import pluralize from "pluralize";
import { capitalize } from "../../../utils";
import { pluralizeName } from "../../../utils/pluralizeName";
import aadLogoMobile from "../../../svg/aad-logo-mobile.svg";
import aadLogoDesktop from "../../../svg/aad-logo-desktop.svg";
import { formatCurrency } from "../../../utils/formatCurrency";

export default {
  text: {
    common: {
      browserTitle: "AA Driving Lessons",
      exit: "exit",
      account: "Account",
      back: "back",
      continueButton: "Continue",
      loading: "Loading...",
      addressPickerNoAddresses: "Sorry, no addresses were found",
      addressPickerLabel: "Select your pickup address",
      addressPickerEdit:
        "Please click here to update your address and find a suitable instructor",
      datePickerCarouselNoDates: "Sorry, there are no dates available.",
      dobPickerLabel: "Date of birth (DD / MM / YYYY)",
      dobPickerPlaceholder: "",
      dobPickerMinDate: "Please check your date of birth is correct",
      dobPickerMaxDate: "Please check your date of birth is correct",
      titlePickerLabel: "Title",
      traineeInstructor: "Trainee Instructor",
      lessonPackagePicker: "Sorry, there are no packages currently available.",
      marketPreferencesCheckboxLabel:
        "Yes, you may contact me by the following ways",
      postcodeLabel: "Please enter the billing address postcode",
      postcodePlaceholder: "",
      timeslotPickerNoLessons:
        "Sorry, there are no lessons available for this date.",
      timeslotPickerPickerAnother:
        "Pick a new date in the calendar. Try a date 2 weeks away.",
      cookieConsentBody:
        "We use cookies to help make BSM better. By visiting this site, you agree to our ",
      cookieConsentLinkText: "Cookie policy",
      cookieConsentLink: "http://www.theaa.com/",
      journeyExitUrl: "https://www.theaa.com",
      favIconDirectory: `/favicons/AAD`,
      chatPage:
        "https://www.theaa.com/driving-school/driving-lessons/advice/pupil-chat",
      refundGuaranteeTitle: "Full refund on unused hours",
      refundGuarantee:
        "We'll refund all your unused hours. No hidden charges. No messing around. That's the AA way.",
      learnerAppGooglePlayStoreUrl:
        "https://play.google.com/store/apps/details?id=com.theaa.drivingschoollearner",
      learnerAppAppleAppStoreUrl:
        "https://apps.apple.com/us/app/the-aa-driving-school/id1548948594",
      learnerAppHeading: "Get the learner app",
    },
    "pickup-location": {
      browserTitle: "Pickup location",
      pageTitle: "So, you're ready to learn to drive?",
      postCodeLabel: "Enter a pickup postcode",
      postCodeError: "Please enter a valid UK postcode",
      referralBannerTitle: "You are using a referral link.",
      // referralBannerBody:
      //   "Take a lesson and claim your £20 Amazon.co.uk voucher. ",
      referralBannerBody: "Sorry, but this offer has expired.",
      referralBannerLink: "Terms apply.",
    },
    "face-covering": {
      browserTitle: "Face coverings",
      pageTitle: "Driving lessons and coronavirus (COVID-19)",
      selfIsolateAdvice:
        "If you need to self-isolate because of COVID-19, you should not go to your driving lesson. Please contact your instructor as soon as possible to rearrange.",
      cancellationAdvice:
        "You can choose whether or not to wear a face covering during your lesson. However, please discuss directly with your instructor.",
      buttonLabel: "Continue",
    },
    "key-worker": {
      browserTitle: "Key Workers",
      pageTitle: "Are you classed as a key worker?",
      yesNoLabel:
        "Based on current government guidelines are you classed as a key worker?",
      infoPanelLabel: "COVID-19 lesson delays",
      infoPanelText:
        "Current government guidelines mean we can only deliver lessons to key workers. If you want to be notified when these restrictions are lifted, press continue.",
      keyWorkerTypeLabel: "Select your area of work",
      keyWorkerTypePlaceholder: "Please select one",
      keyWorkerEmployerLabel: "Enter your employer's name",
      keyWorkerEmployerPlaceholder: "e.g. NHS, Tesco etc",
    },
    "lesson-types": {
      browserTitle: "Your driving lesson",
      pageTitle: "Thanks.",
      lessonTypesLabel: "What type of lesson do you want?",
      learnToDrive: "I want to learn to drive",
      testPassed: "I want Pass Plus or refresher lessons",
      testBooked: "I have a test booked within 14 days",
      surpriseGift: "Choose later if this is a gift for someone else.",
      continueButtonText: "Continue",
    },
    "test-passed": {
      browserTitle: "Refresher lessons",
      pageTitle: "OK, so you already have a full licence...",
      lessonTypesLabel: "Choose your type of refresher lesson",
      refresher: "Short refresher",
      refresherSummary: "gain in confidence",
      refresherInfoTitle: "Short refresher lessons",
      refresherInfoContent:
        "There are several reasons why motorists require refresher lessons, from needing additional assurance to get behind the wheel following an accident to building confidence when driving on a motorway or at night – our instructors listen to your needs and tailor your course accordingly.",
      refresherInfoPath: "/qualified-driver-lessons",
      motorway: "Motorway",
      motorwaySummary: "learn how to drive safely",
      motorwayInfoTitle: "Motorway lessons",
      motorwayInfoContent:
        "These give you the opportunity to experience the unique challenges of motorway driving with an experienced instructor by your side. Great for nervous drivers wanting to calmly gain knowledge and experience before taking them on their own.",
      motorwayInfoPath: "/qualified-driver-lessons",
      passPlus: "Pass Plus",
      passPlusSummary: "advanced driver training",
      passPlusInfoTitle: "Pass Plus lessons",
      passPlusInfoContent1:
        "A practical training course that takes a minimum of 6 hours to complete and is designed for drivers to improve their skills.",
      passPlusInfoContent2:
        "Pass Plus consists of 6 modules, each of them designed to give the driver experience driving in various situations and conditions.",
      passPlusInfoContent3:
        "Only a specially qualified driving instructor can deliver the course and they must be registered to do so with the DVSA.",
      passPlusInfoPath: "/qualified-driver-lessons",
      moreInfoButtonText: "More Information",
      closeButtonText: "Close",
      continueButtonText: "Continue",
    },
    "test-booked": {
      browserTitle: "Short notice lessons",
      pageTitle: "Great! You already have a test booked.",
      testTimeLabel: "When does your test start?",
      testCentreLabel: "Which test centre have you booked?",
      dateTooEarlyError:
        "Please select a date and time at least 72 hours in the future",
      lessonInfo:
        "We will book your lesson to begin at least 1 hour before your test.",
    },
    "call-to-complete-booking": {
      browserTitle: {
        callUs: "Complete your booking",
        testPassed: "Book your pass plus or refresher lessons",
        surpriseGift: "Book lessons as a gift for someone",
      },
      pageTitle: "Complete your booking",
      callUs: `To complete your booking please contact us via the chat button below and we will help you find the instructor that is perfect for you.`,
      testPassed: `Looking to book refresher lessons or Pass Plus lessons? Chat to us using the button below and we will help you find the perfect instructor.`,
      testBooked: `Need some lessons to brush up before your test? Chat to us using the button below and we will help you find an instructor to get you through the test.`,
      surpriseGift: `To buy surprise lessons for someone, you will need to chat to us using the button below and we can arrange everything.`,
      callButtonText: "Start a chat with an advisor",
    },
    prices: {
      crossSellHandoverTitle: "Handing you over to The AA",
      browserTitle: "Select your lesson package",
      pageTitle: "Select a gearbox to see instructors",
      gearbox: "Gearbox:",
      manual: "Manual",
      automatic: "Automatic",
      yourInstructor: "Your instructor",
      change: "Change",
      howManyLessons: "Choose your package hours",
      loadingMessage: "Finding Instructors",
      instructorPickerSelectButtonText: "Select",
      alert: "Get an alert",
      covidAlert: "COVID-19 — lesson delay",
      alertMobileNumberLabel: "Mobile for SMS alert",
      alertBody:
        "All our AA instructors are super busy! Get a priority alert when an instructor is available.",
      covidAlertBody:
        "In-line with DVSA guidelines and to protect you and our instructors, we have temporarily suspended all lessons. We can notify you when you can book lessons again. Simply sign up for an SMS alert.",
      createAlert: "Create alert",
      alertInvalid: "Please enter a valid mobile number",
      alertModalTitle: "Instructor alert created",
      alertModalPostcode: "Pickup postcode",
      alertModalTransmission: "gearbox",
      alertModalTransmissionSubTitle: "Transmission type",
      alertModalMobileNumber: "Mobile to receive SMS alert",
      alertModalDone: "Done",
      notificationTitle: "Notifications & privacy",
      covidNotificationContent:
        "We will only message you once, when we resume lesson delivery. We will only store your number for 90 days. Read more in our ",
      notificationContent:
        "We will only message you once, when an instructor becomes available and will remove you from the queue after 60 days. Only 2 postcodes per mobile number. Read more in our ",
      traineeModalContent:
        "Trainee instructors have undergone theory and practical training and are gaining experience to increase their instructional skills prior to full qualification.",
      crossSellHeading: "Select an instructor and switch to BSM",
      crossSellInfoPanelTitle: ({ transmissionType }) =>
        `Currently, there aren’t any ${transmissionType} gearbox instructors available.`,
      crossSellInfoPanelSummary:
        "However, we have found the following prices from our sister company, the BSM.",
      noInstructorsHeading:
        "We are really sorry but we don’t have any instructors who can help you with this test date.",
      noInstructorsLinkPrefix: "Try changing your ",
      noInstructorsLinkText: "pickup Location",
      noInstructorsLink: ({ pickupPostcode }) =>
        `/pickup-location?pickupPostcode=${pickupPostcode}`,
      noInstructorLinkSuffix:
        " as other instructors nearby may be able to help.",
    },
    "prices/change-instructor": {
      browserTitle: "Change your instructor",
      selectInstructor: "Select your instructor",
      noInstructors: "Sorry, no other instructors are available",
    },
    timeslots: {
      browserTitle: "Select your lesson time",
      pageTitle: ({ instructorName = "" }) =>
        `Please select a time for your lesson with ${instructorName}`,
      defaultInstructorName: "your new instructor",
      noSlots:
        "Sorry, this instructor has no slots available on this date. Please try an alternative date.",
      datePickerOk: "Select",
      datePickerCancel: "Cancel",
      viewCalendar: "View Calendar",
    },
    "personal-details": {
      selectAlternateLesson:
        "Please select an alternative lesson date and time",
      maxDobMessage: "You must be 17 at the time of your selected lesson",
      browserTitle: "Enter your details",
      pageTitle: "Please tell us your details",
      firstName: "Learner first name",
      surname: "Learner surname",
      mobile: "Learner mobile number",
      email: "Learner email address",
      choosePickup: "Select your pickup address",
      chooseBilling: "Select your billing address",
      edit: "Change",
      marketingPrefsLabel: "Stay in the know",
      privacyNoticeUrl:
        "https://www.theaa.com/~/media/the-aa/pdf/driving-school/aa-driving-school-privacy-notice.pdf?la=en",
      tsandcsBrand: "The AA",
      tsandcsLink:
        "https://www.theaa.com/driving-school/driving-lessons/terms-conditions",
      tsandcs: "Terms & Conditions",
      tsandcsConfirmLabel:
        "I have read, understood and accept the terms and conditions",
      bookNow: "Book now",
      required: "This field is required",
      yesNoPickerLegend: "Is your billing address the same as pickup?",
      yesNoIsGift: "Is this a gift?",
      yesNoShouldBeSurprise:
        "Would you like these lessons to be a surprise to the learner?",
      validation: {
        text: ({ fieldName = "" }) =>
          `Your ${fieldName} must only contain letters`,
        phoneNumber: "Please enter a valid phone number",
        email: "Please enter a valid email",
      },
      summaryModalTitle: "You are about to book a lesson",
      giftGiversEmail: "Your email address",
      giftGiversMobile: "Your mobile number",
      giftInfoPanelSurpriseTitle:
        "Don't worry, we won't tell the learner about your surprise gift",
      giftInfoPanelSurpriseBody:
        "We'll send you any lesson details and updates until the learner logs into their account for the first time.",
      giftInfoPanelNoSurpriseTitle: "This gift will NOT be a surprise",
      giftInfoPanelNoSurpriseBody:
        "The learner will be notified the details of their lessons.",
    },
    payment: {
      browserTitle: "Payment",
      pageTitle: "Please enter your payment details to complete your order",
      summaryTitle: "Transaction summary",
      lessons: "Lessons",
      discount: "Discount",
      bookingFee: "Booking fee",
      total: "Total",
      refreshWarning:
        "Please do not refresh your screen or tap the browser back button while we process your payment.",
      paymentDeclined: "Your payment was declined.  Please try again.",
    },
    confirmation: {
      browserTitle: "Booking confirmation",
      pageTitle: ({ pupilFirstName = "" }) =>
        `Congratulations ${capitalize(
          pupilFirstName,
        )}! Your first lesson is booked!`,
      giftedPageTitle: ({ pupilFirstName = "" }) =>
        `Your gifted lesson for ${capitalize(
          pupilFirstName,
        )} has been successfully booked!`,
      lessonDetailsTitle: "Lesson details",
      topUpLinkCopied: "Top-up link copied!",
      copyTopUpLink: "Copy top-up link",
      lessonDetailsInstructor: "Instructor",
      lessonDetailsAddress: "Pick up address",
      hourLesson: "hour lesson",
    },
    sntConfirmation: {
      pageTitle: ({ pupilFirstName = "" }) =>
        `Congratulations ${capitalize(
          pupilFirstName,
        )}! Your test lesson is booked!`,
    },
    "lesson-country": {
      browserTitle: "Select country",
      pageTitle: "Which country do you take your lessons in?",
      countrySelectorLabel: "What country do you get picked up from?",
      infoPanelLabel: "Lessons during COVID-19 lockdown",
      infoPanelText:
        "Some countries within the UK have different rules about driving lessons during the COVID-19 lockdown.",
    },
    "returning/booked-lessons": {
      browserTitle: "Booked Lessons",
      pageTitle: "View your lessons",
      noticeTitle: "Please note: ",
      notice:
        "Not all lessons booked will appear here. There are circumstances where your instructor has booked a lesson for you without using the digital platform. If your booked lesson is not displayed, please contact your instructor.",
      noLessons: "You don’t appear to have any lessons booked.",
      upcomingLessonsTitle: "Upcoming lessons",
      pastLessonsTitle: "Past lessons",
    },
    "somethings-gone-wrong": {
      browserTitle: "Something's gone wrong",
      pageTitle: "Something's gone wrong!",
      buttonText: "Start a chat with an advisor",
      body:
        "Something went wrong with our server. This isn’t your fault–It’s ours.",
      action: "Start a chat with an advisor",
    },
    "somethings-gone-wrong-500": {
      browserTitle: "Something's gone wrong",
      buttonText: "Start a chat with an advisor",
      body:
        "Something went wrong with our server. This isn’t your fault–It’s ours.",
      action: "Start a chat with an advisor",
    },
    account: {
      pageTitle: ({ name = "" }) => `${pluralizeName(name)} account`,
      accountOptionsLabel: "What would you like to do today?",
      bookButtonSubtitle: "Book up your next driving lesson.",
      bookButtonTitle: "Book a lesson",
      breakdownModalSubtextIntro: "Quote code: ",
      breakdownModalSubtextOutro: " and your pupil ID to claim your discount",
      breakdownModalText:
        "Claim your free AA Basic Breakdown policy or choose cover with unlimited call outs from £1 a month",
      breakdownModalTitle: "Claim your 12 months free AA breakdown cover",
      browserTitle: "My account",
      chatBannerText: "Start an online chat to talk to Customer Services.",
      chatBannerTitle: "Want to chat to us?",
      freeBreakdownClaimOne: "Claim 12 months",
      freeBreakdownCover: " breakdown cover",
      freeBreakdownYearsFreeAA: " free AA",
      helpCentreButtonSubtitle: "How can we help you today?",
      helpCentreButtonTitle: "Help centre",
      recommendedDrivingProductsTitle: "Recommended driving products",
      recommendedDrivingProductsSubtitle:
        "AA products and services to help you have confidence on the road.",
      progressButtonSubtitle: "View your progress by lesson.",
      progressButtonTitle: "Progress tracker",
      rateInstructorButtonSubtitle: "Give us your feedback.",
      rateInstructorButtonTitle: "Rate my instructor",
      refundButtonSubtitle: "Request a refund for your pre paid hours.",
      refundButtonTitle: "Request a refund",
      requestInstructorButtonSubtitle: "Get a new instructor.",
      requestInstructorButtonTitle: "Request an instructor",
      topUpButtonSubtitle: "Credit your account to pay for lessons.",
      topUpButtonTitle: "Top-up account",
      topUpRequiredText:
        "There isn’t enough credit to book a lesson. Please top–up first.",
      viewLessonsButtonSubtitle: "View your lesson history.",
      viewLessonsButtonTitle: "View lessons",
      motabilityWelcome: ({ name }) => `Welcome ${name}.`,
      motabilitySubHeading:
        "To book your next lesson and learn more about your progress contact our team on",
      motabilityPhoneNumber: "0800 316 3742",
      motabilityContactHours: "Open Monday to Friday between 8:30am - 6pm",
      yourInstructorButtonTitle: "Your instructor",
      yourInstructorButtonSubtitle: "View instructor details & leave feedback.",
    },
    "top-up/amount": {
      pageTitle: ({ name = "" }) => `Top-up ${pluralizeName(name)} account`,
      browserTitle: "Top-up your account",
      balanceDescriptor: "available to book",
    },
    "top-up/payment": {
      pageTitle: ({ name = "" }) =>
        `Pay securely to top-up ${pluralizeName(name)} account`,
      browserTitle: "Pay securely for top-up",
      loading: "Preparing your order...",
    },
    "top-up/confirmation": {
      pageTitle: ({ name = "", hours = 1 }) =>
        `Thanks! You’ve topped–up ${pluralizeName(
          name,
        )} account with ${hours} ${pluralize(
          "hour",
          hours,
        )} of driving lessons`,
      browserTitle: "Top-up confirmation",
      newAccountBalance: "New account balance",
      bookLesson: "Book a lesson",
      availableToBook: "available to book",
    },
    "requestRefund/request": {
      browserTitle: "Request a refund",
      description1: "You can request a refund for unused credit.",
      description2:
        "The refund method may vary depending on the length of time since your payment was made.",
      emphasis: "Please note:",
      buttonText: ({ refundableTotal }) =>
        `Request a £${formatCurrency(refundableTotal)} refund`,
      balanceDescriptor: "remaining",
      totalLabel: "Total",
      priceLabel: "Price",
      refundReasonLabel: "Please choose a reason for the refund",
      processing: "Processing your refund...",
    },
    "requestRefund/confirmation": {
      pageTitle: "Request a refund",
      browserTitle: "Refund request confirmation",
      buttonText: "Go to my account",
      confirmationText:
        "We have received your refund request. We will be in touch shortly when we have processed it.",
    },
    "returning/timeslots": {
      pageTitle: ({ instructorName = "" }) =>
        `Great! Now book a time slot for your lesson with ${instructorName}`,
      "pageTitle-login": "For security, please complete the following details",
      browserTitle: "Select your lesson time",
      processing: "Booking lesson...",
      authorizing: "Checking your details...",
      bookNow: "Book now",
      confirmationModalTitle: ({ duration = 2 }) =>
        `You are about to book a lesson using ${duration} ${pluralize(
          "hour",
          duration,
        )} of credit`,
      confirmationModalBalanceTitle: "Account balance",
      confirmationModalBalanceSubtitle: "Hours remaining after booking",
      confirmationModalCancelButton: "Cancel",
      confirmationModalConfirmationButton: "Yes! Book it",
      loginSubmitButton: "Confirm",
      bannerTitle: "Chat with your instructor",
      bannerText:
        "Don't forget to find a mutually good time for you both to book a lesson",
    },
    "returning/confirmation": {
      browserTitle: "Booking confirmation",
      pageTitle: ({ pupilFirstName = "" }) =>
        `Congratulations ${capitalize(pupilFirstName)}! Your lesson is booked!`,
      continueButton: "Book another lesson",
    },
    "returning/talk-to-us": {
      pageTitle: "Talk to us",
      browserTitle: "Talk to us",
      chatDescription:
        "If you have a general enquiry, reach out to our friendly web chat advisors.",
      chatOpeningTimes:
        "We're all set to assist Monday to Friday, from 9am to 5pm.",
      sendMessageButtonText: "Send message",
      startChatButtonText: "Start chat",
      messageFieldLabel:
        "If our live chat's closed, why not send us a message and we'll make sure we get back to you within 5 working days via email.",
      processing: "Sending message...",
    },
    "returning/talk-to-us/confirmation": {
      browserTitle: "Message sent",
      title: "We've got it!",
      buttonText: "Go to my account",
      messageSent:
        "We have received your message. We always aim to respond to you as soon as possible.",
    },
    "returning/change-instructor": {
      browserTitle: "Change your instructor",
      pageTitle: "Change your instructor",
      processing: "Sending request...",
      subTitle: "Learning to drive is an exciting time!",
      bodyText:
        "If you aren't happy with your instructor, we will try to switch you to another local AA instructor (dependent on availability).",
      bodyText2: "We're all set to assist Monday to Friday, from 9am to 5pm.",
      bodyTextHelper:
        "If our live chat is currently closed, please come back during our opening hours.",
      selectLabel: "Can you let us know why you want to change?",
      selectPlaceholder: "Please select",
      buttonText: "Request an instructor change",
    },
    "returning/change-instructor/confirmation": {
      browserTitle: "Request sent",
      title: "Change instructor request",
      buttonText: "Go to my account",
      messageSent:
        "We have received your request. We will contact you shortly to discuss your options and make the switch to a new instructor for you.",
    },
    "returning/complaints": {
      browserTitle: "Make a complaint",
      pageTitle: "Make a complaint",
      explanatoryText:
        "We treat all complaints seriously. We have high standards that we expect all our staff, contractors and franchises to adhere to. If you feel that on an occasion these were not met, please complete this form.",
      reason: "What’s the main reason for the complaint?",
      details: "Please enter your complaint",
      resolution: "What would you like us to do to resolve the issue?",
      requiresResponse: "Do you want us to respond to you?",
      sendButton: "Send complaint",
      reasonLabel: "Please select a reason",
      processing: "Submitting your complaint...",
    },
    "returning/complaints/confirmation": {
      browserTitle: "Complaint received",
      title: "Complaint received",
      buttonText: "Go to my account",
      messageSent:
        "We have received your complaint. We will be in touch shortly.",
    },
    "returning/cancel-lesson": {
      browserTitle: "Cancel lesson",
      title: "Cancel lesson",
      loadingLessons: "Your upcoming lessons",
      cancellingLesson: "Cancelling your lesson...",
      lessonSlotLabel: "Choose a lesson",
      reasonLabel: "Why do you want to cancel?",
      noteEmphasis: "Please note: ",
      note:
        "If your lesson is within 2 days, it won’t be cancelled automatically. If your lesson is within 48 hours, you may be charged the full cost of the lesson.",
      button: "Cancel lesson",
    },
    "returning/cancel-lesson/confirmation": {
      browserTitle: "Cancel lesson confirmed",
      title: "Cancel a lesson",
      buttonText: "Go to my account",
      summary: ({ date }) => `Your lesson for the ${date} has been cancelled.`,
    },
    "returning/cancel-lesson/chat": {
      browserTitle: "Chat to cancel lesson",
      title: "Cancel a lesson",
      buttonText: "Start chat",
      secondaryButtonText: "Back",
      summary: "Lesson is within 2 days and can’t be cancelled automatically.",
      secondarySummary: "You need to chat to one of our advisors to cancel.",
    },
    "returning/rate-instructor": {
      browserTitle: "Rate your instructor",
      pageTitle: "Rate your instructor",
      ratingLabel: "Instructor rating",
      reviewLabel: "Your review",
      submitButton: "Submit rating",
      processing: "Submitting your rating...",
    },
    "returning/rate-instructor/confirmation": {
      browserTitle: "Instructor rating received",
      pageTitle: "Instructor rating received",
      buttonText: "Go to my account",
      messageSent: "Thank you for rating your instructor",
    },
    "returning/keyWorker": {
      browserTitle: "Key worker",
      pageTitle: "Are you a key worker?",
      warning:
        "Due to government guidelines, we can only deliver lessons to key workers at this time.",
      label:
        "Based on current government guidelines are you classed as a key worker?",
      actionButton: "Continue",
      talkToUsP1:
        "In-line with DVSA guidelines and to protect you and our instructors, we have temporarily suspended all lessons.",
      talkToUsP2:
        "As soon as the government restrictions are relaxed, we will text you to let you know.",
      talkToUsP3:
        "If you would like to chat to an advisor, please start a chat.",
    },
    newInstructorRequest: {
      title: "New Instructor",
      subText:
        "We have received your request for a new instructor. You will hear from us shortly.",
      buttonTitle: "Go to my account",
    },
    login: {
      pageTitle: "Enter your details to log in to your account",
      browserTitle: "Login",
      errorMessage: `It looks like those details don't match, please try again`,
      pupilIdLabel: "Your pupil ID",
      pupilIdHelperText: "Your pupil ID can be found in your welcome email",
      pupilIdPlaceholder: "6 to 9 digit number",
      surnameLabel: "Your surname",
      dobLabel: "Your date of birth",
      buttonText: "Login",
      validationError: "Could not verify those details, please try again.",
      attemptsRemaining: ({ attemptsRemaining }) =>
        `Could not verify those details. You have ${attemptsRemaining} ${pluralize(
          "attempts",
          attemptsRemaining,
        )} remaining`,
      lockedAccountTitle: "This account is locked for 15 minutes",
      lockedAccountSubtitle:
        "To book a lesson, please start a chat with one of our advisors",
      lockedAccountButton: "Start a chat with an advisor",
    },
    "existing-pupil": {
      browserTitle: "Existing Pupil",
      pageTitle: "Hello again!",
      bodyTitle: "Hi.",
      bodyText: `We think you already have an account with us. Simply log in to top-up or book a lesson`,
      buttonText: "Login",
      footerText: "Not right?",
      footerButtonText: "Start a chat with an advisor",
    },
    helpCentre: {
      pageTitle: "Help centre",
      intro: "How can we help you today?",
      talkButtonTitle: "Talk to us",
      talkButtonSubtitle: "Chat open 9am - 5pm or email 24/7",
      cancelButtonTitle: "Cancel a lesson",
      cancelButtonSubtitle: "Cancel an upcoming driving lesson.",
      changeInstructorButtonTitle: "Change your instructor",
      changeInstructorButtonSubtitle: "Request a switch of instructors.",
      refundButtonTitle: "Refund",
      refundButtonSubtitle: "Request a refund for unused credit.",
      complaintButtonTitle: "Make a complaint",
      complaintButtonSubtitle: "Got a problem? Get it resolved.",
    },
    recommendedDrivingProducts: {
      pageTitle: "Recommended Driving Products",
      learningToDrive: "Learning to drive",
      theoryApp: "AA Theory App",
      theoryAppSubtitle: "All you need to pass your theory test first time.",
      bookALesson: "Book a lesson",
      bookALessonSubtitle: "Secure your next driving lesson.",
      gettingYouOnTheRoad: "Getting you on the road",
      aaCars: "AA Cars",
      aaCarsSubtitle: "Unlock the road to quality preowned cars.",
      aaCarLoans: "AA Car Loans",
      aaCarLoansSubtitle:
        "Loans from £1,000 with no arrangement or set-up fees.",
      keepingYouOnTheRoad: "Keeping you on the road",
      aaBreakdown: "AA Breakdown",
      aaBreakdownSubtitle: "No.1 in keeping you moving.",
      aaSmartCare: "AA Smart Care",
      aaSmartCareSubtitle:
        "MOT testing, services and repairs at our AA approved local garage network.",
    },
    "returning/progress-tracker": {
      pageTitle: "Your driving progress",
      updated: "Updated:",
      infoTextTitle: "About your driving progress",
      infoText: ({ totalCategories }) =>
        `This is a reflection of your current driving skills against the AA Driving School curriculum as rated by your driving instructor. There are ${totalCategories} categories, each with around 5 specific skills in each. They are marked out of 5 for each skill.`,
      noProgressPara1:
        "Your instructor hasn’t completed your progress report yet.",
      noProgressPara2:
        "Please ask your instructor to give you feedback on your driving progress.",
    },
    "recommended-driving-products": {
      browserTitle: "Recommended Driving Products",
    },
    "theory-app/privacy-notice": {
      browserTitle: "Privacy Notice",
    },
    "theory-app/terms-and-conditions": {
      browserTitle: "Terms and Conditions",
    },
    "returning/your-instructor": {
      pageTitle: "Your instructor",
    },
  },
  images: {
    logoMobile: aadLogoMobile,
    logoDesktop: aadLogoDesktop,
  },
};
