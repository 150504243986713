import { useMutation } from "@apollo/react-hooks";

import saveInstructorAwards from "../graphql/saveInstructorAwards.graphql";

const useMutationSaveInstructorAwards = ({
  onCompleted,
  onError,
}: {
  onCompleted?: (data: { saveInstructorAwards: boolean }) => void;
  onError?: (error: Error) => void;
}) =>
  useMutation(saveInstructorAwards, {
    onCompleted,
    onError,
  });

export default useMutationSaveInstructorAwards;
