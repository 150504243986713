import { QueryHookOptions, useQuery } from "@apollo/react-hooks";
import getInstructorAwardsForLearner from "../graphql/getInstructorAwardsForLearner.graphql";
import { Award } from "@graphql/types";

const useQueryGetInstructorAwardsForLearner = (opts?: QueryHookOptions) =>
  useQuery<{ getInstructorAwardsForLearner: Award[] }>(
    getInstructorAwardsForLearner,
    opts,
  );

export default useQueryGetInstructorAwardsForLearner;
