import { Award } from "@graphql/types";
import Awards from "./Awards";
import { useStateContext } from "@context";
import { useSnackbar } from "notistack";
import useMutationSaveInstructorAwards from "../../../../hooks/useMutationSaveInstructorAwards";
import React, { useState } from "react";
import { AwardsStateProviderProps } from "../types";
import { getInstructorName } from "../../../../components/common/Timeslots/helpers";
import { useBrand } from "../../../../components/common/BrandProvider/BrandProvider";

export const StateProvider: React.FC<AwardsStateProviderProps> = ({
  selectedOptions,
  setSelectedOptions,
}) => {
  const awards: Award[] = Object.values(Award);

  const awardLabels: Record<Award, string> = {
    [Award.PerfectForBeginners]: "Perfect for beginners",
    [Award.CalmUnderPressure]: "Calm under pressure",
    [Award.AboveAndBeyond]: "Above & beyond",
    [Award.ClearCommunicator]: "Clear communicator",
    [Award.ConfidenceBuilder]: "Confidence builder",
    [Award.FriendlyAndPolite]: "Friendly & polite",
    [Award.TopSupporter]: "Top supporter",
    [Award.PatientAndUnderstanding]: "Patient & understanding",
    [Award.GreatListener]: "Great listener",
    [Award.DevelopmentFocused]: "Development focused",
  };

  const { getText } = useBrand();
  const { state } = useStateContext();
  const { enqueueSnackbar } = useSnackbar();

  const [saveInstructorAwards, { loading }] = useMutationSaveInstructorAwards({
    onCompleted: (data: { saveInstructorAwards: boolean }) => {
      if (data?.saveInstructorAwards) {
        enqueueSnackbar("Successfully saved awards", { variant: "success" });
      } else {
        enqueueSnackbar("Failed to save awards", { variant: "error" });
      }
    },
    onError: () => {
      enqueueSnackbar("Error saving awards", { variant: "error" });
    },
  });

  const [error, setError] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const award = value as Award;
    setError(false);

    if (!checked) {
      setSelectedOptions(selectedOptions.filter(option => option !== award));
      return;
    }

    if (selectedOptions.length < 3) {
      setSelectedOptions([...selectedOptions, award]);
      return;
    }

    setError(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedOptions.length === 0) {
      setError(true);
      return;
    }
    await saveInstructorAwards({
      variables: {
        input: {
          personKey: state.rujHistory.instructorId,
          learnerId: parseInt(state.rujHistory.pupilId),
          awards: selectedOptions,
        },
      },
    });
  };

  return (
    <Awards
      instructorName={getInstructorName(
        { name: state.rujHistory.instructorName },
        getText,
      )}
      awards={awards}
      awardLabels={awardLabels}
      handleSubmit={handleSubmit}
      handleCheckboxChange={handleCheckboxChange}
      selectedOptions={selectedOptions}
      error={error}
      loading={loading}
    />
  );
};
