import React, { ElementType } from "react";

// Generic Components
import SomethingsGoneWrong from "./components/CommonRoutes/SomethingsGoneWrong/SomethingsGoneWrong";
import SomethingsGoneWrong500 from "./components/CommonRoutes/SomethingsGoneWrong500/SomethingsGoneWrong500";
import ExistingPupil from "./components/CommonRoutes/ExistingPupil";

// NUJ Components
import ChangeInstructor from "./components/NewUserJourney/Steps/Prices/ChangeInstructor";
import Confirmation from "./components/NewUserJourney/Steps/Confirmation";
import SntConfirmation from "./components/NewUserJourney/Steps/SntConfirmation";
import KeyWorker from "./components/NewUserJourney/Steps/KeyWorker";
import LessonTypes from "./components/NewUserJourney/Steps/LessonTypes";
import Payment from "./components/NewUserJourney/Steps/Payment";
import PersonalDetails from "./components/NewUserJourney/Steps/PersonalDetails";
import PickupLocation from "./components/NewUserJourney/Steps/PickupLocation";
import Prices from "./components/NewUserJourney/Steps/Prices";
import SelectCountryNuj from "./components/NewUserJourney/Steps/SelectCountry";
import TestBooked from "./components/NewUserJourney/Steps/LessonTypes/TestBooked";
import TestPassed from "./components/NewUserJourney/Steps/LessonTypes/TestPassed";
import Timeslots from "./components/NewUserJourney/Steps/Timeslots";
import NUJFaceCoveringWithState from "./components/NewUserJourney/Steps/FaceCovering/StateProvider";
import CallToComplete from "./components/NewUserJourney/OtherRoutes/CallToComplete/CallToComplete";

// Refund Request Components
import RefundRequest from "./components/RefundRequestJourney/RefundRequest";
import RefundRequestConfirmation from "./components/RefundRequestJourney/Confirmation";

// QTUJ Components
import TopUp from "./components/QuickTopUpJourney/TopUp";
import QTUPayment from "./components/QuickTopUpJourney/Payment";
import QTUConfirmation from "./components/QuickTopUpJourney/Confirmation";

// RUJ Components
import Login from "./components/ReturningUserJourney/Login";
import Account from "./components/ReturningUserJourney/Account";
import HelpCentre from "./components/ReturningUserJourney/HelpCentre/HelpCentre";
import BookedLessons from "./components/ReturningUserJourney/BookedLessons";
import RUJTimeslots from "./components/ReturningUserJourney/Timeslots";
import RUJSelectCountry from "./components/ReturningUserJourney/SelectCountry";
import RUJKeyWorker from "./components/ReturningUserJourney/KeyWorker/KeyWorker";
import RUJConfirmation from "./components/ReturningUserJourney/Confirmation";
import RUJProgress from "./components/ReturningUserJourney/Progress/StateProvider";
import TalkToUs from "./components/ReturningUserJourney/TalkToUs/StateProvider";
import TalkToUsConfirmation from "./components/ReturningUserJourney/TalkToUs/Confirmation/Confirmation";
import RUJChangeInstructor from "./components/ReturningUserJourney/ChangeInstructor";
import RUJNewInstructorRequested from "./components/ReturningUserJourney/NewInstructorRequested";
import RUJChangeInstructorConfirmation from "./components/ReturningUserJourney/ChangeInstructor/Confirmation";
import Complaints from "./components/ReturningUserJourney/Complaints";
import ComplaintsConfirmation from "./components/ReturningUserJourney/Complaints/Confirmation";
import CancelLesson from "./components/ReturningUserJourney/CancelLesson";
import RateInstructor from "./components/ReturningUserJourney/RateInstructor";
import RateInstructorConfirmation from "./components/ReturningUserJourney/RateInstructor/RateInstructorConfirmation";
import RUJFaceCovering from "./components/ReturningUserJourney/FaceCovering";
import RecommendedDrivingProducts from "./components/ReturningUserJourney/RecommendedDrivingProducts/RecommendedDrivingProducts";
import YourInstructor from "./components/ReturningUserJourney/YourInstructor";

import {
  JourneyType,
  NEW_USER_JOURNEY_PATH_NAMES,
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
  THEORY_APP_PATH_NAMES,
  USE_AUTH_PORTAL,
} from "./constants";

import { TermsAndConditions, PrivacyNotice } from "./components/TheoryApp";

export type StepDefinition = {
  path: string;
  Component: ElementType;
  browserTitle?: string;
  routeDependencies?: string[];
  hideBackButton?: boolean;
  hideProgressBar?: boolean;
  exact?: boolean;
  journeyType: JourneyType;
  shouldRedirectLogin?: boolean;
  headerActionButton?: "AccountButton" | "ExitButton";
};

// The order of these arrays reflects the order of steps in the journey

export const NewUserNewUserJourneySteps: StepDefinition[][] = [
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.PICKUP,
      Component: PickupLocation,
      hideBackButton: true,
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.FACE_COVERING,
      Component: NUJFaceCoveringWithState,
      routeDependencies: ["pickupPostcode"],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.SELECT_COUNTRY,
      Component: SelectCountryNuj,
      routeDependencies: ["pickupPostcode"],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.KEY_WORKER,
      Component: KeyWorker,
      routeDependencies: ["pickupPostcode"],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.LESSON_TYPES,
      Component: LessonTypes,
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.PRICES,
      Component: () => <Prices nudgeBackEnabled />,
      routeDependencies: ["pickupPostcode", "selectedLessonType"],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.SNT_PRICES,
      Component: () => <Prices nudgeBackEnabled={false} />,
      routeDependencies: ["pickupPostcode", "selectedLessonType"],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.TIMESLOTS,
      Component: Timeslots,
      routeDependencies: [
        "pickupPostcode",
        "selectedLessonType",
        "selectedPackage",
        "selectedInstructor",
      ],
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.PERSONAL_DETAILS,
      Component: PersonalDetails,
      routeDependencies: [
        "pickupPostcode",
        "selectedLessonType",
        "selectedPackage",
        "selectedInstructor",
      ],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.PAYMENT,
      Component: Payment,
      routeDependencies: [
        "pickupPostcode",
        "selectedLessonType",
        "selectedPackage",
        "order",
      ],
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
  [
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.CONFIRMATION,
      Component: Confirmation,
      routeDependencies: ["confirmation"],
      hideBackButton: true,
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
    {
      path: NEW_USER_JOURNEY_PATH_NAMES.SNT_CONFIRMATION,
      Component: SntConfirmation,
      routeDependencies: ["confirmation"],
      hideBackButton: true,
      headerActionButton: "ExitButton",
      journeyType: JourneyType.NEW_USER_JOURNEY,
    },
  ],
];

export const OtherNewUserJourneyRoutes: StepDefinition[] = [
  {
    path: NEW_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR,
    Component: ChangeInstructor,
    exact: true,
    routeDependencies: ["pickupPostcode"],
    headerActionButton: "ExitButton",
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: GLOBAL_PATH_NAMES.CALL_TO_COMPLETE,
    Component: () => <CallToComplete type="callUs" />,
    headerActionButton: "ExitButton",
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: NEW_USER_JOURNEY_PATH_NAMES.TEST_PASSED,
    Component: () => <TestPassed />,
    routeDependencies: ["pickupPostcode", "selectedLessonType"],
    headerActionButton: "ExitButton",
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: NEW_USER_JOURNEY_PATH_NAMES.TEST_BOOKED,
    Component: () => <TestBooked />,
    routeDependencies: ["pickupPostcode", "selectedLessonType"],
    headerActionButton: "ExitButton",
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: NEW_USER_JOURNEY_PATH_NAMES.SNT_CONFIRMATION,
    Component: SntConfirmation,
    routeDependencies: ["confirmation"],
    hideBackButton: true,
    headerActionButton: "ExitButton",
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
];

export const QuickTopUpSteps: StepDefinition[][] = [
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT,
      Component: TopUp,
      routeDependencies: ["pupilId", "pupilBalance"],
      headerActionButton: "AccountButton",
      journeyType: JourneyType.QUICK_TOP_UP_JOURNEY,
    },
  ],
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_PAYMENT,
      Component: QTUPayment,
      routeDependencies: ["pupilId"],
      headerActionButton: "AccountButton",
      journeyType: JourneyType.QUICK_TOP_UP_JOURNEY,
    },
  ],
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_CONFIRMATION,
      Component: QTUConfirmation,
      routeDependencies: ["confirmation"],
      hideBackButton: true,
      headerActionButton: "AccountButton",
      journeyType: JourneyType.QUICK_TOP_UP_JOURNEY,
    },
  ],
];

export const ReturningUserJourneySteps: StepDefinition[][] = [
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.KEY_WORKER,
      Component: RUJKeyWorker,
      routeDependencies: ["pupilId", "instructorId"],
      headerActionButton: "AccountButton",
      journeyType: JourneyType.RETURNING_USER_JOURNEY,
    },
  ],
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.FACE_COVERING,
      routeDependencies: ["pupilPickupPostcode"],
      Component: RUJFaceCovering,
      headerActionButton: "AccountButton",
      journeyType: JourneyType.RETURNING_USER_JOURNEY,
    },
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.TIMESLOTS,
      Component: RUJTimeslots,
      routeDependencies: ["pupilId", "instructorId"],
      headerActionButton: "AccountButton",
      journeyType: JourneyType.RETURNING_USER_JOURNEY,
    },
  ],
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.SELECT_COUNTRY,
      Component: RUJSelectCountry,
      routeDependencies: ["pupilPickupPostcode"],
      headerActionButton: "AccountButton",
      journeyType: JourneyType.RETURNING_USER_JOURNEY,
    },
  ],
  [
    {
      path: RETURNING_USER_JOURNEY_PATH_NAMES.CONFIRMATION,
      Component: RUJConfirmation,
      routeDependencies: USE_AUTH_PORTAL ? [] : ["confirmation"], //TODO: check if this is needed, didn't work properly with enabled auth portal on local with this line
      hideBackButton: true,
      headerActionButton: "AccountButton",
      journeyType: JourneyType.RETURNING_USER_JOURNEY,
    },
  ],
];

export const OtherPaths: StepDefinition[] = [
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.LOGIN,
    Component: Login,
    hideProgressBar: true,
    hideBackButton: true,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT,
    Component: Account,
    hideProgressBar: true,
    hideBackButton: true,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
  },
  {
    path: `${RETURNING_USER_JOURNEY_PATH_NAMES.ACCOUNT}/:topUpId`,
    Component: Account,
    hideProgressBar: true,
    hideBackButton: true,
    headerActionButton: "AccountButton",
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US,
    Component: TalkToUs,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.TALK_TO_US_CONFIRMATION,
    Component: TalkToUsConfirmation,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    hideBackButton: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.HELP_CENTRE,
    Component: HelpCentre,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.RECOMMENDED_DRIVING_PRODUCTS,
    Component: RecommendedDrivingProducts,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    browserTitle: "Recommended Driving Products",
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.BOOKED_LESSONS,
    Component: BookedLessons,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR,
    Component: RUJChangeInstructor,
    hideProgressBar: true,
    hideBackButton: false,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.CHANGE_INSTRUCTOR_CONFIRMATION,
    Component: RUJChangeInstructorConfirmation,
    hideProgressBar: true,
    hideBackButton: false,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS,
    Component: Complaints,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.COMPLAINTS_CONFIRMATION,
    Component: ComplaintsConfirmation,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.RATE_INSTRUCTOR,
    Component: RateInstructor,
    routeDependencies: ["instructorId"],
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.RATE_INSTRUCTOR_CONFIRMATION,
    Component: RateInstructorConfirmation,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG,
    Component: SomethingsGoneWrong,
    hideProgressBar: true,
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: GLOBAL_PATH_NAMES.SOMETHINGS_GONE_WRONG_500,
    Component: SomethingsGoneWrong500,
    hideProgressBar: true,
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: NEW_USER_JOURNEY_PATH_NAMES.EXISTING_PUPIL,
    Component: ExistingPupil,
    hideProgressBar: true,
    journeyType: JourneyType.NEW_USER_JOURNEY,
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST,
    Component: RefundRequest,
    routeDependencies: ["pupilId", "pupilBalance"],
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.REFUND_REQUEST_CONFIRMATION,
    Component: RefundRequestConfirmation,
    routeDependencies: ["pupilId"],
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideBackButton: true,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.CANCEL_LESSON,
    Component: CancelLesson,
    routeDependencies: ["pupilId"],
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    hideProgressBar: true,
    headerActionButton: "AccountButton",
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.PROGRESS,
    Component: RUJProgress,
    routeDependencies: ["pupilId"],
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
    headerActionButton: "AccountButton",
    hideProgressBar: true,
  },

  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.NEW_INSTRUCTOR_REQUEST_RECEIVED,
    Component: RUJNewInstructorRequested,
    headerActionButton: "AccountButton",
    hideProgressBar: true,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
  },
  {
    path: RETURNING_USER_JOURNEY_PATH_NAMES.YOUR_INSTRUCTOR,
    Component: YourInstructor,
    headerActionButton: "AccountButton",
    hideProgressBar: true,
    journeyType: JourneyType.RETURNING_USER_JOURNEY,
  },
];

export const TheoryAppPaths: StepDefinition[] = [
  {
    path: THEORY_APP_PATH_NAMES.THEORY_APP_PRIVACY_NOTICE,
    Component: PrivacyNotice,
    journeyType: JourneyType.THEORY_APP_JOURNEY,
    browserTitle: "Privacy Notice",
    hideProgressBar: true,
  },
  {
    path: THEORY_APP_PATH_NAMES.THEORY_APP_TERMS_AND_CONDITIONS,
    Component: TermsAndConditions,
    journeyType: JourneyType.THEORY_APP_JOURNEY,
    browserTitle: "Terms and Conditions",
    hideProgressBar: true,
  },
];

export const AllStepDefinitions: StepDefinition[] = [
  ...NewUserNewUserJourneySteps.flat(1),
  ...OtherNewUserJourneyRoutes,
  ...QuickTopUpSteps.flat(1),
  ...ReturningUserJourneySteps.flat(1),
  ...OtherPaths,
  ...TheoryAppPaths,
];
