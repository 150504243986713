import * as Sentry from "@sentry/browser";
import {
  NEW_USER_JOURNEY_PATH_NAMES,
  GLOBAL_PATH_NAMES,
  RETURNING_USER_JOURNEY_PATH_NAMES,
} from "../constants";

const {
  PICKUP,
  FACE_COVERING,
  SELECT_COUNTRY,
  KEY_WORKER,
  LESSON_TYPES,
  PRICES,
  SNT_PRICES,
  TIMESLOTS,
  PERSONAL_DETAILS,
  PAYMENT,
  CONFIRMATION,
  SNT_CONFIRMATION,
  CHANGE_INSTRUCTOR,
  TEST_PASSED,
  SURPRISE_GIFT,
  EXISTING_PUPIL,
  TEST_BOOKED,
} = NEW_USER_JOURNEY_PATH_NAMES;

const {
  LOGIN,
  TOPUP_AMOUNT,
  TOPUP_PAYMENT,
  TOPUP_CONFIRMATION,
  ACCOUNT,
  HELP_CENTRE,
  CANCEL_LESSON,
  CHANGE_INSTRUCTOR: RETURNING_CHANGE_INSTRUCTOR,
  CHANGE_INSTRUCTOR_CONFIRMATION,
  BOOKED_LESSONS,
  COMPLAINTS,
  COMPLAINTS_CONFIRMATION,
  TALK_TO_US,
  TALK_TO_US_CONFIRMATION,
  RATE_INSTRUCTOR,
  RATE_INSTRUCTOR_CONFIRMATION,
  KEY_WORKER: RETURNING_KEY_WORKER,
  KEY_WORKER_TALK_TO_US,
  TIMESLOTS: RETURNING_TIMESLOTS,
  REFUND_REQUEST,
  REFUND_REQUEST_CONFIRMATION,
  CONFIRMATION: RETURNING_CONFIRMATION,
  SELECT_COUNTRY: RETURNING_SELECT_COUNTRY,
  FACE_COVERING: RETURNING_FACE_COVERING,
  PROGRESS,
  NEW_INSTRUCTOR_REQUEST_RECEIVED,
  RECOMMENDED_DRIVING_PRODUCTS,
  YOUR_INSTRUCTOR,
} = RETURNING_USER_JOURNEY_PATH_NAMES;

const backNavigationMap = {
  [PICKUP]: false,
  [FACE_COVERING]: PICKUP,
  [SELECT_COUNTRY]: FACE_COVERING,
  [KEY_WORKER]: FACE_COVERING,
  [LESSON_TYPES]: FACE_COVERING,
  [PRICES]: LESSON_TYPES,
  [SNT_PRICES]: TEST_BOOKED,
  [TIMESLOTS]: PRICES,
  [PERSONAL_DETAILS]: TIMESLOTS,
  [PAYMENT]: PERSONAL_DETAILS,
  [CONFIRMATION]: false,
  [SNT_CONFIRMATION]: false,
  [CHANGE_INSTRUCTOR]: PRICES,
  [TEST_PASSED]: LESSON_TYPES,
  [TEST_BOOKED]: LESSON_TYPES,
  [SURPRISE_GIFT]: LESSON_TYPES,
  [EXISTING_PUPIL]: PERSONAL_DETAILS,
  [LOGIN]: false,
  [TOPUP_AMOUNT]: ACCOUNT,
  [TOPUP_PAYMENT]: TOPUP_AMOUNT,
  [TOPUP_CONFIRMATION]: false,
  [RETURNING_KEY_WORKER]: ACCOUNT,
  [KEY_WORKER_TALK_TO_US]: ACCOUNT,
  [RETURNING_TIMESLOTS]: ACCOUNT,
  [BOOKED_LESSONS]: ACCOUNT,
  [RETURNING_CONFIRMATION]: false,
  [REFUND_REQUEST]: HELP_CENTRE,
  [REFUND_REQUEST_CONFIRMATION]: false,
  [ACCOUNT]: false,
  [HELP_CENTRE]: ACCOUNT,
  [CANCEL_LESSON]: HELP_CENTRE,
  [COMPLAINTS]: HELP_CENTRE,
  [COMPLAINTS_CONFIRMATION]: false,
  [RETURNING_CHANGE_INSTRUCTOR]: HELP_CENTRE,
  [CHANGE_INSTRUCTOR_CONFIRMATION]: false,
  [TALK_TO_US]: HELP_CENTRE,
  [TALK_TO_US_CONFIRMATION]: false,
  [RATE_INSTRUCTOR]: ACCOUNT,
  [RATE_INSTRUCTOR_CONFIRMATION]: false,
  [RETURNING_SELECT_COUNTRY]: ACCOUNT,
  [RETURNING_FACE_COVERING]: false,
  [PROGRESS]: ACCOUNT,
  [NEW_INSTRUCTOR_REQUEST_RECEIVED]: ACCOUNT,
  [RECOMMENDED_DRIVING_PRODUCTS]: ACCOUNT,
  [YOUR_INSTRUCTOR]: ACCOUNT,
};

export const getBackLocation = pathname => backNavigationMap[pathname];

export const goBack = ({ history, pathname }) => {
  const newLocation = getBackLocation(pathname);

  if (newLocation) {
    history.push(newLocation);
  } else {
    if (Object.values(GLOBAL_PATH_NAMES).indexOf(pathname) === -1) {
      Sentry.captureException(
        new Error(`Unmapped backLocation for pathname: ${pathname}`),
      );
    }
    history.goBack();
  }
};

export default { getBackLocation, goBack };
