import React, { FC, useEffect, useMemo } from "react";
import { withStyles } from "@material-ui/styles";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";

import {
  Typography,
  Box,
  Button,
  Theme,
  StyleRules,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import compose from "lodash.flowright";
import { History } from "history";

import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutline";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ListIcon from "@material-ui/icons/List";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutline";
import BarChartIcon from "@material-ui/icons/BarChart";

import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import TopupHeader from "../../common/TopupHeader/TopupHeader";
import ActionCard from "../../common/ActionCard/ActionCard";
import ActionCardList from "../../common/ActionCardList/ActionCardList";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import { useTealium } from "../../common/TealiumProvider/TealiumProvider";
import FreeBreakdownBanner from "./FreeBreakdownBanner";
import MotabilityAccount from "./MotabilityAccount";
import { getJourneyType } from "@utils";
import { SET_QTU_JOURNEY, useStateContext } from "@context";
import { JourneyType, RETURNING_USER_JOURNEY_PATH_NAMES } from "@constants";
import { PersonOutline } from "@material-ui/icons";
import { useQuery } from "@apollo/client";

import getFeatureFlags from "../../../graphql/getDsGlobalConfig.graphql";

const styles = (theme: Theme): StyleRules<any, Record<string, any>> => ({
  buttonWrapper: {
    textAlign: "center",
  },
  noticeText: {
    color: theme.palette.secondary.main,
    fontSize: "0.9rem",
    lineHeight: 1.4,
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
  },
  bannerWrapper: {
    maxWidth: "100%",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "460px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "500px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  logoutButton: {
    marginTop: "40px",
    textDecoration: "underline",
  },
});

interface Props {
  classes: any;
  pupilName: string;
  balance: number;
  topUpRequired: boolean;
  history: History;
  onLogoutClick: () => void;
  instructorId: string;
  requestNewInstructor: () => void;
  newInstructorRequested: boolean;
  requestInstructorClicked: boolean;
  customerType: string;
}

export const Account: FC<Props> = ({
  classes,
  pupilName,
  balance = 0,
  topUpRequired = false,
  history,
  onLogoutClick = () => {},
  instructorId,
  requestNewInstructor = () => {},
  newInstructorRequested,
  requestInstructorClicked,
  customerType = "Learner",
}) => {
  const { getText, brand } = useBrand();
  const { trackEvent } = useTealium();
  const theme = useTheme();

  const { data } = useQuery(getFeatureFlags);
  const features = data?.getDsGlobalConfig;

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));

  const isBSM = brand === "BSM";

  const { state, dispatch } = useStateContext();

  const journeyType = useMemo(
    () => getJourneyType(history.location.pathname, state),
    [],
  );

  useEffect(() => {
    if (journeyType === JourneyType.QUICK_TOP_UP_JOURNEY) {
      dispatch({
        type: SET_QTU_JOURNEY,
      });
      history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT);
    }
  }, []);

  if (customerType === "Motability") {
    return (
      <MotabilityAccount pupilName={pupilName} onLogoutClick={onLogoutClick} />
    );
  }

  return (
    <ContentWrapper fullWidth fullHeight>
      <ContentWrapper>
        <TopupHeader
          title={getText("account.pageTitle", { name: pupilName })}
          balance={balance}
          margin={false}
        />
      </ContentWrapper>

      {isMobile && <FreeBreakdownBanner />}

      <ContentWrapper fullHeight>
        {!isMobile && <FreeBreakdownBanner />}
        <Typography gutterBottom>
          {getText("account.accountOptionsLabel")}
        </Typography>
        {topUpRequired && instructorId && (
          <Typography
            classes={{ root: classes.noticeText }}
            data-test="topup-required-text"
          >
            {getText("account.topUpRequiredText")}
          </Typography>
        )}
        <Box mb={6}>
          <ActionCardList>
            {!instructorId && (
              <ActionCard
                title={getText("account.requestInstructorButtonTitle")}
                subtitle={getText("account.requestInstructorButtonSubtitle")}
                data-test="request-instructor-button"
                disabled={newInstructorRequested || requestInstructorClicked}
                action={() => {
                  trackEvent({
                    eventCategory: "Account",
                    eventAction: "Interaction",
                    eventLabel: "Request Instructor",
                  });

                  requestNewInstructor();
                }}
                IconComponent={
                  <AddCircleOutlinedIcon color="secondary" fontSize="large" />
                }
              />
            )}
            {instructorId && (
              <ActionCard
                title={getText("account.topUpButtonTitle")}
                subtitle={getText("account.topUpButtonSubtitle")}
                data-test="account-top-up-button"
                action={() => {
                  trackEvent({
                    eventCategory: "Account",
                    eventAction: "Interaction",
                    eventLabel: "Top-up Account",
                  });
                  history.push(RETURNING_USER_JOURNEY_PATH_NAMES.TOPUP_AMOUNT);
                }}
                IconComponent={
                  <AddCircleOutlinedIcon color="secondary" fontSize="large" />
                }
              />
            )}
            {instructorId && (
              <ActionCard
                disabled={topUpRequired}
                title={getText("account.bookButtonTitle")}
                subtitle={getText("account.bookButtonSubtitle")}
                data-test="account-book-button"
                action={() => {
                  trackEvent({
                    eventCategory: "Account",
                    eventAction: "Interaction",
                    eventLabel: "Book Lesson",
                  });
                  history.push(RETURNING_USER_JOURNEY_PATH_NAMES.FACE_COVERING);
                }}
                IconComponent={
                  <CalendarTodayIcon color="secondary" fontSize="large" />
                }
              />
            )}
            {instructorId && features.myBusinessProfileEnabled && (
              <ActionCard
                title={getText("account.yourInstructorButtonTitle")}
                subtitle={getText("account.yourInstructorButtonSubtitle")}
                data-test="account-your-instructor-button"
                action={() => {
                  trackEvent({
                    eventCategory: "Account",
                    eventAction: "Interaction",
                    eventLabel: "Your Instructor",
                  });
                  history.push(
                    RETURNING_USER_JOURNEY_PATH_NAMES.YOUR_INSTRUCTOR,
                  );
                }}
                IconComponent={
                  <PersonOutline color="secondary" fontSize="large" />
                }
              />
            )}
            <ActionCard
              title={getText("account.viewLessonsButtonTitle")}
              subtitle={getText("account.viewLessonsButtonSubtitle")}
              data-test="account-view-lessons-button"
              action={() => {
                trackEvent({
                  eventCategory: "Account",
                  eventAction: "Interaction",
                  eventLabel: "View Lessons",
                });
                history.push(RETURNING_USER_JOURNEY_PATH_NAMES.BOOKED_LESSONS);
              }}
              IconComponent={<ListIcon color="secondary" fontSize="large" />}
            />
            <ActionCard
              title={getText("account.progressButtonTitle")}
              subtitle={getText("account.progressButtonSubtitle")}
              data-test="account-progress-button"
              action={() => {
                trackEvent({
                  eventCategory: "Account",
                  eventAction: "Interaction",
                  eventLabel: "Progress Tracker",
                });
                history.push(RETURNING_USER_JOURNEY_PATH_NAMES.PROGRESS);
              }}
              IconComponent={
                <BarChartIcon color="secondary" fontSize="large" />
              }
            />
            <ActionCard
              title={getText("account.helpCentreButtonTitle")}
              subtitle={getText("account.helpCentreButtonSubtitle")}
              data-test="account-help-centre-button"
              action={() => {
                trackEvent({
                  eventCategory: "Account",
                  eventAction: "Interaction",
                  eventLabel: "Help Centre",
                });
                history.push(RETURNING_USER_JOURNEY_PATH_NAMES.HELP_CENTRE);
              }}
              IconComponent={
                <HelpOutlinedIcon color="secondary" fontSize="large" />
              }
            />
            {/*TODO: remove this condition once we have the cross sell journey approved for bsm */}
            {!isBSM && (
              <ActionCard
                title={getText("account.recommendedDrivingProductsTitle")}
                subtitle={getText("account.recommendedDrivingProductsSubtitle")}
                data-test="account-recommended-driving-products-button"
                action={() => {
                  trackEvent({
                    eventCategory: "Account",
                    eventAction: "Interaction",
                    eventLabel: "Recommended Driving Products",
                  });
                  history.push(
                    `${RETURNING_USER_JOURNEY_PATH_NAMES.RECOMMENDED_DRIVING_PRODUCTS}?referrer=ruj`,
                  );
                }}
                IconComponent={
                  <DirectionsCarOutlinedIcon
                    color="secondary"
                    fontSize="large"
                  />
                }
              />
            )}
          </ActionCardList>

          <Button
            color="primary"
            fullWidth
            className={classes.logoutButton}
            onClick={onLogoutClick}
          >
            Logout
          </Button>
        </Box>
      </ContentWrapper>
    </ContentWrapper>
  );
};

export default compose(withStyles(styles), withRouter)(Account);
