import { withRouter } from "react-router-dom";
import React, { useState } from "react";
import Titlebar from "../../common/Titlebar/Titlebar";
import { goBack } from "../../../utils/navigation";
import { YourInstructorProps } from "./types";
import { useBrand } from "../../common/BrandProvider/BrandProvider";
import ContentWrapper from "../../common/ContentWrapper/ContentWrapper";
import { makeStyles, Typography } from "@material-ui/core";
import { useStateContext } from "@context";
import LoadingScreen from "../../../components/common/LoadingScreen/LoadingScreen";
import { Award } from "@graphql/types";
import useQueryGetInstructorAwardsForLearner from "../../../hooks/useQueryGetInstructorAwardsForLearner";
import { Awards } from "./components";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    paddingBottom: theme.spacing(3),
  },
  subHeading: {
    fontWeight: 600,
    marginBottom: theme.spacing(3),
  },
}));

const YourInstructor: React.FC<YourInstructorProps> = ({
  history,
  location: { pathname },
}) => {
  const { getText } = useBrand();
  const classes = useStyles();
  const { state } = useStateContext();
  const [selectedOptions, setSelectedOptions] = useState<Award[]>([]);

  const onQueryCompleted = () => {
    const data = queryData?.getInstructorAwardsForLearner;
    if (data) {
      setSelectedOptions(data);
    }
  };

  const {
    data: queryData,
    loading: queryLoading,
  } = useQueryGetInstructorAwardsForLearner({
    onCompleted: onQueryCompleted,
    fetchPolicy: "network-only",
    variables: {
      instructorPersonKey: state.rujHistory.instructorId,
      learnerId: parseInt(state.rujHistory.pupilId),
    },
  });

  return (
    <div className={classes.container}>
      {queryLoading ? (
        <LoadingScreen messageText={"Your instructor"} />
      ) : (
        <>
          <ContentWrapper>
            <Titlebar
              showBackButton
              onBackClick={() => goBack({ history, pathname })}
              headingProps={{
                "data-test": "your-instructor",
              }}
              centerText={false}
            >
              {getText("returning/your-instructor.pageTitle")}
            </Titlebar>
            <Typography variant="h4" className={classes.subHeading}>
              Tell us about your instructor
            </Typography>
            <Awards
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          </ContentWrapper>
        </>
      )}
    </div>
  );
};

export default withRouter(YourInstructor);
