/* THIS FILE IS AUTOGENERATED. DO NOT MODIFY */
/* eslint-disable */
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: any;
  GraphQLDate: any;
  PhoneNumber: any;
  Postcode: any;
  Upload: any;
};

export type AccountAdjustmentProduct = {
  __typename?: "AccountAdjustmentProduct";
  productId: Scalars["ID"];
  name: Scalars["String"];
};

export type AddToBuildPlanInput = {
  vehicleKeys: Array<Scalars["ID"]>;
  supplierHubLocationKey: Scalars["ID"];
  livery?: Maybe<LiveryEnum>;
  adaptions: VehicleAdaptions;
  requiredCompletionDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type AdditionalDriver = {
  __typename?: "AdditionalDriver";
  id: Scalars["ID"];
  name?: Maybe<Name>;
  dateOfBirth: Scalars["GraphQLDate"];
  licence: Licence;
  fromDate: Scalars["GraphQLDate"];
  relationship: Relationship;
  instructor: Instructor;
};

export type Address = {
  __typename?: "Address";
  id: Scalars["ID"];
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  town?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["Postcode"]>;
};

export type AddressForTopUpDetails = {
  __typename?: "AddressForTopUpDetails";
  postcode: Scalars["String"];
};

export type AddressInput = {
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  town?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["Postcode"]>;
};

export enum AgreementDocumentStatus {
  Approved = "Approved",
  Rejected = "Rejected",
}

export enum AgreementDocumentType {
  Generic = "Generic",
  Custom = "Custom",
  Template = "Template",
  Upload = "Upload",
  Uploaded = "Uploaded",
}

export type AgreementDocuments = {
  __typename?: "AgreementDocuments";
  agreementName: Scalars["String"];
  active: Scalars["Boolean"];
  documents: Array<AgreementInstructorDocument>;
  fromDate?: Maybe<Scalars["GraphQLDate"]>;
  toDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type AgreementInstructorDocument = {
  __typename?: "AgreementInstructorDocument";
  documentRevisionKey?: Maybe<Scalars["String"]>;
  FADocumentKey: Scalars["String"];
  documentType: Scalars["String"];
  expiry?: Maybe<Scalars["GraphQLDate"]>;
  status: Scalars["String"];
  active: Scalars["Boolean"];
  url?: Maybe<Scalars["String"]>;
  uploadedDate?: Maybe<Scalars["GraphQLDate"]>;
  uploadedBy?: Maybe<Scalars["String"]>;
  dateApproved?: Maybe<Scalars["GraphQLDate"]>;
  approvedBy?: Maybe<Scalars["String"]>;
  documentDescription?: Maybe<Scalars["String"]>;
  agreementDocumentType: Scalars["String"];
};

export type AllocateVehicleInput = {
  vehicleKey: Scalars["ID"];
  personKey: Scalars["ID"];
  lineItemKey: Scalars["ID"];
};

export type Allocation = {
  __typename?: "Allocation";
  id: Scalars["ID"];
  instructor: Instructor;
  active: Scalars["Boolean"];
  fromDate: Scalars["GraphQLDate"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  lessonType: LessonType;
  learnerLessonType: LearnerLessonType;
  allocationStatus?: Maybe<LearnerAllocationStatus>;
  /** @deprecated Use purchaseRate and purchaseVatRate. */
  rate?: Maybe<Scalars["Float"]>;
  transmission: TransmissionType;
  balance: Scalars["Float"];
  proposition: Proposition;
  discounts: Array<Discount>;
  fundingSources: Array<FundingSource>;
  propositionAllocationKey?: Maybe<Scalars["ID"]>;
  productCategory?: Maybe<ProductCategoryType>;
  purchaseRate?: Maybe<Scalars["Float"]>;
  /** @deprecated Not being resolved correctly for DIT. Use purchaseVatRate. */
  purchaseVAT?: Maybe<Scalars["Float"]>;
  purchaseVatRate?: Maybe<Scalars["Float"]>;
};

export type AllocationDiscountsArgs = {
  input?: Maybe<DiscountFilter>;
};

export type AllocationFilter = {
  active?: Maybe<Scalars["Boolean"]>;
  instructorId?: Maybe<Scalars["ID"]>;
};

export type AnalyticsInput = {
  campaign?: Maybe<Scalars["String"]>;
  campaignId?: Maybe<Scalars["String"]>;
  sessionId?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  medium?: Maybe<Scalars["String"]>;
};

export type AreaCoverageRegionResult = {
  __typename?: "AreaCoverageRegionResult";
  id: Scalars["ID"];
};

export type Arrears = {
  __typename?: "Arrears";
  id?: Maybe<Scalars["ID"]>;
  reference: Scalars["String"];
  amount: Scalars["Float"];
  date?: Maybe<Scalars["GraphQLDate"]>;
};

export type ArrearsDetailInput = {
  id: Scalars["ID"];
  propositionKey?: Maybe<Scalars["ID"]>;
};

export type ArrearsFlag = {
  __typename?: "ArrearsFlag";
  inArrears: Scalars["Boolean"];
};

export type AssignNextTicketInput = {
  ticketQueueKey: Scalars["ID"];
};

export type Availability = {
  __typename?: "Availability";
  id: Scalars["ID"];
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
};

export type AvailabilityFilter = {
  start: Scalars["GraphQLDate"];
  end: Scalars["GraphQLDate"];
  brand: Brand;
  transmissionType: TransmissionType;
  lessonLengthHours: Scalars["Int"];
  startTimeOffset?: Maybe<Scalars["Int"]>;
};

export type AvailabilityNuj = {
  __typename?: "AvailabilityNUJ";
  initialDate: Scalars["GraphQLDate"];
  availableDates: Array<AvailableDateNuj>;
};

export type AvailabilityNujInput = {
  instructorId: Scalars["ID"];
  brand: Brand;
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
  transmissionType: TransmissionType;
};

export type AvailableDateNuj = {
  __typename?: "AvailableDateNUJ";
  date: Scalars["GraphQLDate"];
  timeSlots: Array<Maybe<TimeSlotNuj>>;
};

export type AwaitingDeliveryRecord = {
  __typename?: "AwaitingDeliveryRecord";
  id: Scalars["ID"];
  stageExpectedDate?: Maybe<Scalars["GraphQLDate"]>;
  issueFlaggedStatus?: Maybe<Scalars["Boolean"]>;
};

export enum Award {
  PerfectForBeginners = "PERFECT_FOR_BEGINNERS",
  CalmUnderPressure = "CALM_UNDER_PRESSURE",
  AboveAndBeyond = "ABOVE_AND_BEYOND",
  ClearCommunicator = "CLEAR_COMMUNICATOR",
  ConfidenceBuilder = "CONFIDENCE_BUILDER",
  FriendlyAndPolite = "FRIENDLY_AND_POLITE",
  TopSupporter = "TOP_SUPPORTER",
  PatientAndUnderstanding = "PATIENT_AND_UNDERSTANDING",
  GreatListener = "GREAT_LISTENER",
  DevelopmentFocused = "DEVELOPMENT_FOCUSED",
}

export type BankDetails = {
  __typename?: "BankDetails";
  id: Scalars["ID"];
  sapId?: Maybe<Scalars["String"]>;
  sortCode: Scalars["String"];
  accountNumber: Scalars["String"];
  bankName: Scalars["String"];
  nameOnAccount?: Maybe<Scalars["String"]>;
  sapDirectDebitReference?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
};

export type BookedLesson = {
  __typename?: "BookedLesson";
  bookingId: Scalars["ID"];
  lessonDate?: Maybe<Scalars["GraphQLDate"]>;
  lessonLength?: Maybe<Scalars["Int"]>;
};

export type BookedLessons = {
  __typename?: "BookedLessons";
  upcoming: Array<BookedLesson>;
  past: Array<BookedLesson>;
};

export enum Brand {
  Aads = "AADS",
  Bsm = "BSM",
  Dual = "DUAL",
}

export type BrandAdaptions = {
  __typename?: "BrandAdaptions";
  vehicleAdaptions: VehicleAdaptions;
  price: Scalars["Float"];
};

export type BrandOrm = {
  __typename?: "BrandOrm";
  referenceId: Scalars["Int"];
  referenceItemId: Scalars["Int"];
  itemDescription: Scalars["String"];
  fromDate: Scalars["GraphQLDate"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  active: Scalars["Boolean"];
  createdDate: Scalars["GraphQLDate"];
};

export type Buffers = {
  __typename?: "Buffers";
  pdf?: Maybe<Scalars["String"]>;
  customerData?: Maybe<Scalars["String"]>;
  signatures?: Maybe<Scalars["String"]>;
};

export type CabRank = {
  __typename?: "CabRank";
  aa: Array<Instructor>;
  bsm: Array<Instructor>;
  dual: Array<Instructor>;
};

export type CabRankInput = {
  postcode: Scalars["Postcode"];
  lessonType: LearnerLessonType;
  transmission: TransmissionType;
  excludeInstructorIds?: Maybe<Array<Scalars["ID"]>>;
  offersShortNoticeTest?: Maybe<Scalars["Boolean"]>;
  brand: Brand;
  lessonLengthHours: Scalars["Int"];
  mustBeAvailableAtStartDate?: Maybe<Scalars["GraphQLDate"]>;
  mustBeAvailableAtEndDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type CabRankNujInput = {
  postcode: Scalars["Postcode"];
  lessonType: LearnerLessonType;
  transmission: TransmissionType;
  excludeInstructorIds?: Maybe<Array<Scalars["ID"]>>;
  offersShortNoticeTest: Scalars["Boolean"];
  brand: Brand;
  lessonLengthHours: Scalars["Int"];
  marketingChannel?: Maybe<MarketingChannel>;
  marketingChannelId?: Maybe<Scalars["String"]>;
  lessonPurchaseType?: Maybe<LessonPurchaseType>;
  clientId?: Maybe<Scalars["String"]>;
  keyWorker?: Maybe<KeyWorkerInfo>;
  testDateTime?: Maybe<Scalars["GraphQLDate"]>;
  testCentre?: Maybe<Scalars["String"]>;
};

export enum CacheControlScope {
  Public = "PUBLIC",
  Private = "PRIVATE",
}

export type CalculateChangeInstructorBalanceResult = {
  __typename?: "CalculateChangeInstructorBalanceResult";
  id: Scalars["ID"];
  balance?: Maybe<Scalars["Float"]>;
};

export type CalculateChangeInstructorInput = {
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  brand: Brand;
  transmission: TransmissionType;
  lessonType: LearnerLessonType;
  isShortNoticeTest?: Maybe<Scalars["Boolean"]>;
};

export type CalendarEvent = Lesson | TimeOff;

export type CalendarEventInput = {
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
};

export type CancelLessonForRujInput = {
  learnerId: Scalars["ID"];
  bookingId: Scalars["ID"];
  reason: Scalars["String"];
};

export type CancelLessonInput = {
  id: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
  refundLearner?: Maybe<Scalars["Boolean"]>;
};

export type CancelPendingAccountAdjustmentInput = {
  pendingLedgerItemKey: Scalars["ID"];
  cancelRecurring?: Maybe<Scalars["Boolean"]>;
};

export type CancelPendingLessonsInput = {
  ids: Array<Scalars["ID"]>;
};

export type CancelTimeOffInput = {
  id: Scalars["ID"];
  reason?: Maybe<Scalars["String"]>;
  cancelSeries?: Maybe<Scalars["Boolean"]>;
};

export type Certification = {
  __typename?: "Certification";
  id: Scalars["ID"];
  type: CertificationType;
  reference?: Maybe<Scalars["String"]>;
  fromDate?: Maybe<Scalars["GraphQLDate"]>;
  toDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type CertificationListItem = {
  __typename?: "CertificationListItem";
  id: Scalars["ID"];
  title: CertificationType;
};

export enum CertificationType {
  AdiBadge = "ADIBadge",
  Dbs = "DBS",
  PdiBadge = "PDIBadge",
  Qef = "QEF",
  Rospa = "ROSPA",
  Provisional = "Provisional",
  Full = "Full",
  StandardsCheck = "StandardsCheck",
  Diamond = "Diamond",
  Iam = "IAM",
  HiTech = "HiTech",
  PublicLiability = "PublicLiability",
}

export type ChangeDitInstructorInput = {
  learnerKey: Scalars["ID"];
  instructorKey: Scalars["ID"];
};

export type ChangeInstructorInput = {
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  brand: Brand;
  transmission: TransmissionType;
  lessonType: LearnerLessonType;
  lessonStartDateTime?: Maybe<Scalars["GraphQLDate"]>;
  lessonEndDateTime?: Maybe<Scalars["GraphQLDate"]>;
  isShortNoticeTest?: Maybe<Scalars["Boolean"]>;
  businessCoversExcess?: Maybe<Scalars["Boolean"]>;
};

export type ChangeInstructorPaymentCompleteInput = {
  orderId: Scalars["ID"];
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  brand: Brand;
  transmission: TransmissionType;
  lessonType: LearnerLessonType;
  lessonStartDateTime?: Maybe<Scalars["GraphQLDate"]>;
  lessonEndDateTime?: Maybe<Scalars["GraphQLDate"]>;
  isShortNoticeTest: Scalars["Boolean"];
  staffUserId: Scalars["String"];
  businessCoversExcess: Scalars["Boolean"];
  pendingReallocationOrders: Array<PaymentReallocationOrder>;
};

export type ChangeInstructorPaymentResult = {
  __typename?: "ChangeInstructorPaymentResult";
  learner: Learner;
  newOrderPayment?: Maybe<InflightOrderPayment>;
};

export enum ChangeInstructorReason {
  InstructorLeaving = "InstructorLeaving",
  InstructorAvailability = "InstructorAvailability",
  MovingAddress = "MovingAddress",
  Complaint = "Complaint",
  UnhappyWithService = "UnhappyWithService",
  PracticalTestCover = "PracticalTestCover",
}

export type ChangeLessonInput = {
  id: Scalars["ID"];
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  travelTime: Scalars["Int"];
  test?: Maybe<Scalars["Boolean"]>;
};

export type ChangeMotabilityToLearnerInput = {
  learnerKey: Scalars["ID"];
};

export type CheckShortNoticeTestAvailabilityInput = {
  lineItemKey: Scalars["ID"];
  startDateTime: Scalars["GraphQLDate"];
};

export type ClearDownLearnerInput = {
  learnerId: Scalars["ID"];
};

export type CombinedAvailability = {
  __typename?: "CombinedAvailability";
  instructors: Array<Instructor>;
  availability: Array<Availability>;
};

export type CombinedAvailabilityInput = {
  instructorIds: Array<Scalars["ID"]>;
  start: Scalars["GraphQLDate"];
  end: Scalars["GraphQLDate"];
  brand: Brand;
  transmissionType: TransmissionType;
  lessonLengthHours: Scalars["Int"];
  startTimeOffset?: Maybe<Scalars["Int"]>;
};

export type CommissioningReservedAndCommittedFilter = {
  Lead?: Maybe<Scalars["Boolean"]>;
  Renewals?: Maybe<Scalars["Boolean"]>;
  Exchange?: Maybe<Scalars["Boolean"]>;
  NewADI?: Maybe<Scalars["Boolean"]>;
  PDIToADI?: Maybe<Scalars["Boolean"]>;
};

export enum CommissioningTableUserType {
  Lead = "Lead",
  Renewal = "Renewal",
  NewAdi = "NewADI",
  Exchange = "Exchange",
  PdiToAdi = "PDIToADI",
}

export type CommissioningUnallocatedFilter = {
  Online?: Maybe<Scalars["Boolean"]>;
  Sales?: Maybe<Scalars["Boolean"]>;
  Renewals?: Maybe<Scalars["Boolean"]>;
  Courtesy?: Maybe<Scalars["Boolean"]>;
  Interim?: Maybe<Scalars["Boolean"]>;
  Unassigned?: Maybe<Scalars["Boolean"]>;
};

export enum CommittedFilter {
  All = "All",
  Yes = "Yes",
  No = "No",
}

export type CommunicationConsent = {
  __typename?: "CommunicationConsent";
  marketing: CommunicationMarketingConsent;
  reminder: CommunicationReminderConsent;
};

export type CommunicationConsentInput = {
  personKey?: Maybe<Scalars["ID"]>;
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  reminder?: Maybe<CommunicationReminderConsentInput>;
};

export type CommunicationMarketingConsent = {
  __typename?: "CommunicationMarketingConsent";
  email: Scalars["Boolean"];
  sms: Scalars["Boolean"];
  phone: Scalars["Boolean"];
  post: Scalars["Boolean"];
};

export type CommunicationMarketingConsentInput = {
  email?: Maybe<Scalars["Boolean"]>;
  sms?: Maybe<Scalars["Boolean"]>;
  phone?: Maybe<Scalars["Boolean"]>;
  post?: Maybe<Scalars["Boolean"]>;
};

export type CommunicationReminderConsent = {
  __typename?: "CommunicationReminderConsent";
  sms: Scalars["Boolean"];
};

export type CommunicationReminderConsentInput = {
  sms?: Maybe<Scalars["Boolean"]>;
};

export type CompleteDitCheckoutInput = {
  accountNumber?: Maybe<Scalars["String"]>;
  sortCode?: Maybe<Scalars["String"]>;
  nameOnAccount?: Maybe<Scalars["String"]>;
  leadEmail: Scalars["String"];
  orderKey: Scalars["ID"];
  propositionKeys: Array<Scalars["ID"]>;
  paymentPlanMonths?: Maybe<Scalars["Int"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  purchaseSource?: Maybe<PurchaseSource>;
};

export type CompleteFranchisePurchaseLeadInput = {
  leadEmail: Scalars["String"];
  manufacturerCode: Scalars["String"];
  colour: Scalars["String"];
  propositionKey: Scalars["ID"];
  startDate: Scalars["GraphQLDate"];
  vehicleName?: Maybe<Scalars["String"]>;
};

export type CompleteFranchisePurchaseLeadResult = {
  __typename?: "CompleteFranchisePurchaseLeadResult";
  vehicleReserved: Scalars["Boolean"];
  creditCheckPassed: Scalars["Boolean"];
  agreementKey?: Maybe<Scalars["String"]>;
  instructorId?: Maybe<Scalars["Int"]>;
};

export type CompleteIgCheckoutInput = {
  accountNumber?: Maybe<Scalars["String"]>;
  sortCode?: Maybe<Scalars["String"]>;
  nameOnAccount?: Maybe<Scalars["String"]>;
  leadEmail?: Maybe<Scalars["String"]>;
  instructorKey?: Maybe<Scalars["ID"]>;
  orderKey?: Maybe<Scalars["ID"]>;
  propositionKeys: Array<Scalars["ID"]>;
  franchiseStartDate: Scalars["GraphQLDate"];
  discountKey?: Maybe<Scalars["ID"]>;
  isPending?: Maybe<Scalars["Boolean"]>;
  vehicleKey?: Maybe<Scalars["ID"]>;
  customLivery?: Maybe<Scalars["String"]>;
  bespokeWeeklyPayment?: Maybe<Scalars["Float"]>;
};

export type CompleteOnlineDitCheckoutInput = {
  leadEmail: Scalars["String"];
  courseType: CourseType;
  orderKey: Scalars["ID"];
  nameOnAccount?: Maybe<Scalars["String"]>;
  accountNumber?: Maybe<Scalars["String"]>;
  sortCode?: Maybe<Scalars["String"]>;
  instalments?: Maybe<Scalars["Int"]>;
  purchaseSource?: Maybe<PurchaseSource>;
};

export type CompletePaymentForDepositAndAdminFeeInput = {
  franchiseStartDate: Scalars["GraphQLDate"];
  orderKey: Scalars["ID"];
};

export type ConvertPendingLessonsInput = {
  ids: Array<Scalars["ID"]>;
};

export enum CourseType {
  One2One = "One2One",
  Vr = "VR",
}

export type CoveredRegion = {
  __typename?: "CoveredRegion";
  id: Scalars["ID"];
  region: Region;
  pricing?: Maybe<Array<Maybe<RegionCoveragePricing>>>;
  discounts?: Maybe<Array<Maybe<Discount>>>;
};

export type CreateAccountAdjustmentInput = {
  instructorKey: Scalars["ID"];
  productKey: Scalars["ID"];
  fromDate: Scalars["GraphQLDate"];
  toDate: Scalars["GraphQLDate"];
  frequency: Frequency;
  customValue: Scalars["Float"];
};

export type CreateAdditionalDriverInput = {
  instructorId: Scalars["ID"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  relationship: Relationship;
  licenceNumber: Scalars["String"];
  licenceExpiryDate: Scalars["GraphQLDate"];
};

export type CreateAdhocPaymentOrderInput = {
  instructorId: Scalars["ID"];
  amount: Scalars["Float"];
};

export type CreateAreaCoverageInput = {
  instructorId: Scalars["ID"];
  lineItemKey: Scalars["ID"];
  regionCodeKey: Scalars["ID"];
  discounts: Array<RegionalDiscountsInput>;
  districtOptIns: Array<CreateDistrictCoverageInput>;
  preTestPrice?: Maybe<Scalars["Float"]>;
  postTestPrice?: Maybe<Scalars["Float"]>;
};

export type CreateAreaCoverageResult = {
  __typename?: "CreateAreaCoverageResult";
  lineItemCoverageKey: Scalars["ID"];
  price: PricingUpdateResult;
  region: AreaCoverageRegionResult;
  discounts?: Maybe<Array<Maybe<DiscountUpdateResult>>>;
};

export type CreateCertificationInput = {
  instructorId: Scalars["ID"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  certificationTypeId: Scalars["Int"];
  certificationReference?: Maybe<Scalars["String"]>;
};

export type CreateDefermentAccountAdjustmentInput = {
  instructorKey: Scalars["ID"];
  productKey: Scalars["ID"];
  customValue: Scalars["Float"];
  fromDate: Scalars["GraphQLDate"];
  toDate: Scalars["GraphQLDate"];
  planFromDate: Scalars["GraphQLDate"];
  planToDate: Scalars["GraphQLDate"];
};

export type CreateDistrictCoverageInput = {
  id: Scalars["String"];
  sectorOptOuts: Array<CreateSectorCoverageInput>;
};

export type CreateDitLeadCheckoutOrderInput = {
  propositionKeys: Array<Scalars["ID"]>;
  leadEmail: Scalars["String"];
  paymentPlanMonths?: Maybe<Scalars["Int"]>;
  discountAmount?: Maybe<Scalars["Int"]>;
  fundingSourceKey?: Maybe<Scalars["ID"]>;
  fundingSourceValue?: Maybe<Scalars["Float"]>;
};

export type CreateEndCallEventInput = {
  reason: Scalars["String"];
  amount?: Maybe<Scalars["Float"]>;
};

export type CreateEnquiryLeadInput = {
  title?: Maybe<Salutation>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
  emailAddress: Scalars["String"];
  postcode: Scalars["Postcode"];
  drivingLicence?: Maybe<Scalars["Boolean"]>;
  enquiryType: LeadType;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  address?: Maybe<AddressInput>;
  channel: TicketSource;
  brand: Brand;
  clientId?: Maybe<Scalars["String"]>;
  analytics: AnalyticsInput;
};

export type CreateFranchiseAgreementInput = {
  propositionIds: Array<Scalars["ID"]>;
  instructorKey: Scalars["ID"];
  leadType: Scalars["String"];
  startDate: Scalars["GraphQLDate"];
  discountKey?: Maybe<Scalars["ID"]>;
  adminFeeDiscountAmount?: Maybe<Scalars["Float"]>;
  leadEmail?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  vehicleKey?: Maybe<Scalars["ID"]>;
  customLivery?: Maybe<Scalars["String"]>;
  bespokeWeeklyPayment?: Maybe<Scalars["Float"]>;
};

export type CreateIgLeadCheckoutOrderInput = {
  propositionIds: Array<Scalars["ID"]>;
  leadEmail?: Maybe<Scalars["String"]>;
  instructorKey?: Maybe<Scalars["ID"]>;
  amount?: Maybe<Scalars["Float"]>;
  adminFeeDiscountAmount?: Maybe<Scalars["Float"]>;
  vehicleKey?: Maybe<Scalars["ID"]>;
};

export type CreateInstructorRatingInput = {
  brand: Brand;
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  rating: Scalars["Int"];
  review: Scalars["String"];
};

export type CreateInstructorRatingResult = {
  __typename?: "CreateInstructorRatingResult";
  successful: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type CreateLeadInput = {
  salutation: Salutation;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  mobileNumber: Scalars["String"];
  emailAddress: Scalars["String"];
  address: AddressInput;
  dateOfBirth: Scalars["GraphQLDate"];
  licenceNumber?: Maybe<Scalars["String"]>;
  communication: CommunicationConsentInput;
  recommenderId?: Maybe<Scalars["Int"]>;
  channel: TicketSource;
  type: LeadType;
  assignedStaffKey?: Maybe<Scalars["ID"]>;
};

export type CreateLearnerInput = {
  title: Salutation;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressCounty: Scalars["String"];
  addressIsPickup: Scalars["Boolean"];
  addressPostcode: Scalars["String"];
  contactEmail: Scalars["String"];
  contactMobile: Scalars["String"];
  smsRemindersOptIn: Scalars["Boolean"];
  instructorId?: Maybe<Scalars["ID"]>;
  lineItemId?: Maybe<Scalars["ID"]>;
  transmissionId?: Maybe<Scalars["Int"]>;
  learnerType: PersonType;
  lessonType: LearnerLessonType;
  hourlyRate?: Maybe<Scalars["Float"]>;
  sourcedBy: UserType;
  status: PersonStatus;
  appointedBy?: Maybe<Scalars["String"]>;
  billingAddress?: Maybe<AddressInput>;
};

export type CreateLearnerWithTopupForNujInput = {
  brand: Brand;
  learner: NujLearnerInput;
  lesson: LessonSummaryInput;
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  packageHours: Scalars["Int"];
  lessonType: LearnerLessonType;
  lessonPurchaseType?: Maybe<LessonPurchaseType>;
  keyWorker?: Maybe<KeyWorkerInfo>;
  testDateTime?: Maybe<Scalars["GraphQLDate"]>;
  testCentre?: Maybe<Scalars["String"]>;
  giftGiver?: Maybe<GiftPersonInput>;
};

export type CreateLearnerWithTopupForNujResult = {
  __typename?: "CreateLearnerWithTopupForNUJResult";
  successful: Scalars["Boolean"];
  errorType?: Maybe<Scalars["String"]>;
  order: NujOrder;
};

export type CreateLearnerWithTopupInput = {
  addressCounty: Scalars["String"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  brand: Brand;
  dateOfBirth: Scalars["GraphQLDate"];
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  instructorId: Scalars["ID"];
  lastName: Scalars["String"];
  lessonEndDateTime: Scalars["GraphQLDate"];
  lessonRecipient: Scalars["String"];
  lessonStartDateTime: Scalars["GraphQLDate"];
  lessonType: LearnerLessonType;
  marketing?: Maybe<CommunicationMarketingConsentInput>;
  mobileNumber: Scalars["String"];
  packageHours: Scalars["Int"];
  pickupPostcode: Scalars["String"];
  referredBy: Scalars["String"];
  title: Salutation;
  transmission: TransmissionType;
  isShortNoticeTest?: Maybe<Scalars["Boolean"]>;
  fundingSourceKey?: Maybe<Scalars["ID"]>;
  fundingSourceValue?: Maybe<Scalars["Float"]>;
  billingAddress?: Maybe<AddressInput>;
};

export type CreateLearnerWithTopupResult = {
  __typename?: "CreateLearnerWithTopupResult";
  learner: Learner;
  inflightOrderPayment: InflightOrderPayment;
};

export type CreateLessonInput = {
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  travelTime?: Maybe<Scalars["Int"]>;
  status: LessonStatus;
  notes?: Maybe<Scalars["String"]>;
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  createFromLearnerView?: Maybe<Scalars["Boolean"]>;
  ditLessonType?: Maybe<DitLessonType>;
  test?: Maybe<Scalars["Boolean"]>;
};

export type CreateMessageMutationInput = {
  instructorId: Scalars["ID"];
  body: Scalars["String"];
  from?: Maybe<Scalars["Int"]>;
};

export type CreateNewPriceInput = {
  id?: Maybe<Scalars["ID"]>;
  price?: Maybe<Scalars["Float"]>;
};

export type CreateNudgebackInput = {
  brand: Brand;
  mobileNumber: Scalars["String"];
  lessonType: LessonType;
  postcode: Scalars["String"];
  transmissionType: TransmissionType;
  timestamp: Scalars["String"];
  ip?: Maybe<Scalars["String"]>;
  type: NudgebackType;
};

export type CreateNudgebackResult = {
  __typename?: "CreateNudgebackResult";
  successful: Scalars["Boolean"];
};

export type CreateOnlinePurchaseOrderInput = {
  courseType: CourseType;
  leadEmail: Scalars["String"];
  paymentPlanMonths?: Maybe<Scalars["Int"]>;
};

export type CreatePaymentPlanAccountAdjustmentInput = {
  instructorKey: Scalars["ID"];
  customValue: Scalars["Float"];
  fromDate: Scalars["GraphQLDate"];
  toDate: Scalars["GraphQLDate"];
  frequency: Frequency;
};

export type CreatePurchaseBankDetailsInput = {
  accountNumber: Scalars["String"];
  leadEmail: Scalars["String"];
  accountHolderName: Scalars["String"];
  sortCode: Scalars["String"];
};

export type CreatePurchaseLeadError = {
  __typename?: "CreatePurchaseLeadError";
  type: CreatePurchaseLeadFailureType;
  reason: CreatePurchaseLeadFailureReason;
};

export type CreatePurchaseLeadFailure = {
  __typename?: "CreatePurchaseLeadFailure";
  error: CreatePurchaseLeadError;
};

export enum CreatePurchaseLeadFailureReason {
  Contact = "Contact",
  Processing = "Processing",
}

export enum CreatePurchaseLeadFailureType {
  Generic = "Generic",
  Referral = "Referral",
}

export type CreatePurchaseLeadInput = {
  brand: Brand;
  purchaseType: LeadType;
  course?: Maybe<PurchaseLeadCourseInput>;
  packageType?: Maybe<FranchiseType>;
  contractTerm?: Maybe<Scalars["Int"]>;
  transmissionType: TransmissionType;
  vehicleKey?: Maybe<Scalars["ID"]>;
  isAdiHolder?: Maybe<Scalars["Boolean"]>;
  isConvicted?: Maybe<Scalars["Boolean"]>;
  personal: PurchaseLeadPersonalInput;
  address: PurchaseLeadAddressInput;
  billingAddress?: Maybe<PurchaseLeadAddressInput>;
  marketing: PurchaseLeadMarketingInput;
  terms: Scalars["Boolean"];
  clientId?: Maybe<Scalars["String"]>;
  analytics: AnalyticsInput;
  failedQualifyingStatements?: Maybe<Array<FranchiseQualifyingStatement>>;
};

export type CreatePurchaseLeadResult =
  | CreatePurchaseLeadSuccess
  | CreatePurchaseLeadFailure;

export type CreatePurchaseLeadSuccess = {
  __typename?: "CreatePurchaseLeadSuccess";
  id: Scalars["ID"];
};

export type CreateSectorCoverageInput = {
  id: Scalars["String"];
};

export type CreateSelfSourcedLearnerInput = {
  title: Salutation;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  addressLine1: Scalars["String"];
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressCounty: Scalars["String"];
  addressIsPickup: Scalars["Boolean"];
  addressPostcode: Scalars["String"];
  contactEmail?: Maybe<Scalars["String"]>;
  contactMobile?: Maybe<Scalars["String"]>;
  smsRemindersOptIn: Scalars["Boolean"];
  instructorId: Scalars["ID"];
  lineItemId: Scalars["ID"];
  learnerType: PersonType;
  lessonType: LearnerLessonType;
  hourlyRate: Scalars["Float"];
  sourcedBy: UserType;
};

export type CreateSupportMessageInput = {
  message: Scalars["String"];
  pupilId: Scalars["String"];
};

export type CreateTestMutationInput = {
  learnerId: Scalars["ID"];
  status: TestStatus;
  type: TestType;
  date: Scalars["GraphQLDate"];
};

export type CreateTicketInput = {
  personKey: Scalars["ID"];
  assignedStaffKey: Scalars["ID"];
  ticketSource: TicketSource;
  ticketQueueKey: Scalars["ID"];
};

export type CreateTimeOffInput = {
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  notes: Scalars["String"];
  instructorId: Scalars["ID"];
  repeatSchedule?: Maybe<RepeatSchedule>;
};

export type CreateTopUpOrderInput = {
  learnerId: Scalars["ID"];
  hours: Scalars["Int"];
  fundingSourceKey?: Maybe<Scalars["ID"]>;
  fundingSourceValue?: Maybe<Scalars["Float"]>;
};

export type CreateUnassignedTicketInput = {
  personKey: Scalars["ID"];
  ticketSource: TicketSource;
  ticketQueueType: TicketQueueType;
  initialContactId?: Maybe<Scalars["String"]>;
};

export type CreateVehicleInput = {
  instructorId: Scalars["ID"];
  registration: Scalars["String"];
  manufacturer: Scalars["String"];
  model: Scalars["String"];
  colour: Scalars["String"];
  specification: Scalars["String"];
  transmissionType: TransmissionType;
  fuelType: FuelType;
  mileage?: Maybe<Scalars["Int"]>;
  certificateNumber?: Maybe<Scalars["String"]>;
  insuranceStartDate?: Maybe<Scalars["GraphQLDate"]>;
  insuranceEndDate?: Maybe<Scalars["GraphQLDate"]>;
  vehicleType: VehicleType;
  isStandardAdaptionsPackFitted?: Maybe<Scalars["Boolean"]>;
};

export type CreateWelfareCategoryInput = {
  categoryTypeId: Scalars["Int"];
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  note?: Maybe<Scalars["String"]>;
  outcomes?: Maybe<Array<CreateWelfareOutcomeInput>>;
  personKey: Scalars["ID"];
};

export type CreateWelfareOutcomeInput = {
  outcomeTypeId: Scalars["Int"];
  note: Scalars["String"];
};

export type CreateWorkingHoursMutationInput = {
  instructorId: Scalars["String"];
  days: Array<Maybe<DayInput>>;
};

export type CreatedOrder = {
  __typename?: "CreatedOrder";
  token: Scalars["String"];
  endpoint: Scalars["String"];
};

export type CurrentBuildPlanFilter = {
  make?: Maybe<Scalars["String"]>;
  transmission?: Maybe<TransmissionTypeFilter>;
  committed?: Maybe<CommittedFilter>;
  supplier?: Maybe<SupplierFilter>;
};

export type CustomDocument = {
  __typename?: "CustomDocument";
  documentName: Scalars["String"];
  documentKey: Scalars["String"];
  documentRevisionKey: Scalars["String"];
  buffers?: Maybe<Buffers>;
};

export type Customer = Learner | Instructor | Lead;

export type CustomerAndFranchiseDocuments = {
  __typename?: "CustomerAndFranchiseDocuments";
  customerDocuments: Array<AgreementInstructorDocument>;
  franchiseDocuments: Array<AgreementDocuments>;
};

export type CustomerSearchInput = {
  term: Scalars["String"];
  customerTypes?: Maybe<Array<PersonType>>;
  customerStatuses?: Maybe<Array<PersonStatus>>;
};

export type CustomerType = {
  __typename?: "CustomerType";
  id: Scalars["ID"];
  type: PersonType;
  hasActivePDIFranchise?: Maybe<Scalars["Boolean"]>;
  fromDate: Scalars["GraphQLDate"];
};

export type DayInput = {
  dayNumber: Scalars["Int"];
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
};

export type DeallocateInstructorInput = {
  learnerId: Scalars["ID"];
};

export type DeallocateVehicleInput = {
  vehiclePersonHistoryKey: Scalars["ID"];
};

export type DeleteLedgerMutationInput = {
  ledgerKey: Scalars["ID"];
};

export type DeleteWorkingHoursMutationInput = {
  dayKeys: Array<Maybe<Scalars["String"]>>;
};

export type DeletedResult = {
  __typename?: "DeletedResult";
  affected: Array<Scalars["ID"]>;
};

export enum DeliveryIssueAction {
  Repair = "Repair",
  Rearrange = "Rearrange",
}

export type DirectDebitInstalments = {
  __typename?: "DirectDebitInstalments";
  firstInstalment: Scalars["Float"];
  monthlyInstalment: Scalars["Float"];
};

export enum DisabledWorkItemType {
  DitSupportAccountManagerOther = "DitSupportAccountManagerOther",
  DitSupportBsmVrBooking = "DitSupportBsmVrBooking",
  DitSupportBsmVrReschedule = "DitSupportBsmVrReschedule",
  DitSupportDbsApplicationSubmitted = "DitSupportDbsApplicationSubmitted",
  DitSupportDbsFollowUp = "DitSupportDbsFollowUp",
  DitSupportDbsRequested = "DitSupportDbsRequested",
  DitSupportOnlineClassroomBooking = "DitSupportOnlineClassroomBooking",
  DitSupportPrnApplicationSubmitted = "DitSupportPrnApplicationSubmitted",
  DitSupportPrnFollowUp = "DitSupportPrnFollowUp",
  DitSupportPrnRequested = "DitSupportPrnRequested",
}

export type Discount = {
  __typename?: "Discount";
  id: Scalars["ID"];
  discountName: Scalars["String"];
  discountType?: Maybe<DiscountType>;
  valueType?: Maybe<DiscountValueType>;
  description: Scalars["String"];
  value: Scalars["Float"];
  hoursRangeStart?: Maybe<Scalars["Int"]>;
  hoursRangeEnd?: Maybe<Scalars["Int"]>;
};

export type DiscountFilter = {
  discountType?: Maybe<DiscountType>;
};

export enum DiscountType {
  NewLearner = "NewLearner",
  ExistingLearner = "ExistingLearner",
}

export type DiscountUpdateResult = {
  __typename?: "DiscountUpdateResult";
  id: Scalars["ID"];
  optIn: Scalars["Boolean"];
};

export enum DiscountValueType {
  Percentage = "Percentage",
  Hourly = "Hourly",
  Variable = "Variable",
}

export type DiscountsInput = {
  propositionId: Scalars["ID"];
  productCategoryId: Scalars["ID"];
};

export type District = {
  __typename?: "District";
  id: Scalars["ID"];
  districtCode: Scalars["String"];
  sectors: Array<Maybe<Sector>>;
  covered?: Maybe<Scalars["Boolean"]>;
};

export enum DitLessonType {
  DitClassroom = "DITClassroom",
  DitCar = "DITCar",
}

export type DitOrderStatusResult = {
  __typename?: "DitOrderStatusResult";
  id: Scalars["ID"];
  status: Scalars["String"];
};

export type Document = {
  __typename?: "Document";
  documentId: Scalars["Int"];
  documentKey: Scalars["String"];
  documentName: Scalars["String"];
  versions: Array<Maybe<DocumentRevision>>;
  permissions: Array<Maybe<DocumentPermission>>;
};

export type DocumentPermission = {
  __typename?: "DocumentPermission";
  documentKey?: Maybe<Scalars["String"]>;
  document?: Maybe<Document>;
  person?: Maybe<Person>;
  permissions?: Maybe<Array<Maybe<DocumentPermissionType>>>;
  dateFrom: Scalars["GraphQLDate"];
  dateTo: Scalars["GraphQLDate"];
  active: Scalars["Boolean"];
};

export enum DocumentPermissionType {
  Rw = "rw",
  R = "r",
  W = "w",
}

export type DocumentRevision = {
  __typename?: "DocumentRevision";
  documentRevisionId: Scalars["Int"];
  documentRevisionKey: Scalars["String"];
  documentKey?: Maybe<Scalars["String"]>;
  document?: Maybe<Document>;
  versionName: Scalars["String"];
  person?: Maybe<Person>;
  dateFrom: Scalars["GraphQLDate"];
  dateTo: Scalars["GraphQLDate"];
  documentHash: Scalars["String"];
  active: Scalars["Boolean"];
};

export type DocumentRevisionResult = {
  __typename?: "DocumentRevisionResult";
  documentRevisionKey: Scalars["String"];
  versionName?: Maybe<Scalars["Int"]>;
  documentHash: Scalars["String"];
  dateFrom: Scalars["GraphQLDate"];
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
};

export type DocumentVersion = {
  __typename?: "DocumentVersion";
  documentKey: Scalars["String"];
  documentRevisionKey: Scalars["String"];
  documentName: Scalars["String"];
  documentType: Scalars["String"];
  dateUploaded: Scalars["GraphQLDate"];
  dateArchived?: Maybe<Scalars["GraphQLDate"]>;
  version: Scalars["Int"];
  url?: Maybe<Scalars["String"]>;
  uploadedBy: Scalars["String"];
  history?: Maybe<Array<Maybe<DocumentVersion>>>;
};

export type DownloadFileResult = {
  __typename?: "DownloadFileResult";
  success: Scalars["Boolean"];
  fileContent?: Maybe<Scalars["String"]>;
};

export type DsGlobalConfig = {
  __typename?: "DsGlobalConfig";
  sendMotabilityWelcomeEmailButtonEnabled: Scalars["Boolean"];
  instructorFutureTransactionsTableEnabled: Scalars["Boolean"];
  learnerClearDownEnabled: Scalars["Boolean"];
  instructorArrearsEnabled: Scalars["Boolean"];
  helixNewInvoiceTableEnabled: Scalars["Boolean"];
  hubcappReoccurringLessonsEnable: Scalars["Boolean"];
  fleetDatabaseSchemaMigrationEnabled: Scalars["Boolean"];
  fleetDashboardUsageEnabled: Scalars["Boolean"];
  authPortalEnabled: Scalars["Boolean"];
  newFranchiseSelectionEnabled: Scalars["Boolean"];
  myBusinessProfileEnabled: Scalars["Boolean"];
};

export type EditAdditionalDriverInput = {
  additionalDriverId: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  relationship?: Maybe<Relationship>;
  licenceNumber?: Maybe<Scalars["String"]>;
  licenceExpiryDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type EditBankDetailsInput = {
  personKey: Scalars["ID"];
  sortCode: Scalars["String"];
  accountNumber: Scalars["String"];
  bankName: Scalars["String"];
  nameOnAccount: Scalars["String"];
  sapDirectDebitReference?: Maybe<Scalars["String"]>;
  active: Scalars["Boolean"];
};

export type EditPoolsInput = {
  vehicleKeys: Array<Scalars["ID"]>;
  pools: Array<VehiclePool>;
};

export type EditTestMutationInput = {
  learnerId: Scalars["ID"];
  id: Scalars["ID"];
  status?: Maybe<TestStatus>;
  type?: Maybe<TestType>;
  date?: Maybe<Scalars["GraphQLDate"]>;
};

export type EditWorkingHoursMutationInput = {
  days: Array<Maybe<DayInput>>;
};

export type EmailAddressExistsCheckInput = {
  emailAddress: Scalars["EmailAddress"];
  excludePersonStatuses?: Maybe<Array<Maybe<PersonStatus>>>;
};

export type EmailAddressExistsCheckResult = {
  __typename?: "EmailAddressExistsCheckResult";
  exists: Scalars["Boolean"];
};

export type EndFranchiseInput = {
  instructorId: Scalars["ID"];
  propositionAllocationId: Scalars["ID"];
  endingReasonId: Scalars["Int"];
  endDate: Scalars["GraphQLDate"];
  lockCap: Scalars["Boolean"];
  reallocateLearners: Scalars["Boolean"];
  goodTerms: Scalars["Boolean"];
  dateToFeeCharged: Scalars["Boolean"];
};

export enum EndOfTermStatus {
  Decommissioning = "Decommissioning",
  Courtesy = "Courtesy",
  Interim = "Interim",
}

export type EventLog = {
  __typename?: "EventLog";
  id?: Maybe<Scalars["String"]>;
};

export type ExcessOrderPayment = {
  __typename?: "ExcessOrderPayment";
  id: Scalars["ID"];
  cardEnding: Scalars["String"];
  total: Scalars["Float"];
  businessFunded: Scalars["Boolean"];
};

export type ExportBuildPlanInput = {
  vehicleKeys: Array<Scalars["ID"]>;
};

export type ExportTemplateOfExchangesInput = {
  vehicleKeys: Array<Scalars["ID"]>;
  earliestDeliveryDate: Scalars["GraphQLDate"];
};

export enum FcaAgreementStatus {
  Yes = "Yes",
  No = "No",
}

export type FlagDeliveryIssueInput = {
  vehicleKey: Scalars["ID"];
  action: DeliveryIssueAction;
};

export type FormError = {
  __typename?: "FormError";
  field?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
};

export type FranchiseAgreement = {
  __typename?: "FranchiseAgreement";
  franchiseAgreementKey: Scalars["String"];
  franchiseAgreementId: Scalars["Int"];
  franchiseAgreementsDocuments?: Maybe<
    Array<Maybe<FranchiseAgreementDocument>>
  >;
  agreementKey: Scalars["String"];
  personKey: Scalars["String"];
  person: Learner;
  brand: BrandOrm;
  proposition: PropositionOrm;
  statusId: Scalars["Int"];
  status: FranchiseAgreementStatus;
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  dateFrom: Scalars["GraphQLDate"];
  active: Scalars["Boolean"];
  createdDate: Scalars["GraphQLDate"];
};

export type FranchiseAgreementAcceptance = {
  __typename?: "FranchiseAgreementAcceptance";
  franchiseAgreementKey: Scalars["String"];
  title: Scalars["String"];
  firstName: Scalars["String"];
  surname: Scalars["String"];
  leadType: LeadType;
  dateOfBirth: Scalars["GraphQLDate"];
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  contactPreferences: Scalars["String"];
  drivingNumber: Scalars["String"];
  adiNumber: Scalars["Int"];
  franchiseName: Scalars["String"];
  franchiseType: Scalars["String"];
  brand: Brand;
  duration: Scalars["Float"];
  startDateTime: Scalars["GraphQLDate"];
  paymentTerms: Scalars["String"];
  paymentType: Scalars["String"];
  totalPayable?: Maybe<Scalars["Float"]>;
  paymentDueWeekly: Scalars["Float"];
  documents: Array<SignedUrl>;
  personKey: Scalars["String"];
  channel: Scalars["String"];
  communication: CommunicationConsent;
};

export type FranchiseAgreementDocument = {
  __typename?: "FranchiseAgreementDocument";
  franchiseAgreementDocumentId: Scalars["Int"];
  franchiseAgreementDocumentKey: Scalars["String"];
  agreement?: Maybe<FranchiseAgreement>;
  document?: Maybe<Document>;
  version?: Maybe<DocumentRevision>;
  agreementDocumentTypeId: Scalars["Int"];
  AgreementDocumentStatusId: Scalars["Int"];
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  dateFrom: Scalars["GraphQLDate"];
  active: Scalars["Boolean"];
  createdDate: Scalars["GraphQLDate"];
};

export type FranchiseAgreementStatus = {
  __typename?: "FranchiseAgreementStatus";
  referenceId: Scalars["Int"];
  referenceItemId: Scalars["Int"];
  itemDescription: Scalars["String"];
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  dateFrom: Scalars["GraphQLDate"];
  active: Scalars["Boolean"];
  createdDate: Scalars["GraphQLDate"];
};

export type FranchiseDocuments = {
  __typename?: "FranchiseDocuments";
  generics: Array<GenericDocument>;
  templates: Array<Maybe<TemplateDocument>>;
};

export enum FranchiseQualifyingStatement {
  AdiHeld = "AdiHeld",
  Convictions = "Convictions",
}

export enum FranchiseType {
  Solo = "Solo",
  PartnerLite = "PartnerLite",
  Full = "Full",
  Dit = "DIT",
  ThirdParty = "ThirdParty",
  Trainee = "Trainee",
  Motability = "Motability",
}

export enum Frequency {
  OneTime = "OneTime",
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export enum FuelType {
  Petrol = "Petrol",
  Diesel = "Diesel",
  Electric = "Electric",
  Hybrid = "Hybrid",
}

export type FundingSource = {
  __typename?: "FundingSource";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type FundingSourcesInput = {
  lessonType: LearnerLessonType;
  brand: Brand;
};

export type FutureLessonsInput = {
  id: Scalars["ID"];
};

export type FutureTransaction = {
  __typename?: "FutureTransaction";
  id: Scalars["ID"];
  lineItemBillingName: Scalars["String"];
  totalOccurrences: Scalars["Int"];
  pastOccurrences: Scalars["Int"];
  pastAmount: Scalars["Float"];
  remainingOccurrences: Scalars["Int"];
  futureAmountToBeBilled: Scalars["Float"];
  totalAmount: Scalars["Float"];
  frequency: Scalars["String"];
};

export type GenericDocument = {
  __typename?: "GenericDocument";
  documentKey: Scalars["String"];
  documentRevisionKey: Scalars["String"];
};

export type GetDirectDebitInstalmentsInput = {
  courseType: CourseType;
  paymentPlanMonths: Scalars["Int"];
};

export type GetPaymentDataInput = {
  customerKey: Scalars["ID"];
  total: Scalars["Float"];
  orderId: Scalars["Int"];
  brand: Brand;
  orderKey: Scalars["ID"];
};

export type GetVehicleDetailsInput = {
  vehicleKey: Scalars["ID"];
};

export type GiftPersonInput = {
  email: Scalars["String"];
  mobileNumber: Scalars["String"];
};

export type HistoricalFranchise = {
  __typename?: "HistoricalFranchise";
  lineItemKey: Scalars["ID"];
  description?: Maybe<Scalars["String"]>;
  weeklyFee?: Maybe<Scalars["Float"]>;
  dateString?: Maybe<Scalars["String"]>;
};

export type InProductionFilter = {
  make?: Maybe<Scalars["String"]>;
  transmission?: Maybe<TransmissionTypeFilter>;
  status?: Maybe<InProductionStatusFilter>;
  pool?: Maybe<VehiclePoolFilter>;
};

export enum InProductionStatusFilter {
  All = "All",
  AwaitingProduction = "AwaitingProduction",
  InProduction = "InProduction",
  InTransit = "InTransit",
  InUk = "InUK",
  InRepair = "InRepair",
}

export type InflightOrderPayment = {
  __typename?: "InflightOrderPayment";
  order: Order;
  token: Scalars["String"];
  paymentEndpoint: Scalars["String"];
};

export enum InitialContactMethod {
  PrimaryMobile = "PrimaryMobile",
  Email = "Email",
}

export type Instructor = Person & {
  __typename?: "Instructor";
  id: Scalars["ID"];
  personId: Scalars["ID"];
  created: Scalars["GraphQLDate"];
  name: Name;
  status: PersonStatus;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  customerTypes: Array<CustomerType>;
  learners: Array<Maybe<Learner>>;
  pendingLearners: Array<Maybe<Learner>>;
  lessonTypes: Array<LessonTypePreference>;
  learnerCapacity: Array<LearnerCapacity>;
  workingHours: Array<WorkingHour>;
  testCentres?: Maybe<Array<TestCentre>>;
  messages?: Maybe<Array<Message>>;
  posts: Array<Maybe<Post>>;
  propositions?: Maybe<Array<PropositionAllocation>>;
  vehicleAssignments?: Maybe<Array<VehicleAssignment>>;
  vehiclePersonHistories?: Maybe<Array<VehiclePersonHistory>>;
  languages: Array<SpokenLanguage>;
  certifications?: Maybe<Array<Certification>>;
  additionalDrivers?: Maybe<Array<AdditionalDriver>>;
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  mobileNumber?: Maybe<Scalars["PhoneNumber"]>;
  landlineNumber?: Maybe<Scalars["PhoneNumber"]>;
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
  address?: Maybe<Array<Address>>;
  travelTime?: Maybe<Array<TravelTime>>;
  invoices: Array<Invoice>;
  bankDetails?: Maybe<BankDetails>;
  prices: Array<InstructorPrice>;
  brands: Array<Brand>;
  ledgerItems: Array<LedgerItem>;
  pastLedgerItemsForLineItem: Array<LedgerItem>;
  pendingLedgerItems: Array<PendingLedgerItem>;
  futureTransactions: Array<FutureTransaction>;
  coveredRegion?: Maybe<CoveredRegion>;
  availability: Array<Availability>;
  packagePrices: Array<PackagePrice>;
  franFreeDaysBalance?: Maybe<Scalars["Int"]>;
  franFreeDaysDayRateExVat?: Maybe<Scalars["Float"]>;
  regionalPrice?: Maybe<InstructorRegionPrice>;
  description?: Maybe<Scalars["String"]>;
  totalOwed: Scalars["Float"];
  activeComplaint?: Maybe<Scalars["Boolean"]>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
  historicalFranchises?: Maybe<Array<Maybe<HistoricalFranchise>>>;
  arrears?: Maybe<Array<Arrears>>;
  cognitoSub?: Maybe<Scalars["String"]>;
};

export type InstructorLearnersArgs = {
  input?: Maybe<InstructorLearnersInput>;
};

export type InstructorMessagesArgs = {
  input: MessageInput;
};

export type InstructorPostsArgs = {
  input?: Maybe<PostsInput>;
};

export type InstructorPropositionsArgs = {
  input?: Maybe<PropositionInput>;
};

export type InstructorCalendarEventsArgs = {
  input: CalendarEventInput;
};

export type InstructorPricesArgs = {
  postcode: Scalars["Postcode"];
  propositionAllocationId: Scalars["ID"];
};

export type InstructorLedgerItemsArgs = {
  prev?: Maybe<Scalars["Boolean"]>;
};

export type InstructorPastLedgerItemsForLineItemArgs = {
  lineItemKey?: Maybe<Scalars["ID"]>;
};

export type InstructorPendingLedgerItemsArgs = {
  lineItemKey?: Maybe<Scalars["ID"]>;
};

export type InstructorCoveredRegionArgs = {
  postcode: Scalars["Postcode"];
  brand: Brand;
  transmission: TransmissionType;
};

export type InstructorAvailabilityArgs = {
  input: AvailabilityFilter;
};

export type InstructorPackagePricesArgs = {
  input: PackagePriceInput;
};

export type InstructorRegionalPriceArgs = {
  input?: Maybe<InstructorRegionPriceInput>;
};

export enum InstructorAvailabilityResponse {
  Available = "Available",
  NoCapacity = "NoCapacity",
  PostcodeNotCovered = "PostcodeNotCovered",
  TestCentreNotCovered = "TestCentreNotCovered",
  DateTimeNotAvailable = "DateTimeNotAvailable",
}

export type InstructorBusinessProfile = {
  __typename?: "InstructorBusinessProfile";
  bio?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  additionalLanguages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  questionAnswers?: Maybe<
    Array<Maybe<InstructorBusinessProfileQuestionAnswers>>
  >;
  badgeType?: Maybe<Scalars["String"]>;
};

export type InstructorBusinessProfileQuestionAnswers = {
  __typename?: "InstructorBusinessProfileQuestionAnswers";
  question?: Maybe<Scalars["String"]>;
  answer?: Maybe<Scalars["String"]>;
};

export type InstructorLearnersInput = {
  learnerStatusFilter?: Maybe<LearnerStatusFilter>;
  searchTerm?: Maybe<Scalars["String"]>;
};

export type InstructorPrice = {
  __typename?: "InstructorPrice";
  id: Scalars["ID"];
  productCategory: ProductCategoryType;
  price: Scalars["Float"];
};

export type InstructorRegionPrice = {
  __typename?: "InstructorRegionPrice";
  price: Scalars["Float"];
  shortNoticePrice?: Maybe<Scalars["Float"]>;
  isInstructorRegionalPrice?: Maybe<Scalars["Boolean"]>;
  discounts: Array<Discount>;
};

export type InstructorRegionPriceInput = {
  lessonType: LearnerLessonType;
  postcode: Scalars["String"];
  brand: Brand;
  transmission: TransmissionType;
  learnerKey?: Maybe<Scalars["ID"]>;
  isShortNoticeTest?: Maybe<Scalars["Boolean"]>;
};

export type Insurance = {
  __typename?: "Insurance";
  id: Scalars["ID"];
  personKey?: Maybe<Scalars["ID"]>;
  vehicleKey?: Maybe<Scalars["ID"]>;
  certificateNumber?: Maybe<Scalars["String"]>;
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
};

export type Invoice = {
  __typename?: "Invoice";
  id: Scalars["ID"];
  invoiceId: Scalars["Int"];
  invoiceDate: Scalars["GraphQLDate"];
  pdfCreated: Scalars["Boolean"];
  value: Scalars["Float"];
  valueExVat: Scalars["Float"];
  paid: Scalars["Boolean"];
  paymentStatus?: Maybe<Scalars["String"]>;
};

export type InvoiceUrl = {
  __typename?: "InvoiceUrl";
  id: Scalars["ID"];
  url?: Maybe<Scalars["String"]>;
};

export enum Journey {
  Nuj = "NUJ",
  Ruj = "RUJ",
  Oij = "OIJ",
}

export type KeyWorkerInfo = {
  type: KeyWorkerType;
  employer: Scalars["String"];
};

export enum KeyWorkerType {
  HealthSocialCare = "HealthSocialCare",
  EducationChildcare = "EducationChildcare",
  KeyPublicServices = "KeyPublicServices",
  LocalNationalGovernment = "LocalNationalGovernment",
  FoodNecessaryGoods = "FoodNecessaryGoods",
  SafetySecurity = "SafetySecurity",
  Transport = "Transport",
  UtilitiesCommunicationFinancial = "UtilitiesCommunicationFinancial",
}

export type Lead = Person & {
  __typename?: "Lead";
  id: Scalars["ID"];
  personId: Scalars["ID"];
  name: Name;
  leadAddress?: Maybe<Address>;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  licenceNumber?: Maybe<Scalars["String"]>;
  communication?: Maybe<CommunicationConsent>;
  mobileNumber?: Maybe<Scalars["PhoneNumber"]>;
  landlineNumber?: Maybe<Scalars["PhoneNumber"]>;
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
  status: PersonStatus;
  recommended?: Maybe<Scalars["String"]>;
  created: Scalars["GraphQLDate"];
  channel: TicketSource;
  tickets?: Maybe<Array<Ticket>>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
  cognitoSub?: Maybe<Scalars["String"]>;
};

export enum LeadType {
  Dit = "DIT",
  Ig = "IG",
  Franchise = "Franchise",
}

export type Learner = Person & {
  __typename?: "Learner";
  id: Scalars["ID"];
  appointedBy?: Maybe<Scalars["String"]>;
  personId: Scalars["ID"];
  created: Scalars["GraphQLDate"];
  name: Name;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  age?: Maybe<Scalars["Int"]>;
  status: PersonStatus;
  allocations?: Maybe<Array<Allocation>>;
  address?: Maybe<LearnerAddress>;
  pickupAddress?: Maybe<LearnerAddress>;
  mobileNumber?: Maybe<Scalars["PhoneNumber"]>;
  landlineNumber?: Maybe<Scalars["PhoneNumber"]>;
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
  tests: Array<Test>;
  lastLesson?: Maybe<Scalars["GraphQLDate"]>;
  lessons: Array<Lesson>;
  averageHourlyRatePaid?: Maybe<Scalars["Float"]>;
  /** @deprecated Not being resolved correctly for DIT. Not generic. Use `lessonBalances`. */
  lessonBalance?: Maybe<LessonBalance>;
  lessonBalances: Array<LessonBalanceWithCategory>;
  lessonType?: Maybe<LessonType>;
  learnerLessonType?: Maybe<LearnerLessonType>;
  sourcedBy?: Maybe<UserType>;
  customerTypes: Array<CustomerType>;
  transactions: Array<LearnerTransaction>;
  bankDetails?: Maybe<BankDetails>;
  progress?: Maybe<LearnerProgress>;
  isShortNoticeTest?: Maybe<Scalars["Boolean"]>;
  pendingLedgerItems: Array<PendingLedgerItem>;
  transmissionType?: Maybe<TransmissionType>;
  refundableBalance?: Maybe<Array<LearnerContributionAmount>>;
  overallBalance?: Maybe<Array<OverallContributionAmount>>;
  allocationStatus: LearnerAllocationStatus;
  referralId?: Maybe<Scalars["String"]>;
  activeComplaint?: Maybe<Scalars["Boolean"]>;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
  cognitoSub?: Maybe<Scalars["String"]>;
};

export type LearnerAllocationsArgs = {
  input?: Maybe<AllocationFilter>;
};

export type LearnerAllocationStatusArgs = {
  instructorId: Scalars["ID"];
};

export type LearnerAddress = {
  __typename?: "LearnerAddress";
  id: Scalars["ID"];
  line1?: Maybe<Scalars["String"]>;
  line2?: Maybe<Scalars["String"]>;
  town?: Maybe<Scalars["String"]>;
  county?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["Postcode"]>;
  isPickup: Scalars["Boolean"];
  isBilling: Scalars["Boolean"];
};

export enum LearnerAllocationStatus {
  Approved = "Approved",
  Pending = "Pending",
  Rejected = "Rejected",
  Unallocated = "Unallocated",
}

export type LearnerCapacity = {
  __typename?: "LearnerCapacity";
  id: Scalars["ID"];
  maximum: Scalars["Int"];
  current: Scalars["Int"];
  proposition?: Maybe<Proposition>;
  lineItemKey?: Maybe<Scalars["ID"]>;
  locked: Scalars["Boolean"];
};

export type LearnerContributionAmount = {
  __typename?: "LearnerContributionAmount";
  hours: Scalars["Float"];
  learnerContribution: Scalars["Float"];
};

export type LearnerForTopUpDetails = {
  __typename?: "LearnerForTopUpDetails";
  id: Scalars["ID"];
  personId: Scalars["ID"];
  name: NameForTopUpDetails;
  allocations?: Maybe<Array<Allocation>>;
  pickupAddress?: Maybe<AddressForTopUpDetails>;
  referralId?: Maybe<Scalars["String"]>;
  customerTypes?: Maybe<Array<CustomerType>>;
};

export type LearnerForTopUpDetailsAllocationsArgs = {
  input?: Maybe<AllocationFilter>;
};

export type LearnerInput = {
  id: Scalars["ID"];
  created?: Maybe<Scalars["GraphQLDate"]>;
  status?: Maybe<PersonStatus>;
  lessonType?: Maybe<LessonType>;
};

export enum LearnerLessonType {
  Motorway = "Motorway",
  PassPlus = "PassPlus",
  Qualified = "Qualified",
  Standard = "Standard",
  Dit = "DIT",
  Motability = "Motability",
}

export type LearnerProgress = {
  __typename?: "LearnerProgress";
  categories: Array<SkillCategory>;
  overallProgress: Scalars["Float"];
};

export enum LearnerStatusFilter {
  Active = "Active",
  Inactive = "Inactive",
}

export type LearnerTransaction = {
  __typename?: "LearnerTransaction";
  id: Scalars["ID"];
  transactionDate: Scalars["GraphQLDate"];
  orderId: Scalars["Int"];
  transactionValue?: Maybe<Scalars["Float"]>;
  transactionType: LearnerTransactionType;
  itemDescription: Scalars["String"];
  originalBalanceInHours: Scalars["Float"];
  remainingBalanceInPoundsAndPence: Scalars["Float"];
  bookingFee: Scalars["Float"];
  propositionCategory?: Maybe<Scalars["String"]>;
  customDescription?: Maybe<Scalars["String"]>;
  businessFunded?: Maybe<Scalars["Boolean"]>;
  remainingHours: Scalars["Float"];
  excessPayment?: Maybe<Scalars["Boolean"]>;
  orderValue?: Maybe<Scalars["Float"]>;
};

export enum LearnerTransactionType {
  Setup = "Setup",
  Purchase = "Purchase",
  Refund = "Refund",
  Invoice = "Invoice",
  ReallocationTransferOut = "ReallocationTransferOut",
  ReallocationTransferIn = "ReallocationTransferIn",
  Reallocation = "Reallocation",
  AccountCleardown = "AccountCleardown",
}

export type LeaverReasonListItem = {
  __typename?: "LeaverReasonListItem";
  id?: Maybe<Scalars["ID"]>;
  type?: Maybe<PropositionAllocationLeaverReason>;
};

export type LedgerItem = {
  __typename?: "LedgerItem";
  id: Scalars["ID"];
  date: Scalars["GraphQLDate"];
  value: Scalars["Float"];
  valueExVat: Scalars["Float"];
  name: Scalars["String"];
  description: Scalars["String"];
  isCancellable: Scalars["Boolean"];
  paymentStatus: PaymentStatus;
};

export type Lesson = {
  __typename?: "Lesson";
  id: Scalars["ID"];
  created: Scalars["GraphQLDate"];
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  status: LessonStatus;
  learner: Learner;
  instructor: Instructor;
  notes?: Maybe<Scalars["String"]>;
  pickupPostcode?: Maybe<Scalars["String"]>;
  paid: Scalars["Boolean"];
  travelTimeInMinutes: Scalars["Int"];
  newLearner?: Maybe<Scalars["Boolean"]>;
  instructorAllocationKey?: Maybe<Scalars["String"]>;
  cancellationDate?: Maybe<Scalars["GraphQLDate"]>;
  test?: Maybe<Scalars["Boolean"]>;
  franTransmission?: Maybe<Scalars["String"]>;
};

export type LessonBalance = {
  __typename?: "LessonBalance";
  id: Scalars["ID"];
  ditClassroomLessonBalance?: Maybe<Scalars["Float"]>;
  ditInCarLessonBalance?: Maybe<Scalars["Float"]>;
  standardLessonBalance?: Maybe<Scalars["Float"]>;
};

export type LessonBalanceFilter = {
  instructorId?: Maybe<Scalars["ID"]>;
};

export type LessonBalanceWithCategory = {
  __typename?: "LessonBalanceWithCategory";
  id: Scalars["ID"];
  hours: Scalars["Float"];
  category: ProductCategoryType;
};

export enum LessonPurchaseType {
  ThirdParty = "ThirdParty",
  Caller = "Caller",
  Surprise = "Surprise",
}

export enum LessonStatus {
  Active = "Active",
  Pending = "Pending",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export type LessonSummaryInput = {
  startDateTime: Scalars["String"];
  length: Scalars["Int"];
  instructorId: Scalars["ID"];
  transmissionType: TransmissionType;
};

export enum LessonType {
  ShortNoticeTest = "ShortNoticeTest",
  PassPlus = "PassPlus",
  Motability = "Motability",
  Standard = "Standard",
  Dit = "DIT",
}

export type LessonTypePreference = {
  __typename?: "LessonTypePreference";
  propositionAllocationId: Scalars["String"];
  lessonType: LessonType;
  enabled: Scalars["Boolean"];
};

export type LessonTypePreferenceInput = {
  lessonType: LessonType;
  enabled: Scalars["Boolean"];
};

export type Licence = {
  __typename?: "Licence";
  licenceNumber: Scalars["String"];
  expiryDate: Scalars["GraphQLDate"];
};

export enum LiveryEnum {
  Aads = "AADS",
  Bsm = "BSM",
  Solo = "Solo",
}

export type Location = {
  __typename?: "Location";
  id: Scalars["String"];
  description: Scalars["String"];
};

export enum MarketingChannel {
  NudgeBack = "NudgeBack",
  Referral = "Referral",
}

export type MarketingFeed = {
  __typename?: "MarketingFeed";
  aa: Array<MarketingFeedInstructor>;
  bsm: Array<MarketingFeedInstructor>;
};

export type MarketingFeedAaArgs = {
  input: MarketingFeedInput;
};

export type MarketingFeedBsmArgs = {
  input: MarketingFeedInput;
};

export type MarketingFeedCount = {
  __typename?: "MarketingFeedCount";
  aa: Scalars["Int"];
  bsm: Scalars["Int"];
};

export enum MarketingFeedFuelType {
  Petrol = "Petrol",
  Diesel = "Diesel",
  Electric = "Electric",
  Hybrid = "Hybrid",
  Unknown = "Unknown",
}

export type MarketingFeedInput = {
  offset: Scalars["Int"];
  limit: Scalars["Int"];
};

export type MarketingFeedInstructor = {
  __typename?: "MarketingFeedInstructor";
  brand: Brand;
  bio: Scalars["String"];
  dbsChecked: Scalars["Boolean"];
  instructorId: Scalars["Int"];
  personKey: Scalars["String"];
  languagesSpoken: Array<Scalars["String"]>;
  locationsServiced: MarketingFeedLocations;
  minHourlyRate?: Maybe<Scalars["Float"]>;
  monthsProficient?: Maybe<Scalars["Int"]>;
  name: MarketingFeedName;
  pdi: Scalars["Boolean"];
  uniqueUserId: Scalars["String"];
  vehicles: Array<MarketingFeedVehicle>;
};

export type MarketingFeedLocations = {
  __typename?: "MarketingFeedLocations";
  regions: Array<Scalars["String"]>;
  postcodes: Array<Scalars["String"]>;
};

export type MarketingFeedName = {
  __typename?: "MarketingFeedName";
  firstName: Scalars["String"];
  lastName: Scalars["String"];
};

export type MarketingFeedVehicle = {
  __typename?: "MarketingFeedVehicle";
  make: Scalars["String"];
  model: Scalars["String"];
  transmission: Scalars["String"];
  fuel: MarketingFeedFuelType;
};

export type Message = {
  __typename?: "Message";
  id: Scalars["ID"];
  body: Scalars["String"];
  created: Scalars["GraphQLDate"];
  from: Scalars["Int"];
};

export type MessageInput = {
  offset: Scalars["Int"];
  total: Scalars["Int"];
};

export type Mileage = {
  __typename?: "Mileage";
  value: Scalars["Int"];
  captureDate: Scalars["GraphQLDate"];
};

export type MotabilityProduct = {
  __typename?: "MotabilityProduct";
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type MoveToBuildPlanInput = {
  vehicleKeys: Array<Scalars["ID"]>;
};

export type MoveToCommittedInput = {
  vehicleKeys: Array<Scalars["ID"]>;
};

export type MoveToEndOfTermInput = {
  vehicleKey: Scalars["ID"];
};

export type Mutation = {
  __typename?: "Mutation";
  portalAuthLogin: PortalAuthLoginResult;
  portalAuthRequestPassword: PortalAuthRequestPasswordResult;
  portalAuthSetPassword: PortalAuthSetPasswordResult;
  rujLogin: RujLoginResult;
  onboardInstructorLogin: OnboardInstructorLoginResult;
  allocateVehicle: VehiclePersonHistory;
  assignNextTicket: Ticket;
  addToBuildPlan: SimpleMutationResult;
  moveToBuildPlan: SimpleMutationResult;
  moveToCommitted: SimpleMutationResult;
  cancelLesson: Lesson;
  cancelLessonForRUJ: RujCancelledLesson;
  cancelPendingLessons: Array<Lesson>;
  cancelTimeOff: TimeOff;
  cancelPendingAccountAdjustment: LedgerItem;
  changeInstructor: ChangeInstructorPaymentResult;
  changeInstructorPaymentComplete: SimpleMutationResult;
  changeDITInstructor: Learner;
  changeMotabilityToLearner?: Maybe<Learner>;
  closeTicket: DeletedResult;
  completePaymentForDepositAndAdminFee: Scalars["Boolean"];
  completeIgCheckout: Instructor;
  completeDitCheckout: Learner;
  completeOnlineDitCheckout: Scalars["Boolean"];
  createAdditionalDriver: AdditionalDriver;
  createAdhocPaymentOrder: InflightOrderPayment;
  createAreaCoverage: CoveredRegion;
  createCertification: Certification;
  createAccountAdjustment: Instructor;
  createDefermentAccountAdjustment: Instructor;
  createLead: Lead;
  createDitLeadCheckoutOrder: InflightOrderPayment;
  createOnlinePurchaseOrder: OnlinePurchaseOrder;
  createEndCallEvent: EventLog;
  createFranchiseAgreement: Array<FranchiseAgreement>;
  createIgLeadCheckoutOrder: InflightOrderPayment;
  createInstructorRating: CreateInstructorRatingResult;
  createLearner: Learner;
  createEnquiryLead: Scalars["Boolean"];
  createPurchaseLead: CreatePurchaseLeadResult;
  completeFranchisePurchaseLead: CompleteFranchisePurchaseLeadResult;
  createPurchaseBankDetails: Scalars["Boolean"];
  createLearnerWithTopup: CreateLearnerWithTopupResult;
  createLearnerWithTopupForNUJ: CreateLearnerWithTopupForNujResult;
  createLesson: Lesson;
  convertPendingLessons: Array<Lesson>;
  changeLesson: Lesson;
  createMessage: Message;
  createNudgeback: CreateNudgebackResult;
  createPaymentPlanAccountAdjustment: Instructor;
  createSelfSourcedLearner: Learner;
  createSupportMessage: Ticket;
  createTest: Test;
  createTicket: Ticket;
  createUnassignedTicket: Ticket;
  createTimeOff: TimeOff;
  createTopUpOrder: InflightOrderPayment;
  createVehicle: Vehicle;
  createWelfareCategory: Welfare;
  downloadOnFleetingDetails: DownloadFileResult;
  updateWelfare: Welfare;
  deleteLedger: LedgerItem;
  deallocateInstructor: Learner;
  deallocateVehicle: VehiclePersonHistory;
  deleteAreaCoverage: DeletedResult;
  deleteTest: Test;
  editAdditionalDriver: AdditionalDriver;
  editBankDetails: BankDetails;
  endFranchise: PropositionAllocation;
  exportBuildPlan: DownloadFileResult;
  exportTemplateOfExchanges: DownloadFileResult;
  updateDeliveryDate: SimpleMutationResult;
  updateRequiredCompletionDate: SimpleMutationResult;
  removeFromBuildPlan: SimpleMutationResult;
  editPools: SimpleMutationResult;
  flagDeliveryIssue: SimpleMutationResult;
  /** @deprecated Use removeVehicle instead */
  removeVehicleAssignment: VehicleAssignment;
  removeVehicle?: Maybe<VehiclePersonHistory>;
  requestNewInstructor: RequestNewInstructorResult;
  requestNewInstructorWithReason: RequestNewInstructorWithReasonResult;
  requestRefundWithReason: RequestRefundWithReasonResult;
  reassignTicket: Scalars["ID"];
  refundOrder: LearnerTransaction;
  rejectLearner: Learner;
  renewFranchise: Instructor;
  saveWorkItem: WorkItem;
  sendComplaintEmail: Scalars["Boolean"];
  sendMotabilityWelcomeEmail: Scalars["Boolean"];
  sendPupilhelpEmail: Scalars["Boolean"];
  clearDownLearner: Scalars["Boolean"];
  setConsents: CommunicationConsent;
  toggleDirectDebitMandate: BankDetails;
  undoEndFranchise: PropositionAllocation;
  updateFranchiseAgreementDocument?: Maybe<Array<SignedUrl>>;
  signFranchiseAgreementDocument: Scalars["String"];
  signFranchiseAgreementDocumentFromSalesJourney: Scalars["String"];
  updateAllocation: Allocation;
  updateAreaCoverage: CoveredRegion;
  updateCertification: Certification;
  updateLead: Lead;
  updateInstructor: Instructor;
  updateInstructorPreferences: Instructor;
  updateInsurance?: Maybe<Insurance>;
  updateInsuranceHistory?: Maybe<Insurance>;
  updateInvoiceStatus: Invoice;
  updateLearner: Learner;
  updateLearnerProgress: LearnerProgress;
  updateLesson: Lesson;
  updateMileage: Mileage;
  updateMotabilityAllocation: UpdatedMotabilityAllocation;
  updateTest: Test;
  updateTimeOff: TimeOff;
  updateFranchiseStartDate: PropositionAllocation;
  updateFranchiseRenewalDate: PropositionAllocation;
  updateFranchiseRegulationStatus: PropositionAllocation;
  removePendingFranchise: Scalars["Boolean"];
  searchAgreementKey: Scalars["String"];
  uploadNewDocument: DocumentVersion;
  uploadDocumentVersion: DocumentVersion;
  reviewAgreementDocument: Scalars["String"];
  deleteAgreementDocument: Scalars["String"];
  updateAgreementDocument: Scalars["String"];
  fileUpload: Scalars["Boolean"];
  deleteWelfare: Welfare;
  deleteWelfareOutcome: WelfareOutcome;
  addBankDetails?: Maybe<Scalars["Boolean"]>;
  addNewDocument: Scalars["Boolean"];
  sendRemainder: Scalars["Boolean"];
  submitQualifyingStatements: Scalars["Boolean"];
  reserveVehicle: ReserveVehicleResult;
  unReserveVehicle?: Maybe<ReserveVehicleResult>;
  setVehicleStatusToEndOfTerm: SimpleMutationResult;
  updateVehicleAvailable?: Maybe<Scalars["GraphQLDate"]>;
  updateVehicleEndOfTermStatus: SimpleMutationResult;
  updateAgreementStatus?: Maybe<Scalars["Boolean"]>;
  saveInstructorBusinessProfile: Scalars["Boolean"];
  saveInstructorAwards: Scalars["Boolean"];
};

export type MutationPortalAuthLoginArgs = {
  input: PortalAuthLoginInput;
};

export type MutationPortalAuthRequestPasswordArgs = {
  input: PortalAuthRequestPasswordInput;
};

export type MutationPortalAuthSetPasswordArgs = {
  input: PortalAuthSetPasswordInput;
};

export type MutationRujLoginArgs = {
  input: RujLoginInput;
};

export type MutationOnboardInstructorLoginArgs = {
  input: OnboardInstructorLoginInput;
};

export type MutationAllocateVehicleArgs = {
  input: AllocateVehicleInput;
};

export type MutationAssignNextTicketArgs = {
  input?: Maybe<AssignNextTicketInput>;
};

export type MutationAddToBuildPlanArgs = {
  input: AddToBuildPlanInput;
};

export type MutationMoveToBuildPlanArgs = {
  input: MoveToBuildPlanInput;
};

export type MutationMoveToCommittedArgs = {
  input: MoveToCommittedInput;
};

export type MutationCancelLessonArgs = {
  input: CancelLessonInput;
};

export type MutationCancelLessonForRujArgs = {
  input: CancelLessonForRujInput;
};

export type MutationCancelPendingLessonsArgs = {
  input: CancelPendingLessonsInput;
};

export type MutationCancelTimeOffArgs = {
  input: CancelTimeOffInput;
};

export type MutationCancelPendingAccountAdjustmentArgs = {
  input?: Maybe<CancelPendingAccountAdjustmentInput>;
};

export type MutationChangeInstructorArgs = {
  input?: Maybe<ChangeInstructorInput>;
};

export type MutationChangeInstructorPaymentCompleteArgs = {
  input?: Maybe<ChangeInstructorPaymentCompleteInput>;
};

export type MutationChangeDitInstructorArgs = {
  input?: Maybe<ChangeDitInstructorInput>;
};

export type MutationChangeMotabilityToLearnerArgs = {
  input?: Maybe<ChangeMotabilityToLearnerInput>;
};

export type MutationCloseTicketArgs = {
  id: Scalars["ID"];
};

export type MutationCompletePaymentForDepositAndAdminFeeArgs = {
  input: CompletePaymentForDepositAndAdminFeeInput;
};

export type MutationCompleteIgCheckoutArgs = {
  input: CompleteIgCheckoutInput;
};

export type MutationCompleteDitCheckoutArgs = {
  input: CompleteDitCheckoutInput;
};

export type MutationCompleteOnlineDitCheckoutArgs = {
  input: CompleteOnlineDitCheckoutInput;
};

export type MutationCreateAdditionalDriverArgs = {
  input?: Maybe<CreateAdditionalDriverInput>;
};

export type MutationCreateAdhocPaymentOrderArgs = {
  input?: Maybe<CreateAdhocPaymentOrderInput>;
};

export type MutationCreateAreaCoverageArgs = {
  input?: Maybe<CreateAreaCoverageInput>;
};

export type MutationCreateCertificationArgs = {
  input?: Maybe<CreateCertificationInput>;
};

export type MutationCreateAccountAdjustmentArgs = {
  input?: Maybe<CreateAccountAdjustmentInput>;
};

export type MutationCreateDefermentAccountAdjustmentArgs = {
  input?: Maybe<CreateDefermentAccountAdjustmentInput>;
};

export type MutationCreateLeadArgs = {
  input: CreateLeadInput;
};

export type MutationCreateDitLeadCheckoutOrderArgs = {
  input: CreateDitLeadCheckoutOrderInput;
};

export type MutationCreateOnlinePurchaseOrderArgs = {
  input: CreateOnlinePurchaseOrderInput;
};

export type MutationCreateEndCallEventArgs = {
  input?: Maybe<CreateEndCallEventInput>;
};

export type MutationCreateFranchiseAgreementArgs = {
  input: CreateFranchiseAgreementInput;
};

export type MutationCreateIgLeadCheckoutOrderArgs = {
  input: CreateIgLeadCheckoutOrderInput;
};

export type MutationCreateInstructorRatingArgs = {
  input: CreateInstructorRatingInput;
};

export type MutationCreateLearnerArgs = {
  input: CreateLearnerInput;
};

export type MutationCreateEnquiryLeadArgs = {
  input: CreateEnquiryLeadInput;
};

export type MutationCreatePurchaseLeadArgs = {
  input: CreatePurchaseLeadInput;
};

export type MutationCompleteFranchisePurchaseLeadArgs = {
  input: CompleteFranchisePurchaseLeadInput;
};

export type MutationCreatePurchaseBankDetailsArgs = {
  input: CreatePurchaseBankDetailsInput;
};

export type MutationCreateLearnerWithTopupArgs = {
  input?: Maybe<CreateLearnerWithTopupInput>;
};

export type MutationCreateLearnerWithTopupForNujArgs = {
  input?: Maybe<CreateLearnerWithTopupForNujInput>;
};

export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};

export type MutationConvertPendingLessonsArgs = {
  input: ConvertPendingLessonsInput;
};

export type MutationChangeLessonArgs = {
  input: ChangeLessonInput;
};

export type MutationCreateMessageArgs = {
  input?: Maybe<CreateMessageMutationInput>;
};

export type MutationCreateNudgebackArgs = {
  input: CreateNudgebackInput;
};

export type MutationCreatePaymentPlanAccountAdjustmentArgs = {
  input?: Maybe<CreatePaymentPlanAccountAdjustmentInput>;
};

export type MutationCreateSelfSourcedLearnerArgs = {
  input: CreateSelfSourcedLearnerInput;
};

export type MutationCreateSupportMessageArgs = {
  input: CreateSupportMessageInput;
};

export type MutationCreateTestArgs = {
  input?: Maybe<CreateTestMutationInput>;
};

export type MutationCreateTicketArgs = {
  input?: Maybe<CreateTicketInput>;
};

export type MutationCreateUnassignedTicketArgs = {
  input?: Maybe<CreateUnassignedTicketInput>;
};

export type MutationCreateTimeOffArgs = {
  input: CreateTimeOffInput;
};

export type MutationCreateTopUpOrderArgs = {
  input: CreateTopUpOrderInput;
};

export type MutationCreateVehicleArgs = {
  input?: Maybe<CreateVehicleInput>;
};

export type MutationCreateWelfareCategoryArgs = {
  input: CreateWelfareCategoryInput;
};

export type MutationDownloadOnFleetingDetailsArgs = {
  vehicleKeys: Array<Scalars["ID"]>;
};

export type MutationUpdateWelfareArgs = {
  input: UpdateWelfareInput;
};

export type MutationDeleteLedgerArgs = {
  input?: Maybe<DeleteLedgerMutationInput>;
};

export type MutationDeallocateInstructorArgs = {
  input: DeallocateInstructorInput;
};

export type MutationDeallocateVehicleArgs = {
  input: DeallocateVehicleInput;
};

export type MutationDeleteAreaCoverageArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteTestArgs = {
  id: Scalars["ID"];
};

export type MutationEditAdditionalDriverArgs = {
  input?: Maybe<EditAdditionalDriverInput>;
};

export type MutationEditBankDetailsArgs = {
  input?: Maybe<EditBankDetailsInput>;
};

export type MutationEndFranchiseArgs = {
  input?: Maybe<EndFranchiseInput>;
};

export type MutationExportBuildPlanArgs = {
  input: ExportBuildPlanInput;
};

export type MutationExportTemplateOfExchangesArgs = {
  input: ExportTemplateOfExchangesInput;
};

export type MutationUpdateDeliveryDateArgs = {
  input: UpdateDeliveryDateInput;
};

export type MutationUpdateRequiredCompletionDateArgs = {
  input: UpdateRequiredCompletionDateInput;
};

export type MutationRemoveFromBuildPlanArgs = {
  input: RemoveFromBuildPlanInput;
};

export type MutationEditPoolsArgs = {
  input?: Maybe<EditPoolsInput>;
};

export type MutationFlagDeliveryIssueArgs = {
  input: FlagDeliveryIssueInput;
};

export type MutationRemoveVehicleAssignmentArgs = {
  input: RemoveVehicleAssignmentInput;
};

export type MutationRemoveVehicleArgs = {
  input: RemoveVehicleInput;
};

export type MutationRequestNewInstructorArgs = {
  input: RequestNewInstructorInput;
};

export type MutationRequestNewInstructorWithReasonArgs = {
  input: RequestNewInstructorWithReasonInput;
};

export type MutationRequestRefundWithReasonArgs = {
  input: RequestRefundWithReasonInput;
};

export type MutationReassignTicketArgs = {
  input?: Maybe<ReassignTicketInput>;
};

export type MutationRefundOrderArgs = {
  input?: Maybe<RefundOrderInput>;
};

export type MutationRejectLearnerArgs = {
  input: RejectLearnerInput;
};

export type MutationRenewFranchiseArgs = {
  input: RenewFranchiseInput;
};

export type MutationSaveWorkItemArgs = {
  input?: Maybe<WorkItemInput>;
};

export type MutationSendComplaintEmailArgs = {
  input: SendComplaintEmailInput;
};

export type MutationSendMotabilityWelcomeEmailArgs = {
  input: SendMotabilityWelcomeEmailInput;
};

export type MutationSendPupilhelpEmailArgs = {
  input: SendPupilhelpEmailInput;
};

export type MutationClearDownLearnerArgs = {
  input: ClearDownLearnerInput;
};

export type MutationSetConsentsArgs = {
  input?: Maybe<CommunicationConsentInput>;
};

export type MutationToggleDirectDebitMandateArgs = {
  input: ToggleDirectDebitMandateInput;
};

export type MutationUndoEndFranchiseArgs = {
  input?: Maybe<UndoEndFranchiseInput>;
};

export type MutationUpdateFranchiseAgreementDocumentArgs = {
  agreementKey?: Maybe<Scalars["String"]>;
};

export type MutationSignFranchiseAgreementDocumentArgs = {
  agreementKey?: Maybe<Scalars["String"]>;
};

export type MutationSignFranchiseAgreementDocumentFromSalesJourneyArgs = {
  input: SignFranchiseAgreementDocumentFromSalesJourneyInput;
};

export type MutationUpdateAllocationArgs = {
  input?: Maybe<UpdateAllocationInput>;
};

export type MutationUpdateAreaCoverageArgs = {
  input?: Maybe<UpdateAreaCoverageInput>;
};

export type MutationUpdateCertificationArgs = {
  input: UpdateCertificationInput;
};

export type MutationUpdateLeadArgs = {
  input: UpdateLeadInput;
};

export type MutationUpdateInstructorArgs = {
  input: UpdateInstructorInput;
};

export type MutationUpdateInstructorPreferencesArgs = {
  input: UpdateInstructorPreferencesInput;
};

export type MutationUpdateInsuranceArgs = {
  input: UpdateInsuranceInput;
};

export type MutationUpdateInsuranceHistoryArgs = {
  input: UpdateInsuranceHistoryInput;
};

export type MutationUpdateInvoiceStatusArgs = {
  input: UpdateInvoiceStatusInput;
};

export type MutationUpdateLearnerArgs = {
  input: UpdateLearnerInput;
};

export type MutationUpdateLearnerProgressArgs = {
  input: UpdateLearnerProgressInput;
};

export type MutationUpdateLessonArgs = {
  input: UpdateLessonInput;
};

export type MutationUpdateMileageArgs = {
  input: UpdateMileageInput;
};

export type MutationUpdateMotabilityAllocationArgs = {
  input: UpdateMotabilityAllocationInput;
};

export type MutationUpdateTestArgs = {
  input?: Maybe<EditTestMutationInput>;
};

export type MutationUpdateTimeOffArgs = {
  input: UpdateTimeOffInput;
};

export type MutationUpdateFranchiseStartDateArgs = {
  input: UpdateFranchiseDateInput;
};

export type MutationUpdateFranchiseRenewalDateArgs = {
  input: UpdateFranchiseDateInput;
};

export type MutationUpdateFranchiseRegulationStatusArgs = {
  input: UpdateFranchiseRegulationStatusInput;
};

export type MutationRemovePendingFranchiseArgs = {
  input: RemovePendingFranchiseInput;
};

export type MutationSearchAgreementKeyArgs = {
  personKey: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  email: Scalars["String"];
  postcode: Scalars["String"];
};

export type MutationUploadNewDocumentArgs = {
  documentName: Scalars["String"];
  brand: Scalars["String"];
  documentType: Scalars["String"];
  personKey: Scalars["String"];
  buffer: Scalars["Upload"];
};

export type MutationUploadDocumentVersionArgs = {
  documentKey: Scalars["String"];
  personKey: Scalars["String"];
  buffer: Scalars["Upload"];
};

export type MutationReviewAgreementDocumentArgs = {
  input: ReviewAgreementDocumentInput;
};

export type MutationDeleteAgreementDocumentArgs = {
  franAgreeDocKey: Scalars["String"];
};

export type MutationUpdateAgreementDocumentArgs = {
  personKey: Scalars["String"];
  file: Scalars["Upload"];
  franAgreeDocKey: Scalars["String"];
  documentName: Scalars["String"];
  documentDescription?: Maybe<Scalars["String"]>;
  expiryDate?: Maybe<Scalars["GraphQLDate"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MutationFileUploadArgs = {
  name: Scalars["String"];
  file?: Maybe<Scalars["Upload"]>;
};

export type MutationDeleteWelfareArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteWelfareOutcomeArgs = {
  id: Scalars["ID"];
};

export type MutationAddBankDetailsArgs = {
  personKey: Scalars["String"];
  accountNumber: Scalars["String"];
  sortCode: Scalars["String"];
  nameOnAccount: Scalars["String"];
};

export type MutationAddNewDocumentArgs = {
  documentName: Scalars["String"];
  file: Scalars["Upload"];
  instructorKey: Scalars["String"];
  expiryDate?: Maybe<Scalars["GraphQLDate"]>;
  documentDescription?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type MutationSendRemainderArgs = {
  franAgreeDoc: Scalars["String"];
};

export type MutationSubmitQualifyingStatementsArgs = {
  input: PurchaseLeadQualifyingStatementsInput;
};

export type MutationReserveVehicleArgs = {
  input: ReserveVehicleInput;
};

export type MutationUnReserveVehicleArgs = {
  input: UnReserveVehicleInput;
};

export type MutationSetVehicleStatusToEndOfTermArgs = {
  input: MoveToEndOfTermInput;
};

export type MutationUpdateVehicleAvailableArgs = {
  input: UpdateVehicleAvailableInput;
};

export type MutationUpdateVehicleEndOfTermStatusArgs = {
  input: UpdateVehicleEndOfTermStatusInput;
};

export type MutationUpdateAgreementStatusArgs = {
  franchiseAgreementKey: Scalars["String"];
  status: Scalars["String"];
};

export type MutationSaveInstructorBusinessProfileArgs = {
  input: SaveInstructorBusinessProfileInput;
};

export type MutationSaveInstructorAwardsArgs = {
  input: SaveInstructorAwardsInput;
};

export type NujLearnerInput = {
  title: Salutation;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  mobileNumber: Scalars["String"];
  email: Scalars["String"];
  pickupAddress: AddressInput;
  billingAddress?: Maybe<AddressInput>;
  giftGiver?: Maybe<GiftPersonInput>;
};

export type Name = {
  __typename?: "Name";
  title?: Maybe<Salutation>;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  fullName: Scalars["String"];
};

export type NameForTopUpDetails = {
  __typename?: "NameForTopUpDetails";
  firstName: Scalars["String"];
};

export type NewDay = {
  day: Scalars["Int"];
  startTime?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
};

export enum NudgebackType {
  Web = "Web",
  WebCovid = "WebCovid",
}

export type NujOrder = {
  __typename?: "NujOrder";
  orderId?: Maybe<Scalars["ID"]>;
  bookingId?: Maybe<Scalars["ID"]>;
  orderCostSummary?: Maybe<OrderCostSummary>;
  pupilId?: Maybe<Scalars["ID"]>;
  payment?: Maybe<CreatedOrder>;
};

export type OnboardInstructorLoginInput = {
  personKey: Scalars["String"];
  brand: Brand;
  postcode: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  email: Scalars["String"];
};

export type OnboardInstructorLoginResult = {
  __typename?: "OnboardInstructorLoginResult";
  authenticated: Scalars["Boolean"];
  attemptsRemaining?: Maybe<Scalars["Int"]>;
  lockoutExpiry?: Maybe<Scalars["GraphQLDate"]>;
  accessToken?: Maybe<Scalars["String"]>;
  encryptedPersonId?: Maybe<Scalars["String"]>;
};

export type OnlinePurchaseOrder = {
  __typename?: "OnlinePurchaseOrder";
  orderId: Scalars["ID"];
  token: Scalars["String"];
  paymentEndpoint: Scalars["String"];
};

export type OnlineVehiclesInformation = {
  __typename?: "OnlineVehiclesInformation";
  id: Scalars["ID"];
  colours: Array<Scalars["String"]>;
  weeklyFee: Scalars["Float"];
  termDays: Scalars["Int"];
  adminFee: Scalars["Float"];
  depositFee: Scalars["Float"];
  propositionKey: Scalars["ID"];
};

export type OnlineVehiclesInput = {
  franchiseType: FranchiseType;
  contractTerm: Scalars["Int"];
  transmissionType: TransmissionType;
};

export type Order = {
  __typename?: "Order";
  id: Scalars["ID"];
  amount?: Maybe<Scalars["Float"]>;
  status: OrderStatus;
  customer?: Maybe<Customer>;
  topUpId?: Maybe<Scalars["String"]>;
  discount?: Maybe<Scalars["Float"]>;
};

export type OrderCostSummary = {
  __typename?: "OrderCostSummary";
  hours: Scalars["Int"];
  packagePrice: Scalars["Float"];
  bookingFee?: Maybe<Scalars["Float"]>;
  discount: Scalars["Float"];
  total: Scalars["Float"];
};

export type OrderDetail = {
  __typename?: "OrderDetail";
  orderDetailsKey?: Maybe<Scalars["ID"]>;
  orderKey?: Maybe<Scalars["ID"]>;
  totalValue?: Maybe<Scalars["Float"]>;
  vat?: Maybe<Scalars["Float"]>;
  refundValue?: Maybe<Scalars["Float"]>;
  product?: Maybe<OrderDetailProduct>;
};

export type OrderDetailProduct = {
  __typename?: "OrderDetailProduct";
  productName?: Maybe<Scalars["String"]>;
  productDescription?: Maybe<Scalars["String"]>;
};

export type OrderPayment = {
  __typename?: "OrderPayment";
  id: Scalars["ID"];
  orderValue: Scalars["Float"];
  bookingFeeValue: Scalars["Float"];
  cardEnding: Scalars["String"];
  remainingBalance: Scalars["Float"];
  excessPayments?: Maybe<Array<ExcessOrderPayment>>;
};

export enum OrderStatus {
  Paid = "Paid",
  Pending = "Pending",
  Failed = "Failed",
}

export type OverallContributionAmount = {
  __typename?: "OverallContributionAmount";
  hours: Scalars["Float"];
  overallContribution: Scalars["Float"];
};

export type OwnershipHistory = {
  __typename?: "OwnershipHistory";
  id: Scalars["ID"];
  personName: Scalars["String"];
  personKey: Scalars["ID"];
  fromDate: Scalars["GraphQLDate"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  active: Scalars["Boolean"];
  type?: Maybe<Scalars["String"]>;
};

export type PackagePrice = {
  __typename?: "PackagePrice";
  id: Scalars["ID"];
  discount?: Maybe<Discount>;
  packagePrice: Scalars["Float"];
  discountAmount?: Maybe<Scalars["Float"]>;
  packageLengthInHours: Scalars["Int"];
};

export type PackagePriceInput = {
  postcode: Scalars["Postcode"];
  brand: Brand;
  transmission: TransmissionType;
  packageLengthsInHours: Array<Scalars["Int"]>;
  learnerLessonType: LearnerLessonType;
  isShortNoticeTest: Scalars["Boolean"];
};

export type PaymentData = {
  __typename?: "PaymentData";
  token: Scalars["String"];
  endpoint: Scalars["String"];
  orderId: Scalars["Int"];
  orderKey: Scalars["ID"];
};

export type PaymentReallocationOrder = {
  pendingOrderKey: Scalars["ID"];
  pendingOrderHours: Scalars["Int"];
  oldOrderKey: Scalars["ID"];
  oldOrderHours: Scalars["Int"];
};

export enum PaymentStatus {
  Pending = "Pending",
  Paid = "Paid",
  Unpaid = "Unpaid",
}

export type PendingLedgerItem = {
  __typename?: "PendingLedgerItem";
  id: Scalars["ID"];
  date: Scalars["GraphQLDate"];
  frequency?: Maybe<Frequency>;
  description: Scalars["String"];
  name: Scalars["String"];
  amountExcVAT: Scalars["Float"];
  amountIncVAT: Scalars["Float"];
  isCancellable: Scalars["Boolean"];
  endDate?: Maybe<Scalars["GraphQLDate"]>;
  totalInSeries: Scalars["Int"];
  productCategory?: Maybe<ProductCategoryType>;
  paymentStatus: PaymentStatus;
};

export type Permissions = {
  __typename?: "Permissions";
  personKey: Scalars["String"];
  readable: Scalars["Boolean"];
  writable: Scalars["Boolean"];
};

export type Person = {
  id: Scalars["ID"];
  personId: Scalars["ID"];
  created: Scalars["GraphQLDate"];
  name: Name;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  mobileNumber?: Maybe<Scalars["PhoneNumber"]>;
  landlineNumber?: Maybe<Scalars["PhoneNumber"]>;
  emailAddress?: Maybe<Scalars["EmailAddress"]>;
  status: PersonStatus;
  welfare?: Maybe<Array<Maybe<Welfare>>>;
  cognitoSub?: Maybe<Scalars["String"]>;
};

export enum PersonStatus {
  Active = "Active",
  Inactive = "Inactive",
  Deleted = "Deleted",
  Lead = "Lead",
  Pending = "Pending",
}

export enum PersonType {
  Dit = "DIT",
  Instructor = "Instructor",
  Learner = "Learner",
  Motability = "Motability",
  TestCustomer = "TestCustomer",
}

export type PoolHistory = {
  __typename?: "PoolHistory";
  poolHistoryKey: Scalars["ID"];
  vehicleKey: Scalars["ID"];
  poolId: Scalars["Int"];
  poolItemDescription?: Maybe<Scalars["String"]>;
  fromDate: Scalars["GraphQLDate"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  active: Scalars["Boolean"];
};

export type PortalAuthLoginInput = {
  email: Scalars["String"];
  password: Scalars["String"];
  personType: PersonType;
};

export type PortalAuthLoginResult = {
  __typename?: "PortalAuthLoginResult";
  authenticated: Scalars["Boolean"];
  message: Scalars["String"];
  accessToken?: Maybe<Scalars["String"]>;
};

export type PortalAuthRequestPasswordInput = {
  email: Scalars["String"];
  personType: PersonType;
};

export type PortalAuthRequestPasswordResult = {
  __typename?: "PortalAuthRequestPasswordResult";
  success: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
};

export type PortalAuthSetPasswordInput = {
  email: Scalars["String"];
  personType: PersonType;
  code: Scalars["String"];
  password: Scalars["String"];
};

export type PortalAuthSetPasswordResult = {
  __typename?: "PortalAuthSetPasswordResult";
  success: Scalars["Boolean"];
  errors?: Maybe<Array<FormError>>;
};

export type Post = {
  __typename?: "Post";
  id: Scalars["ID"];
  type: PostType;
  date: Scalars["GraphQLDate"];
  status: PostStatus;
  brand: Brand;
  content: Scalars["String"];
};

export enum PostStatus {
  Draft = "Draft",
  Live = "Live",
  Removed = "Removed",
}

export enum PostType {
  News = "News",
  Tech = "Tech",
  File = "File",
}

export type PostsInput = {
  status?: Maybe<PostStatus>;
  type?: Maybe<Array<Maybe<PostType>>>;
  limit?: Maybe<Scalars["Int"]>;
};

export enum PriceType {
  Instructor = "Instructor",
  Region = "Region",
  Bespoke = "Bespoke",
}

export type PricingUpdateResult = {
  __typename?: "PricingUpdateResult";
  id: Scalars["ID"];
  manualPrice?: Maybe<Scalars["String"]>;
  automaticPrice?: Maybe<Scalars["String"]>;
  shortNoticeTestPrice?: Maybe<Scalars["String"]>;
};

export type Product = {
  __typename?: "Product";
  id: Scalars["ID"];
  name: Scalars["String"];
  defaultPriceExVat?: Maybe<Scalars["Float"]>;
  adjustablePrice?: Maybe<Scalars["Boolean"]>;
  vat?: Maybe<Vat>;
};

export type ProductCategory = {
  __typename?: "ProductCategory";
  id: Scalars["ID"];
  type: ProductCategoryType;
};

export enum ProductCategoryType {
  Charge = "Charge",
  Deferral = "Deferral",
  Discount = "Discount",
  Instructor = "Instructor",
  Motability2015 = "Motability2015",
  Motability2018 = "Motability2018",
  Motability2022 = "Motability2022",
  Motability2023 = "Motability2023",
  Motability2024 = "Motability2024",
  Payment = "Payment",
  PaymentPlan = "PaymentPlan",
  PayPlan = "PayPlan",
  PostTest = "PostTest",
  PreTest = "PreTest",
  DitLearnerResource = "DITLearnerResource",
  DitClassroom = "DITClassroom",
  DitCar = "DITCar",
  Dit = "DIT",
  Motability = "Motability",
  PropositionSurcharge = "PropositionSurcharge",
  CreditNote = "CreditNote",
  LearnerRetail = "LearnerRetail",
  Deferment = "Deferment",
  Surcharge = "Surcharge",
  InstantRevenueRecognition = "InstantRevenueRecognition",
  InstructorRetail = "InstructorRetail",
}

export type Proposition = {
  __typename?: "Proposition";
  id: Scalars["ID"];
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  brand: Brand;
  transmission?: Maybe<TransmissionType>;
  price: PropositionPrice;
  type?: Maybe<FranchiseType>;
  productCategories?: Maybe<Array<ProductCategory>>;
  adminFee?: Maybe<PropositionAdminFee>;
  depositFee?: Maybe<Scalars["Float"]>;
  fundingSources?: Maybe<Array<FundingSource>>;
  term?: Maybe<Scalars["Int"]>;
  fleetSpecificationBand?: Maybe<Scalars["String"]>;
};

export type PropositionAdminFee = {
  __typename?: "PropositionAdminFee";
  includingVAT: Scalars["Float"];
  excludingVAT: Scalars["Float"];
  vat?: Maybe<Scalars["Float"]>;
};

export type PropositionAllocation = {
  __typename?: "PropositionAllocation";
  id: Scalars["ID"];
  fromDate: Scalars["GraphQLDate"];
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  estimatedRenewalDate?: Maybe<Scalars["GraphQLDate"]>;
  coverage: Array<Maybe<CoveredRegion>>;
  proposition: Proposition;
  leavingReason?: Maybe<LeaverReasonListItem>;
  learnerCaps?: Maybe<Array<LearnerCapacity>>;
  originalEndDate?: Maybe<Scalars["GraphQLDate"]>;
  regulationStatus?: Maybe<FcaAgreementStatus>;
  dateToFeeCharged: Scalars["Boolean"];
  status: PropositionAllocationStatus;
  orderDetails?: Maybe<Array<Maybe<OrderDetail>>>;
  orderId?: Maybe<Scalars["Int"]>;
  orderKey?: Maybe<Scalars["ID"]>;
  nextLineItemKey?: Maybe<Scalars["ID"]>;
  prevLineItemKey?: Maybe<Scalars["ID"]>;
  bespokeWeeklyPaymentIncVAT?: Maybe<Scalars["Float"]>;
  bespokeWeeklyPaymentExcVAT?: Maybe<Scalars["Float"]>;
  agreement?: Maybe<Array<Maybe<FranchiseAgreement>>>;
};

export enum PropositionAllocationLeaverReason {
  Migration = "Migration",
  CscServiceIssue = "CSCServiceIssue",
  PersonalReasons = "PersonalReasons",
  UnhappyWithServiceCsc = "UnhappyWithServiceCSC",
  TerminatedByAads = "TerminatedByAADS",
  FranchiseCostPupilSupply = "FranchiseCostPupilSupply",
  TerminatedByAadsPermanentlyDeclined = "TerminatedByAADSPermanentlyDeclined",
  LackOfWork = "LackOfWork",
  TerminationFailedPart3For3rdTime = "TerminationFailedPart3For3rdTime",
  Retired = "Retired",
  MoveToOtherFranchise = "MoveToOtherFranchise",
  Relocation = "Relocation",
  CrossBrandTransfer = "CrossBrandTransfer",
  TerminatedByBusinessPermanentlyDeclined = "TerminatedByBusinessPermanentlyDeclined",
  CareerChange = "CareerChange",
  LackOfWorkPupils = "LackOfWorkPupils",
  Covid19 = "COVID19",
  MoveToSelfEmployed = "MoveToSelfEmployed",
  MedicalReasons = "MedicalReasons",
  FranchiseCostFranchiseIncrease = "FranchiseCostFranchiseIncrease",
  LackOfWorkAadt = "LackOfWorkAADT",
  TerminationGreenBadge = "TerminationGreenBadge",
  FamilyIssues = "FamilyIssues",
  TerminatedByBusiness = "TerminatedByBusiness",
  TerminationDebt = "TerminationDebt",
  MoveToAnotherFranchise = "MoveToAnotherFranchise",
  FranchiseCostCheaperCompetitor = "FranchiseCostCheaperCompetitor",
  TerminationPinkBadge = "TerminationPinkBadge",
  FranchiseCost2ndYearFranchiseIncrease = "FranchiseCost2ndYearFranchiseIncrease",
  Deceased = "Deceased",
  NotGiven = "NotGiven",
  UnhappyWithServiceFleet = "UnhappyWithServiceFleet",
  TerminationConduct = "TerminationConduct",
  Other = "Other",
  FranchiseCosts = "FranchiseCosts",
  MoveToOtherAAfranchise = "MoveToOtherAAfranchise",
  CancellationNotTakenUpNtu = "CancellationNotTakenUpNTU",
  CancellationEarlyExit = "CancellationEarlyExit",
}

export enum PropositionAllocationStatus {
  Active = "Active",
  Pending = "Pending",
  Deleted = "Deleted",
}

export enum PropositionFeeFrequency {
  Weekly = "Weekly",
}

export type PropositionInput = {
  future?: Maybe<Scalars["Boolean"]>;
};

export type PropositionOrm = {
  __typename?: "PropositionOrm";
  propositionName: Scalars["String"];
  propositionDescription: Scalars["String"];
};

export type PropositionPrice = {
  __typename?: "PropositionPrice";
  excludingVAT: Scalars["Float"];
  includingVAT: Scalars["Float"];
  vat?: Maybe<Scalars["Float"]>;
  vatRate?: Maybe<Scalars["Float"]>;
  frequency: PropositionFeeFrequency;
};

export type PropositionProduct = {
  __typename?: "PropositionProduct";
  propositionId: Scalars["ID"];
  products: Array<Product>;
};

export enum PurchasablePropositionType {
  Dit = "DIT",
  InstructorGrowth = "InstructorGrowth",
}

export type PurchaseLeadAddressInput = {
  postcode: Scalars["Postcode"];
  line1: Scalars["String"];
  line2?: Maybe<Scalars["String"]>;
  town: Scalars["String"];
  county: Scalars["String"];
  country: Scalars["String"];
};

export type PurchaseLeadCourseInput = {
  studyFormat: StudyFormat;
  type: CourseType;
};

export type PurchaseLeadMarketingInput = {
  email: Scalars["Boolean"];
  phone: Scalars["Boolean"];
  post: Scalars["Boolean"];
  text: Scalars["Boolean"];
};

export type PurchaseLeadPersonalInput = {
  title: Salutation;
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  dateOfBirth: Scalars["GraphQLDate"];
  emailAddress: Scalars["String"];
  phoneNumber: Scalars["String"];
  drivingLicenceNumber?: Maybe<Scalars["String"]>;
};

export type PurchaseLeadQualifyingStatementsInput = {
  email: Scalars["String"];
  statements: Array<QualifyingStatement>;
  purchaseType: LeadType;
};

export enum PurchaseSource {
  Helix = "Helix",
  DitOnline = "DitOnline",
}

export enum QualifyingStatement {
  FullLicence = "FullLicence",
  Disqualified = "Disqualified",
  Points = "Points",
  Vision = "Vision",
  Convictions = "Convictions",
}

export type Query = {
  __typename?: "Query";
  searchForAddress: Array<Location>;
  getFullAddress: SearchAddress;
  getDsGlobalConfig: DsGlobalConfig;
  validateInstructorAvailability: InstructorAvailabilityResponse;
  accessToken: Scalars["String"];
  accountAdjustmentChargeProducts: Array<Product>;
  accountAdjustmentDefermentProducts: Array<Product>;
  accountAdjustmentDiscountProducts: Array<Product>;
  accountAdjustmentFrequencies: Array<Frequency>;
  timeSlotsAvailability: Array<TimeSlot>;
  availabilityNUJ: AvailabilityNuj;
  checkShortNoticeTestAvailability: InstructorAvailabilityResponse;
  cabRank: CabRank;
  cabRankNUJ: CabRank;
  calculateChangeInstructorBalance: CalculateChangeInstructorBalanceResult;
  certificationList: Array<CertificationListItem>;
  combinedAvailability: CombinedAvailability;
  consents: CommunicationConsent;
  coverage: CoveredRegion;
  currentMotabilityProduct?: Maybe<MotabilityProduct>;
  customer?: Maybe<Customer>;
  customerByPersonId?: Maybe<Customer>;
  customerSearch: Array<Customer>;
  getDirectDebitInstalments: DirectDebitInstalments;
  getDocumentsByFranchise: FranchiseDocuments;
  getDocumentURL?: Maybe<Scalars["String"]>;
  getAgreementsDocumentsByPerson?: Maybe<Array<Maybe<AgreementDocuments>>>;
  getCustomerAndFranchiseDocumentsByPerson?: Maybe<
    CustomerAndFranchiseDocuments
  >;
  sendEmail: Scalars["Boolean"];
  retrieveDocumentURLs?: Maybe<Array<Maybe<SignedUrl>>>;
  downloadVersions?: Maybe<Array<Maybe<CustomDocument>>>;
  getDocumentPermsion?: Maybe<Array<Maybe<Permissions>>>;
  emailAddressExistsCheck: EmailAddressExistsCheckResult;
  existingLead?: Maybe<Lead>;
  franchiseAgreement: FranchiseAgreementAcceptance;
  fundingSources?: Maybe<Array<FundingSource>>;
  futureLessons: BookedLessons;
  instructor?: Maybe<Instructor>;
  instructors: Array<Instructor>;
  instructorSearch: Array<Instructor>;
  isInstructorInArrears: Scalars["Boolean"];
  getArrearsDetailForProposition: Array<Arrears>;
  invoiceUrl: InvoiceUrl;
  learner?: Maybe<Learner>;
  thisLearner?: Maybe<Learner>;
  learnerProgressByPersonId: LearnerProgress;
  lesson: Lesson;
  leaverReasons: Array<LeaverReasonListItem>;
  marketingFeed: MarketingFeed;
  marketingFeedCount: MarketingFeedCount;
  motabilityProducts: Array<MotabilityProduct>;
  motabilityCustomerUploadUrl?: Maybe<Scalars["String"]>;
  numberOfTicketsAssignedToUser: Scalars["Int"];
  numberOfTicketsAssignedToUserByStatus: Scalars["Int"];
  numberOfTicketsAssignedToUserByStatuses: Scalars["Int"];
  numberOfTicketsPickedUpByUser: Scalars["Int"];
  numberOfTicketsClosedByUser: Scalars["Int"];
  order?: Maybe<Order>;
  orderNUJ?: Maybe<Order>;
  DITOrder?: Maybe<DitOrderStatusResult>;
  orderPayment?: Maybe<OrderPayment>;
  productCategory?: Maybe<Array<Maybe<ProductCategory>>>;
  propositionDiscounts: Array<Discount>;
  propositionProducts: Array<PropositionProduct>;
  learnerTopUpDetails: LearnerForTopUpDetails;
  purchasablePropositions: Array<Proposition>;
  queueTicketCount: Scalars["Int"];
  queueTicketCountWithStatus: Scalars["Int"];
  queueProductivity: QueueProductivity;
  randomInstructors: Array<Instructor>;
  region: Region;
  regionLookup: Array<Maybe<RegionLookup>>;
  regions: Array<Maybe<Region>>;
  staff: Array<Staff>;
  teamMembersAssignedToQueue: Array<Staff>;
  testCentres: Array<TestCentre>;
  ticket: Ticket;
  ticketQueues: Array<TicketQueue>;
  ticketQueuesForUser: Array<TicketQueue>;
  tickets: Array<Ticket>;
  ticketsAssignedToStaffMember: Array<StaffMemberTicket>;
  ticketsForCustomer: Array<Ticket>;
  ticketsInQueue: Array<Ticket>;
  timeOff: TimeOff;
  supplierHubLocations: Array<SupplierHubLocation>;
  welfareCategoryTypes: Array<WelfareCategoryType>;
  welfareOutcomeTypes: Array<WelfareOutcomeType>;
  workItemsToday?: Maybe<Array<WorkItem>>;
  getAllGenerics: Array<DocumentVersion>;
  welfare?: Maybe<Welfare>;
  welfaresByPersonId?: Maybe<Array<Maybe<Welfare>>>;
  getAllTemplates: Array<DocumentVersion>;
  getAgreementDocumentRejectReason?: Maybe<Array<Reference>>;
  getDDIDocumentUrl?: Maybe<Scalars["String"]>;
  getPaymentData: PaymentData;
  vehicle: VehicleInformation;
  vehiclesCountByAssignmentType: Scalars["Int"];
  vehiclesByAssignmentType: Array<VehicleInformation>;
  searchVehicles: Array<VehicleInformation>;
  vehiclesByStageHistory: Array<VehicleInformation>;
  vehiclesCountByStageHistory: Scalars["Int"];
  vehicleSelection: Array<VehicleInformation>;
  vehicleSelectionCount: Scalars["Int"];
  getVehicleMakes: Array<Scalars["String"]>;
  onlineSaleVehicles: Array<OnlineVehiclesInformation>;
  vehicleReservation?: Maybe<VehicleReservation>;
  getInstructorBusinessProfile?: Maybe<InstructorBusinessProfile>;
  getInstructorAwardsForLearner?: Maybe<Array<Maybe<Award>>>;
};

export type QuerySearchForAddressArgs = {
  term: Scalars["String"];
  isExactMatch?: Maybe<Scalars["Boolean"]>;
};

export type QueryGetFullAddressArgs = {
  id: Scalars["String"];
};

export type QueryValidateInstructorAvailabilityArgs = {
  input: ValidateInstructorAvailabilityInput;
};

export type QueryAccessTokenArgs = {
  service: Service;
};

export type QueryAccountAdjustmentChargeProductsArgs = {
  instructorKey: Scalars["ID"];
};

export type QueryAccountAdjustmentDefermentProductsArgs = {
  instructorKey: Scalars["ID"];
};

export type QueryAccountAdjustmentDiscountProductsArgs = {
  instructorKey: Scalars["ID"];
};

export type QueryTimeSlotsAvailabilityArgs = {
  input: TimeSlotsAvailabilityInput;
};

export type QueryAvailabilityNujArgs = {
  input: AvailabilityNujInput;
};

export type QueryCheckShortNoticeTestAvailabilityArgs = {
  input: CheckShortNoticeTestAvailabilityInput;
};

export type QueryCabRankArgs = {
  input: CabRankInput;
};

export type QueryCabRankNujArgs = {
  input: CabRankNujInput;
};

export type QueryCalculateChangeInstructorBalanceArgs = {
  input?: Maybe<CalculateChangeInstructorInput>;
};

export type QueryCombinedAvailabilityArgs = {
  input: CombinedAvailabilityInput;
};

export type QueryConsentsArgs = {
  personKey: Scalars["ID"];
};

export type QueryCoverageArgs = {
  coverageId: Scalars["ID"];
};

export type QueryCurrentMotabilityProductArgs = {
  learnerId: Scalars["ID"];
};

export type QueryCustomerArgs = {
  id: Scalars["String"];
};

export type QueryCustomerByPersonIdArgs = {
  id: Scalars["ID"];
};

export type QueryCustomerSearchArgs = {
  input: CustomerSearchInput;
};

export type QueryGetDirectDebitInstalmentsArgs = {
  input: GetDirectDebitInstalmentsInput;
};

export type QueryGetDocumentsByFranchiseArgs = {
  brand: Scalars["String"];
};

export type QueryGetDocumentUrlArgs = {
  bucket: Scalars["String"];
  document: Scalars["String"];
};

export type QueryGetAgreementsDocumentsByPersonArgs = {
  personKey: Scalars["String"];
};

export type QueryGetCustomerAndFranchiseDocumentsByPersonArgs = {
  personKey: Scalars["String"];
};

export type QuerySendEmailArgs = {
  email: Scalars["String"];
  personKey: Scalars["String"];
  instructorName: Scalars["String"];
  productName: Scalars["String"];
  link: Scalars["String"];
  brand: Scalars["String"];
};

export type QueryRetrieveDocumentUrLsArgs = {
  documentRevisionKeys: Array<Maybe<Scalars["String"]>>;
};

export type QueryDownloadVersionsArgs = {
  documentRevisionKeys: Array<Maybe<Scalars["String"]>>;
};

export type QueryGetDocumentPermsionArgs = {
  documentKey: Scalars["String"];
};

export type QueryEmailAddressExistsCheckArgs = {
  input: EmailAddressExistsCheckInput;
};

export type QueryExistingLeadArgs = {
  email: Scalars["String"];
};

export type QueryFranchiseAgreementArgs = {
  agreementKey: Scalars["String"];
};

export type QueryFundingSourcesArgs = {
  input: FundingSourcesInput;
};

export type QueryFutureLessonsArgs = {
  input: FutureLessonsInput;
};

export type QueryInstructorArgs = {
  id: Scalars["ID"];
};

export type QueryInstructorsArgs = {
  instructorIds: Array<Scalars["ID"]>;
};

export type QueryInstructorSearchArgs = {
  term: Scalars["String"];
};

export type QueryIsInstructorInArrearsArgs = {
  id: Scalars["ID"];
};

export type QueryGetArrearsDetailForPropositionArgs = {
  input: ArrearsDetailInput;
};

export type QueryInvoiceUrlArgs = {
  transactionKey: Scalars["ID"];
};

export type QueryLearnerArgs = {
  id: Scalars["ID"];
};

export type QueryLearnerProgressByPersonIdArgs = {
  personId: Scalars["Int"];
};

export type QueryLessonArgs = {
  id: Scalars["ID"];
};

export type QueryMotabilityProductsArgs = {
  activeOnly: Scalars["Boolean"];
};

export type QueryMotabilityCustomerUploadUrlArgs = {
  fileName: Scalars["String"];
};

export type QueryNumberOfTicketsAssignedToUserByStatusArgs = {
  ticketStatus?: Maybe<TicketStatus>;
};

export type QueryNumberOfTicketsAssignedToUserByStatusesArgs = {
  ticketStatuses?: Maybe<Array<Maybe<TicketStatus>>>;
};

export type QueryOrderArgs = {
  orderId: Scalars["ID"];
};

export type QueryOrderNujArgs = {
  orderId: Scalars["ID"];
};

export type QueryDitOrderArgs = {
  orderId: Scalars["ID"];
};

export type QueryOrderPaymentArgs = {
  id: Scalars["ID"];
};

export type QueryPropositionDiscountsArgs = {
  propositionIds: Array<Scalars["ID"]>;
};

export type QueryPropositionProductsArgs = {
  propositionIds: Array<Scalars["ID"]>;
};

export type QueryLearnerTopUpDetailsArgs = {
  topUpId: Scalars["ID"];
};

export type QueryPurchasablePropositionsArgs = {
  type: PurchasablePropositionType;
  onlyPurchasableFranchises?: Maybe<Scalars["Boolean"]>;
};

export type QueryQueueTicketCountArgs = {
  queueDescription?: Maybe<Scalars["String"]>;
};

export type QueryQueueTicketCountWithStatusArgs = {
  queueDescription: Scalars["String"];
  ticketStatus: Scalars["String"];
};

export type QueryQueueProductivityArgs = {
  queueDescription?: Maybe<Scalars["String"]>;
};

export type QueryRandomInstructorsArgs = {
  input: RandomInstructorsInput;
};

export type QueryRegionArgs = {
  id: Scalars["ID"];
};

export type QueryRegionLookupArgs = {
  term: Scalars["String"];
};

export type QueryRegionsArgs = {
  input?: Maybe<RegionInput>;
};

export type QueryTeamMembersAssignedToQueueArgs = {
  queueDescription: Scalars["String"];
};

export type QueryTicketArgs = {
  ticketKey: Scalars["ID"];
};

export type QueryTicketsArgs = {
  input: TicketsInput;
};

export type QueryTicketsAssignedToStaffMemberArgs = {
  input: TicketsAssignedToStaffMemberInput;
};

export type QueryTicketsForCustomerArgs = {
  id: Scalars["ID"];
};

export type QueryTicketsInQueueArgs = {
  input: TicketsInQueueInput;
};

export type QueryTimeOffArgs = {
  id: Scalars["ID"];
};

export type QueryWelfareArgs = {
  id: Scalars["ID"];
};

export type QueryWelfaresByPersonIdArgs = {
  id: Scalars["ID"];
};

export type QueryGetPaymentDataArgs = {
  input: GetPaymentDataInput;
};

export type QueryVehicleArgs = {
  input: GetVehicleDetailsInput;
};

export type QueryVehiclesCountByAssignmentTypeArgs = {
  input: VehiclesByAssignmentTypeCountInput;
};

export type QueryVehiclesByAssignmentTypeArgs = {
  input: VehiclesByAssignmentTypeInput;
};

export type QuerySearchVehiclesArgs = {
  input: SearchVehiclesInput;
};

export type QueryVehiclesByStageHistoryArgs = {
  input: VehicleByStageInput;
};

export type QueryVehiclesCountByStageHistoryArgs = {
  input: VehiclesByStageHistoryCountInput;
};

export type QueryVehicleSelectionArgs = {
  input: VehicleSelectionInput;
};

export type QueryOnlineSaleVehiclesArgs = {
  input: OnlineVehiclesInput;
};

export type QueryVehicleReservationArgs = {
  input: VehicleReservationInput;
};

export type QueryGetInstructorBusinessProfileArgs = {
  personKey: Scalars["ID"];
};

export type QueryGetInstructorAwardsForLearnerArgs = {
  personKey: Scalars["ID"];
  learnerId?: Maybe<Scalars["Int"]>;
};

export type QueueProductivity = {
  __typename?: "QueueProductivity";
  pickedUpToday: Scalars["Int"];
  closedToday: Scalars["Int"];
};

export type RandomInstructorsInput = {
  personId?: Maybe<Scalars["String"]>;
  count?: Maybe<Scalars["Int"]>;
  brand?: Maybe<Brand>;
};

export type ReassignTicketInput = {
  ticketKey: Scalars["ID"];
  queueKey: Scalars["ID"];
  userId?: Maybe<Scalars["String"]>;
};

export type Reference = {
  __typename?: "Reference";
  referenceId: Scalars["Int"];
  referenceItemId: Scalars["Int"];
  itemDescription: Scalars["String"];
  active?: Maybe<Scalars["Boolean"]>;
};

export type RefundOrderInput = {
  orderPaymentId: Scalars["ID"];
  refundBookingFee: Scalars["Boolean"];
  businessFunded?: Maybe<Scalars["Boolean"]>;
};

export type Region = {
  __typename?: "Region";
  id: Scalars["ID"];
  regionName: Scalars["String"];
  regionCode: Scalars["String"];
  discounts: Array<Discount>;
  pricing?: Maybe<Array<Maybe<RegionPricing>>>;
  districts: Array<Maybe<District>>;
};

export type RegionDiscountsArgs = {
  input?: Maybe<DiscountsInput>;
};

export type RegionCoveragePricing = {
  __typename?: "RegionCoveragePricing";
  id: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
  productCategory: ProductCategory;
};

export type RegionInput = {
  regionCode: Scalars["String"];
};

export type RegionLookup = {
  __typename?: "RegionLookup";
  id: Scalars["ID"];
  regionName: Scalars["String"];
  regionCode: Scalars["String"];
  discounts?: Maybe<Array<Maybe<Discount>>>;
  pricing?: Maybe<Array<Maybe<RegionPricing>>>;
  districts: Array<Maybe<District>>;
};

export type RegionLookupDiscountsArgs = {
  input?: Maybe<DiscountsInput>;
};

export type RegionLookupPricingArgs = {
  input?: Maybe<RegionPricingInput>;
};

export type RegionPricing = {
  __typename?: "RegionPricing";
  id: Scalars["ID"];
  productCategory?: Maybe<ProductCategory>;
  franchiseType: FranchiseType;
  recommendedPrice?: Maybe<Scalars["Float"]>;
  maxPrice?: Maybe<Scalars["Float"]>;
  minPrice?: Maybe<Scalars["Float"]>;
};

export type RegionPricingInput = {
  propositionId: Scalars["ID"];
};

export type RegionalDiscountsInput = {
  discountId: Scalars["ID"];
  optIn: Scalars["Boolean"];
};

export type RejectLearnerInput = {
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
  rejectionReason: Scalars["String"];
};

export enum Relationship {
  Spouse = "Spouse",
  Child = "Child",
  Partner = "Partner",
  Other = "Other",
}

export type RemoveFromBuildPlanInput = {
  vehicleKeys: Array<Scalars["ID"]>;
};

export type RemovePendingFranchiseInput = {
  propositionAllocationId: Scalars["ID"];
};

export type RemoveVehicleAssignmentInput = {
  vehicleAssignmentId: Scalars["ID"];
  vehicleType: VehicleType;
};

export type RemoveVehicleInput = {
  vehiclePersonHistoryKey: Scalars["ID"];
};

export type RenewFranchiseInput = {
  instructorKey: Scalars["ID"];
  currentLineItemKey: Scalars["ID"];
  newFranchiseKey: Scalars["ID"];
  franchiseStartDate: Scalars["GraphQLDate"];
  isPending?: Maybe<Scalars["Boolean"]>;
  vehicleKey?: Maybe<Scalars["ID"]>;
  customLivery?: Maybe<Scalars["String"]>;
  bespokeWeeklyPayment?: Maybe<Scalars["Float"]>;
};

export enum RepeatFrequency {
  Daily = "Daily",
  Fortnightly = "Fortnightly",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export type RepeatSchedule = {
  frequency: RepeatFrequency;
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
};

export type RequestNewInstructorInput = {
  pupilId: Scalars["ID"];
};

export type RequestNewInstructorResult = {
  __typename?: "RequestNewInstructorResult";
  successful: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type RequestNewInstructorWithReasonInput = {
  pupilId: Scalars["ID"];
  reason: ChangeInstructorReason;
};

export type RequestNewInstructorWithReasonResult = {
  __typename?: "RequestNewInstructorWithReasonResult";
  successful: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type RequestRefundWithReasonInput = {
  pupilId: Scalars["ID"];
  reason: Scalars["String"];
};

export type RequestRefundWithReasonResult = {
  __typename?: "RequestRefundWithReasonResult";
  successful: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type ReserveVehicleInput = {
  vehicleKey: Scalars["ID"];
  propositionKey?: Maybe<Scalars["ID"]>;
  lineItemKey?: Maybe<Scalars["ID"]>;
  personKey: Scalars["ID"];
  customLivery?: Maybe<Scalars["String"]>;
};

export type ReserveVehicleResult = {
  __typename?: "ReserveVehicleResult";
  success: Scalars["Boolean"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type ReviewAgreementDocumentInput = {
  franchiseAgreementDocumentKey: Scalars["ID"];
  newAgreementDocumentStatus: AgreementDocumentStatus;
  message?: Maybe<Scalars["String"]>;
  expirationDate?: Maybe<Scalars["GraphQLDate"]>;
};

export enum Role {
  Instructor = "INSTRUCTOR",
  Agent = "AGENT",
  Learner = "LEARNER",
  Microservice = "MICROSERVICE",
  Public = "PUBLIC",
  LearnerJourney = "LEARNER_JOURNEY",
}

export type RujCancelledLesson = {
  __typename?: "RujCancelledLesson";
  successful: Scalars["Boolean"];
  lessonTime?: Maybe<Scalars["GraphQLDate"]>;
  errorType?: Maybe<Scalars["String"]>;
};

export type RujLoginInput = {
  brand: Brand;
  pupilId: Scalars["ID"];
  dateOfBirth: Scalars["GraphQLDate"];
  surname: Scalars["String"];
  clientId?: Maybe<Scalars["String"]>;
};

export type RujLoginResult = {
  __typename?: "RujLoginResult";
  authenticated: Scalars["Boolean"];
  attemptsRemaining?: Maybe<Scalars["Int"]>;
  lockoutExpiry?: Maybe<Scalars["GraphQLDate"]>;
  accessToken?: Maybe<Scalars["String"]>;
  encryptedPersonId?: Maybe<Scalars["String"]>;
  customerTypes?: Maybe<Array<CustomerType>>;
};

export enum Salutation {
  Dr = "Dr",
  Master = "Master",
  Mr = "Mr",
  Miss = "Miss",
  Mrs = "Mrs",
  Ms = "Ms",
  Mx = "Mx",
}

export type SaveInstructorAwardsInput = {
  personKey: Scalars["ID"];
  learnerId: Scalars["Int"];
  awards?: Maybe<Array<Maybe<Award>>>;
};

export type SaveInstructorBusinessProfileInput = {
  personKey: Scalars["ID"];
  displayName?: Maybe<Scalars["String"]>;
  additionalLanguages?: Maybe<Array<Maybe<Scalars["String"]>>>;
  badgeDate?: Maybe<Scalars["GraphQLDate"]>;
  teaching_enjoyment?: Maybe<Scalars["String"]>;
  teaching_style?: Maybe<Scalars["String"]>;
  most_rewarding_aspect?: Maybe<Scalars["String"]>;
  self_description?: Maybe<Scalars["String"]>;
  free_time_activities?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
};

export type SearchAddress = {
  __typename?: "SearchAddress";
  id: Scalars["ID"];
  line1: Scalars["String"];
  line2: Scalars["String"];
  town: Scalars["String"];
  county: Scalars["String"];
  country: Scalars["String"];
  postcode: Scalars["String"];
};

export type SearchVehiclesInput = {
  term: Scalars["String"];
  identifiers: Array<VehicleIdentifier>;
};

export type Sector = {
  __typename?: "Sector";
  id: Scalars["ID"];
  sectorCode: Scalars["String"];
  covered?: Maybe<Scalars["Boolean"]>;
};

export type SendComplaintEmailInput = {
  brand: Brand;
  reason: Scalars["String"];
  complaintDetails: Scalars["String"];
  requiresResponse: Scalars["Boolean"];
  complaintResolution: Scalars["String"];
};

export type SendMotabilityWelcomeEmailInput = {
  learnerId: Scalars["ID"];
  instructorId: Scalars["ID"];
};

export type SendPupilhelpEmailInput = {
  brand: Brand;
  message: Scalars["String"];
};

export enum Service {
  DrivingLessons = "DrivingLessons",
  Helix = "Helix",
  Hubcapp = "Hubcapp",
  LocalPages = "LocalPages",
}

export type SignFranchiseAgreementDocumentFromSalesJourneyInput = {
  agreementKey: Scalars["ID"];
  instructorId: Scalars["Int"];
  email: Scalars["String"];
};

export type SignedUrl = {
  __typename?: "SignedUrl";
  documentName: Scalars["String"];
  url: Scalars["String"];
};

export type SimpleMutationResult = {
  __typename?: "SimpleMutationResult";
  success: Scalars["Boolean"];
  message?: Maybe<Scalars["String"]>;
};

export type Skill = {
  __typename?: "Skill";
  id: Scalars["ID"];
  skillName: Scalars["String"];
  score: Scalars["Int"];
};

export type SkillCategory = {
  __typename?: "SkillCategory";
  id: Scalars["ID"];
  categoryName: Scalars["String"];
  progressPercentage: Scalars["Float"];
  skills: Array<Maybe<Skill>>;
};

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type SpokenLanguage = {
  __typename?: "SpokenLanguage";
  id: Scalars["ID"];
  person: Person;
  name?: Maybe<Scalars["String"]>;
};

export type Staff = {
  __typename?: "Staff";
  id: Scalars["ID"];
  userId: Scalars["String"];
  displayName: Scalars["String"];
  createdOn?: Maybe<Scalars["GraphQLDate"]>;
};

export type StaffMember = {
  __typename?: "StaffMember";
  staffKey: Scalars["ID"];
  userId?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  createdOn?: Maybe<Scalars["GraphQLDate"]>;
};

export type StaffMemberTicket = {
  __typename?: "StaffMemberTicket";
  id: Scalars["ID"];
  fromDate: Scalars["GraphQLDate"];
  ticketId: Scalars["Int"];
  displayName: Scalars["String"];
  status: TicketStatus;
  workItemDescription?: Maybe<Scalars["String"]>;
  userCanRead: Scalars["Boolean"];
  userCanWrite: Scalars["Boolean"];
  customer: Customer;
};

export enum StudyFormat {
  FullTime = "FullTime",
  PartTime = "PartTime",
  Tbc = "TBC",
}

export enum SupplierFilter {
  All = "All",
  Ceva = "Ceva",
  Cox = "Cox",
}

export type SupplierHubLocation = {
  __typename?: "SupplierHubLocation";
  id: Scalars["ID"];
  location: Scalars["String"];
  supplier: Scalars["String"];
};

export type TemplateDocument = {
  __typename?: "TemplateDocument";
  documentName: Scalars["String"];
  documentKey: Scalars["String"];
  documentRevisionKey: Scalars["String"];
  buffers?: Maybe<Buffers>;
};

export type Test = {
  __typename?: "Test";
  id: Scalars["ID"];
  date: Scalars["GraphQLDate"];
  type: TestType;
  status: TestStatus;
  editable: Scalars["Boolean"];
  learner: Learner;
};

export type TestCentre = {
  __typename?: "TestCentre";
  id: Scalars["ID"];
  name: Scalars["String"];
  line1: Scalars["String"];
  line2: Scalars["String"];
  town: Scalars["String"];
  county: Scalars["String"];
  postcode: Scalars["Postcode"];
  lineItemKey?: Maybe<Scalars["ID"]>;
};

export type TestDeleteResponse = {
  __typename?: "TestDeleteResponse";
  id: Scalars["ID"];
};

export enum TestStatus {
  Passed = "Passed",
  Failed = "Failed",
  Cancelled = "Cancelled",
  Upcoming = "Upcoming",
  Pending = "Pending",
}

export enum TestType {
  Theory = "Theory",
  Practical = "Practical",
  Dit1 = "DIT1",
  Dit2 = "DIT2",
  Dit3 = "DIT3",
}

export type Ticket = {
  __typename?: "Ticket";
  id: Scalars["ID"];
  ticketId?: Maybe<Scalars["Int"]>;
  status?: Maybe<TicketStatus>;
  personKey: Scalars["String"];
  person: Customer;
  ticketType: Scalars["String"];
  fromDate?: Maybe<Scalars["GraphQLDate"]>;
  description?: Maybe<Scalars["String"]>;
  assignedTo?: Maybe<StaffMember>;
  workItems: Array<WorkItem>;
  queue?: Maybe<TicketQueue>;
};

export type TicketQueue = {
  __typename?: "TicketQueue";
  id: Scalars["ID"];
  name: Scalars["String"];
  amountOpen: Scalars["Int"];
  unassignedAmount: Scalars["Int"];
};

export enum TicketQueueType {
  InstructorService = "InstructorService",
  Reallocation = "Reallocation",
  LearnerService = "LearnerService",
  Complaint = "Complaint",
  Motability = "Motability",
  Ditig = "DITIG",
  Retention = "Retention",
  DitSupport = "DITSupport",
  Approval = "Approval",
  Fleet = "Fleet",
  Finance = "Finance",
  RefundRequests = "RefundRequests",
  FranchiseAdmin = "FranchiseAdmin",
}

export enum TicketSource {
  AAdvance = "AAdvance",
  App = "App",
  ContactForm = "ContactForm",
  Email = "Email",
  Message = "Message",
  Phone = "Phone",
  Ruj = "RUJ",
  OnlinePurchaseJourney = "OnlinePurchaseJourney",
}

export enum TicketStatus {
  Active = "Active",
  Closed = "Closed",
  InProgress = "InProgress",
}

export type TicketsAssignedToStaffMemberInput = {
  ticketStatus: TicketStatus;
  sort: Scalars["String"];
  offset: Scalars["Int"];
  rowsPerPage: Scalars["Int"];
};

export type TicketsInQueueInput = {
  queueDescription: Scalars["String"];
  ticketStatus: TicketStatus;
  sort: Scalars["String"];
  offset: Scalars["Int"];
  rowsPerPage: Scalars["Int"];
};

export type TicketsInput = {
  ticketStatus: TicketStatus;
  offset: Scalars["Int"];
  rowsPerPage: Scalars["Int"];
};

export type TimeOff = {
  __typename?: "TimeOff";
  id: Scalars["ID"];
  created: Scalars["GraphQLDate"];
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  notes?: Maybe<Scalars["String"]>;
  status: TimeOffStatus;
  repeatFrequency?: Maybe<RepeatFrequency>;
  instructor: Instructor;
};

export enum TimeOffStatus {
  Active = "Active",
  Cancelled = "Cancelled",
}

export type TimeSlot = {
  __typename?: "TimeSlot";
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  travelTime: Scalars["Int"];
  availability: Scalars["Boolean"];
  bookedPendingLessonId?: Maybe<Scalars["ID"]>;
  prepaid: Scalars["Boolean"];
  insufficientBalanceForBooking?: Maybe<Scalars["Boolean"]>;
};

export type TimeSlotInput = {
  startDateTime: Scalars["GraphQLDate"];
  endDateTime: Scalars["GraphQLDate"];
  travelTime: Scalars["Int"];
};

export type TimeSlotNuj = {
  __typename?: "TimeSlotNUJ";
  endTime: Scalars["GraphQLDate"];
  startTime: Scalars["GraphQLDate"];
};

export type TimeSlotsAvailabilityInput = {
  instructorKey: Scalars["ID"];
  learnerKey: Scalars["ID"];
  timeSlots: Array<TimeSlotInput>;
  ignoreTimeOff?: Maybe<Scalars["Boolean"]>;
  ignoreWorkHours?: Maybe<Scalars["Boolean"]>;
  ignoreCalendarItemIds?: Maybe<Array<Scalars["ID"]>>;
  prebook?: Maybe<Scalars["Boolean"]>;
};

export type ToggleDirectDebitMandateInput = {
  personKey: Scalars["ID"];
  active: Scalars["Boolean"];
};

export enum TransmissionType {
  Manual = "Manual",
  Automatic = "Automatic",
}

export enum TransmissionTypeFilter {
  All = "All",
  Manual = "Manual",
  Automatic = "Automatic",
}

export type TravelTime = {
  __typename?: "TravelTime";
  id: Scalars["ID"];
  proposition?: Maybe<Proposition>;
  travelTimeId: Scalars["ID"];
  lineItemKey?: Maybe<Scalars["ID"]>;
  time: Scalars["String"];
};

export type UnReserveVehicleInput = {
  vehicleKey: Scalars["ID"];
};

export type UndoEndFranchiseInput = {
  instructorId: Scalars["ID"];
  propositionAllocationId: Scalars["ID"];
  lockCap: Scalars["Boolean"];
};

export type UpdateAllocationInput = {
  allocationKey: Scalars["ID"];
  lessonType?: Maybe<LearnerLessonType>;
  rate?: Maybe<Scalars["Float"]>;
};

export type UpdateAreaCoverageInput = {
  lineItemCoverageId: Scalars["String"];
  districtOptIns?: Maybe<Array<Maybe<UpdateDistrictInput>>>;
  discounts?: Maybe<Array<Maybe<RegionalDiscountsInput>>>;
  preTestPrice?: Maybe<Scalars["Float"]>;
  postTestPrice?: Maybe<Scalars["Float"]>;
};

export type UpdateAreaCoverageResult = {
  __typename?: "UpdateAreaCoverageResult";
  region?: Maybe<UpdateRegionResponse>;
  pricing?: Maybe<PricingUpdateResult>;
  discounts?: Maybe<Array<Maybe<DiscountUpdateResult>>>;
};

export type UpdateCertificationInput = {
  id: Scalars["ID"];
  reference?: Maybe<Scalars["String"]>;
  toDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type UpdateCoveragePricingInput = {
  id: Scalars["String"];
  manualPrice?: Maybe<Scalars["String"]>;
  automaticPrice?: Maybe<Scalars["String"]>;
  shortNoticePrice?: Maybe<Scalars["String"]>;
};

export type UpdateDeliveryDateInput = {
  vehicleKeys: Array<Scalars["ID"]>;
  deliveryDate: Scalars["GraphQLDate"];
};

export type UpdateDistrictInput = {
  id: Scalars["ID"];
  covered?: Maybe<Scalars["Boolean"]>;
  sectorOptOuts?: Maybe<Array<Maybe<UpdateSectorInput>>>;
};

export type UpdateFranchiseDateInput = {
  instructorId: Scalars["ID"];
  propositionAllocationId: Scalars["ID"];
  newDate: Scalars["GraphQLDate"];
};

export type UpdateFranchiseRegulationStatusInput = {
  propositionAllocationId: Scalars["ID"];
  newRegulationStatus: FcaAgreementStatus;
};

export type UpdateInstructorInput = {
  id: Scalars["ID"];
  title?: Maybe<Salutation>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressPostcode?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactLandline?: Maybe<Scalars["String"]>;
  contactMobile?: Maybe<Scalars["String"]>;
  vehicleMileage?: Maybe<UpdatedVehicleMileage>;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
};

export type UpdateInstructorPreferencesInput = {
  instructorId: Scalars["ID"];
  propositionAllocationId: Scalars["ID"];
  learnerCapacity?: Maybe<Scalars["Int"]>;
  capLock?: Maybe<Scalars["Boolean"]>;
  workingHours?: Maybe<Array<Maybe<DayInput>>>;
  testCentres?: Maybe<Array<Scalars["ID"]>>;
  travelTime?: Maybe<Scalars["Int"]>;
  userType: UserType;
  lessonTypes?: Maybe<Array<Maybe<LessonTypePreferenceInput>>>;
};

export type UpdateInsuranceHistoryInput = {
  id?: Maybe<Scalars["ID"]>;
  instructorId: Scalars["ID"];
  vehicleId: Scalars["ID"];
  certificateNumber: Scalars["String"];
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
};

export type UpdateInsuranceInput = {
  id: Scalars["String"];
  instructorId: Scalars["String"];
  certificateNumber: Scalars["String"];
  startDate: Scalars["GraphQLDate"];
  endDate: Scalars["GraphQLDate"];
};

export type UpdateInvoiceStatusInput = {
  transactionId: Scalars["ID"];
  paid: Scalars["Boolean"];
  notes?: Maybe<Scalars["String"]>;
};

export type UpdateLeadInput = {
  id: Scalars["ID"];
  salutation?: Maybe<Salutation>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  mobileNumber?: Maybe<Scalars["String"]>;
  emailAddress: Scalars["String"];
  address?: Maybe<AddressInput>;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  licenceNumber?: Maybe<Scalars["String"]>;
  communication?: Maybe<CommunicationConsentInput>;
  recommenderId?: Maybe<Scalars["Int"]>;
  channel?: Maybe<TicketSource>;
};

export type UpdateLearnerInput = {
  id: Scalars["ID"];
  title?: Maybe<Salutation>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  contactEmail?: Maybe<Scalars["String"]>;
  contactMobile?: Maybe<Scalars["String"]>;
  contactLandline?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["GraphQLDate"]>;
  addressKey?: Maybe<Scalars["ID"]>;
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  addressTown?: Maybe<Scalars["String"]>;
  addressPostcode?: Maybe<Scalars["String"]>;
  billingAddressUpdate?: Maybe<Scalars["Boolean"]>;
  pickupAddressUpdate?: Maybe<Scalars["Boolean"]>;
  appointedBy?: Maybe<Scalars["String"]>;
};

export type UpdateLearnerProgressInput = {
  learnerId: Scalars["ID"];
  skills: Array<Maybe<UpdateLearnerProgressSkillInput>>;
};

export type UpdateLearnerProgressSkillInput = {
  id: Scalars["ID"];
  score: Scalars["Int"];
};

export type UpdateLessonInput = {
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
};

export type UpdateMileageInput = {
  vehicleKey: Scalars["ID"];
  mileage: Scalars["Int"];
};

export type UpdateMotabilityAllocationInput = {
  learnerId: Scalars["ID"];
  currentProductId: Scalars["ID"];
  newProductId: Scalars["ID"];
  transmissionType?: Maybe<TransmissionType>;
};

export type UpdateRegionCoveragePriceInput = {
  id: Scalars["ID"];
  price: Scalars["Float"];
};

export type UpdateRegionPriceInput = {
  id: Scalars["ID"];
  price: Scalars["Float"];
};

export type UpdateRegionResponse = {
  __typename?: "UpdateRegionResponse";
  id: Scalars["ID"];
};

export type UpdateRequiredCompletionDateInput = {
  vehicleKey: Scalars["ID"];
  requiredCompletionDate: Scalars["GraphQLDate"];
};

export type UpdateSectorInput = {
  id: Scalars["ID"];
  optedOut?: Maybe<Scalars["Boolean"]>;
};

export type UpdateTimeOffInput = {
  id: Scalars["ID"];
  notes?: Maybe<Scalars["String"]>;
};

export type UpdateVehicleAvailableInput = {
  vehicleKey: Scalars["ID"];
  available: Scalars["Boolean"];
};

export type UpdateVehicleEndOfTermStatusInput = {
  vehicleKey: Scalars["ID"];
  endOfTermStatus: EndOfTermStatus;
};

export type UpdateWelfareInput = {
  id: Scalars["ID"];
  note?: Maybe<Scalars["String"]>;
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  outcomes?: Maybe<Array<Maybe<UpdateWelfareOutcomeInput>>>;
};

export type UpdateWelfareOutcomeInput = {
  id?: Maybe<Scalars["ID"]>;
  outcomeTypeId?: Maybe<Scalars["Int"]>;
  note: Scalars["String"];
};

export type UpdatedLearnerCapacity = {
  maximum: Scalars["Int"];
};

export type UpdatedMotabilityAllocation = {
  __typename?: "UpdatedMotabilityAllocation";
  learnerId: Scalars["ID"];
  currentProductId: Scalars["ID"];
  newProductId: Scalars["ID"];
  transmissionType?: Maybe<TransmissionType>;
};

export type UpdatedVehicleMileage = {
  id: Scalars["ID"];
  mileage: Scalars["Int"];
};

export enum UserType {
  Business = "Business",
  Instructor = "Instructor",
}

export type ValidateInstructorAvailabilityInput = {
  instructorId: Scalars["Int"];
  postCode: Scalars["String"];
  lineItemKey: Scalars["ID"];
};

export type Vat = {
  __typename?: "Vat";
  id: Scalars["ID"];
  rate?: Maybe<Scalars["Float"]>;
};

export type Vehicle = {
  __typename?: "Vehicle";
  id: Scalars["ID"];
  registration: Scalars["String"];
  manufacturer: Scalars["String"];
  model: Scalars["String"];
  colour: Scalars["String"];
  specification: Scalars["String"];
  transmissionType: TransmissionType;
  fuelType: FuelType;
  mileage?: Maybe<Array<Mileage>>;
  insurance?: Maybe<Insurance>;
  instructor?: Maybe<Instructor>;
  vehicleType: VehicleType;
  vehicleBands: Array<VehicleBand>;
};

export enum VehicleAdaptions {
  Yes = "Yes",
  No = "No",
}

export type VehicleAssignment = {
  __typename?: "VehicleAssignment";
  id: Scalars["ID"];
  active: Scalars["Boolean"];
  assignmentDate: Scalars["GraphQLDate"];
  instructor?: Maybe<Instructor>;
  vehicle: Vehicle;
};

export enum VehicleAssignmentType {
  Franchise = "Franchise",
  Courtesy = "Courtesy",
  Interim = "Interim",
}

export type VehicleBand = {
  __typename?: "VehicleBand";
  name: Scalars["String"];
};

export type VehicleByStageInput = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  stageTable: VehicleStageTable;
  inProductionFilter?: Maybe<InProductionFilter>;
  currentBuildPlanFilter?: Maybe<CurrentBuildPlanFilter>;
  commissioningUnallocatedFilter?: Maybe<CommissioningUnallocatedFilter>;
  commissioningReservedAndCommittedFilter?: Maybe<
    CommissioningReservedAndCommittedFilter
  >;
};

export enum VehicleIdentifier {
  Registration = "Registration",
  Vin = "VIN",
  OrderId = "OrderID",
}

export type VehicleInformation = {
  __typename?: "VehicleInformation";
  id: Scalars["ID"];
  VIN?: Maybe<Scalars["String"]>;
  registration?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  design?: Maybe<Scalars["String"]>;
  colour?: Maybe<Scalars["String"]>;
  transmissionType?: Maybe<TransmissionType>;
  fuelType?: Maybe<FuelType>;
  status?: Maybe<Scalars["String"]>;
  orderId?: Maybe<Scalars["Int"]>;
  order?: Maybe<VehicleOrder>;
  totalTimeWithADI?: Maybe<Scalars["Int"]>;
  totalMileage?: Maybe<Scalars["Int"]>;
  poolHistory?: Maybe<Array<PoolHistory>>;
  ownershipHistory?: Maybe<Array<OwnershipHistory>>;
  livery?: Maybe<Scalars["String"]>;
  priceBand?: Maybe<Scalars["String"]>;
  brandAdaptions?: Maybe<BrandAdaptions>;
  pool?: Maybe<Array<VehiclePool>>;
  location?: Maybe<SupplierHubLocation>;
  stageDuration?: Maybe<Scalars["Int"]>;
  vehicleAssignmentType?: Maybe<CommissioningTableUserType>;
  estStartDate?: Maybe<Scalars["GraphQLDate"]>;
  agreementStatus?: Maybe<Scalars["Boolean"]>;
  feesStatus?: Maybe<Scalars["Boolean"]>;
  earliestDelivery?: Maybe<Scalars["GraphQLDate"]>;
  onFleetedDate?: Maybe<Scalars["GraphQLDate"]>;
  stage?: Maybe<Scalars["String"]>;
  stageCategory?: Maybe<Scalars["String"]>;
  priceExVat?: Maybe<Scalars["Float"]>;
  dateReserved?: Maybe<Scalars["GraphQLDate"]>;
  lastAwaitingDeliveryRecord?: Maybe<AwaitingDeliveryRecord>;
  mileage?: Maybe<Array<Mileage>>;
  insurance?: Maybe<Insurance>;
  vehicleType?: Maybe<VehicleType>;
  requiredCompletionDate?: Maybe<Scalars["GraphQLDate"]>;
  available?: Maybe<Scalars["GraphQLDate"]>;
};

export type VehicleOrder = {
  __typename?: "VehicleOrder";
  id: Scalars["ID"];
  orderId: Scalars["Int"];
  oemOrderRef: Scalars["String"];
  orderDate?: Maybe<Scalars["GraphQLDate"]>;
  createdDate?: Maybe<Scalars["GraphQLDate"]>;
};

export type VehiclePersonHistory = {
  __typename?: "VehiclePersonHistory";
  vehiclePersonHistoryKey: Scalars["ID"];
  vehicleKey?: Maybe<Scalars["ID"]>;
  vehicle?: Maybe<VehicleInformation>;
  personKey?: Maybe<Scalars["ID"]>;
  fromDate?: Maybe<Scalars["GraphQLDate"]>;
  toDate?: Maybe<Scalars["GraphQLDate"]>;
  active?: Maybe<Scalars["Boolean"]>;
};

export enum VehiclePool {
  Courtesy = "Courtesy",
  Interim = "Interim",
  OnlineSales = "OnlineSales",
  Sales = "Sales",
  Renewals = "Renewals",
  Unassigned = "Unassigned",
}

export enum VehiclePoolFilter {
  All = "All",
  Courtesy = "Courtesy",
  Interim = "Interim",
  OnlineSales = "OnlineSales",
  Sales = "Sales",
  Renewals = "Renewals",
  Unassigned = "Unassigned",
}

export type VehicleReservation = {
  __typename?: "VehicleReservation";
  id: Scalars["ID"];
  personKey: Scalars["ID"];
  vehicle: VehicleInformation;
  proposition: Proposition;
};

export type VehicleReservationInput = {
  personKey: Scalars["ID"];
};

export type VehicleSelectionFilters = {
  make?: Maybe<Scalars["String"]>;
  transmission?: Maybe<TransmissionTypeFilter>;
  status?: Maybe<VehicleSelectionStatus>;
  pool?: Maybe<VehiclePoolFilter>;
  livery?: Maybe<Scalars["String"]>;
  priceBand?: Maybe<Scalars["String"]>;
};

export type VehicleSelectionInput = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  vehicleSelectionFilters: VehicleSelectionFilters;
};

export enum VehicleSelectionStatus {
  All = "All",
  AwaitingProduction = "AwaitingProduction",
  InProduction = "InProduction",
  InTransit = "InTransit",
  InUk = "InUK",
  InRepair = "InRepair",
  Commissioning = "Commissioning",
}

export enum VehicleSortField {
  TotalMileage = "TotalMileage",
  TimeWithAdi = "TimeWithADI",
}

export enum VehicleStageTable {
  InProduction = "InProduction",
  CurrentBuildPlan = "CurrentBuildPlan",
  Unallocated = "Unallocated",
  Reserved = "Reserved",
  Committed = "Committed",
  AwaitingDelivery = "AwaitingDelivery",
}

export enum VehicleType {
  Instructor = "Instructor",
  Business = "Business",
}

export type VehiclesByAssignmentTypeCountInput = {
  assignmentType: VehicleAssignmentType;
  searchVehicleKeys?: Maybe<Array<Scalars["ID"]>>;
};

export type VehiclesByAssignmentTypeInput = {
  assignmentType: VehicleAssignmentType;
  searchVehicleKeys?: Maybe<Array<Scalars["ID"]>>;
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
  sortBy?: Maybe<VehicleSortField>;
  sortOrder?: Maybe<SortOrder>;
};

export type VehiclesByStageHistoryCountInput = {
  stageTable: VehicleStageTable;
  inProductionFilter?: Maybe<InProductionFilter>;
  currentBuildPlanFilter?: Maybe<CurrentBuildPlanFilter>;
  commissioningUnallocatedFilter?: Maybe<CommissioningUnallocatedFilter>;
  commissioningReservedAndCommittedFilter?: Maybe<
    CommissioningReservedAndCommittedFilter
  >;
};

export type Welfare = {
  __typename?: "Welfare";
  id: Scalars["ID"];
  category?: Maybe<WelfareCategoryType>;
  note?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["GraphQLDate"]>;
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
  outcomes?: Maybe<Array<Maybe<WelfareOutcome>>>;
  personKey?: Maybe<Scalars["ID"]>;
};

export type WelfareCategoryType = {
  __typename?: "WelfareCategoryType";
  id: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
};

export type WelfareOutcome = {
  __typename?: "WelfareOutcome";
  id: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  outcomeTypeId: Scalars["Int"];
  dateFrom?: Maybe<Scalars["GraphQLDate"]>;
  dateTo?: Maybe<Scalars["GraphQLDate"]>;
};

export type WelfareOutcomeType = {
  __typename?: "WelfareOutcomeType";
  id: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
};

export type WorkItem = {
  __typename?: "WorkItem";
  id: Scalars["ID"];
  person: Customer;
  estimatedDate?: Maybe<Scalars["GraphQLDate"]>;
  schedule?: Maybe<WorkItemSchedule>;
  initialContactMethod: InitialContactMethod;
  confidence: Scalars["Float"];
  type: WorkItemType;
  status: WorkItemStatus;
  note?: Maybe<Scalars["String"]>;
  ticket: Ticket;
};

export type WorkItemInput = {
  ticketKey: Scalars["ID"];
  workItemType?: Maybe<WorkItemType>;
  status: WorkItemStatus;
  workItemKey?: Maybe<Scalars["ID"]>;
  note?: Maybe<Scalars["String"]>;
  scheduleFrom?: Maybe<Scalars["GraphQLDate"]>;
  scheduleTo?: Maybe<Scalars["GraphQLDate"]>;
  estimatedDate?: Maybe<Scalars["GraphQLDate"]>;
  confidence?: Maybe<Scalars["Float"]>;
};

export type WorkItemSchedule = {
  __typename?: "WorkItemSchedule";
  id: Scalars["ID"];
  fromDate: Scalars["GraphQLDate"];
  toDate: Scalars["GraphQLDate"];
};

export enum WorkItemStatus {
  Waiting = "Waiting",
  Complete = "Complete",
  InProgress = "InProgress",
  Scheduled = "Scheduled",
}

export enum WorkItemType {
  AdiCallBackRequest = "ADICallBackRequest",
  AandSApplicationReceived = "AandSApplicationReceived",
  AandSApplicationSent = "AandSApplicationSent",
  AandSClaimFormSent = "AandSClaimFormSent",
  Accounts = "Accounts",
  AccountsDepositRefunded = "AccountsDepositRefunded",
  AccountsPayPlanSetUp = "AccountsPayPlanSetUp",
  AssociatedFranchisesEndedCheck = "AssociatedFranchisesEndedCheck",
  AwaitingCreditCheck = "AwaitingCreditCheck",
  BalanceQuery = "BalanceQuery",
  BankDetailsUpdated = "BankDetailsUpdated",
  BespokeRateCreated = "BespokeRateCreated",
  BespokeRateRemoved = "BespokeRateRemoved",
  BlueDualBrakeRequest = "BlueDualBrakeRequest",
  BusinessSupportCall = "BusinessSupportCall",
  Covid19BalanceWithAccounts = "COVID19BalanceWithAccounts",
  Covid19DeferredBalanceRepaid = "COVID19DeferredBalanceRepaid",
  CscTelephoneOptionsIvr = "CSCTelephoneOptionsIVR",
  CscWaitTimeResponseTime = "CSCWaitTimeResponseTime",
  CalendarUpdates = "CalendarUpdates",
  Call1stNoContact = "Call1stNoContact",
  Call2ndNoContact = "Call2ndNoContact",
  Call3rdNoContact = "Call3rdNoContact",
  Call4thNoContact = "Call4thNoContact",
  Call5thNoContact = "Call5thNoContact",
  CallAbandoned = "CallAbandoned",
  CallBackNotReceived = "CallBackNotReceived",
  CallBackRequest = "CallBackRequest",
  CallContacted = "CallContacted",
  CallInbound = "CallInbound",
  CallListeningRequest = "CallListeningRequest",
  CallReengagement = "CallReengagement",
  CancellationRequest = "CancellationRequest",
  Capability = "Capability",
  CloseAccount = "CloseAccount",
  Commendation = "Commendation",
  ComplaintAdi = "ComplaintADI",
  ComplaintCsc = "ComplaintCSC",
  ComplaintFleet = "ComplaintFleet",
  ComplaintMotability = "ComplaintMotability",
  ComplaintNotLogged = "ComplaintNotLogged",
  ComplaintsProcess = "ComplaintsProcess",
  ConsolidationCall = "ConsolidationCall",
  CoverageUpdated = "CoverageUpdated",
  CreDitCheckCompleted = "CreDITCheckCompleted",
  CreateNewPupil = "CreateNewPupil",
  CreateSelfSourcedPupil = "CreateSelfSourcedPupil",
  CrossSaleAabsm = "CrossSaleAABSM",
  DbsContact = "DBSContact",
  DbsDuetoExpire = "DBSDuetoExpire",
  DbsExperianApplication = "DBSExperianApplication",
  DbsExpired = "DBSExpired",
  DbsUpdated = "DBSUpdated",
  DitSupportContinuingRights = "DITSupportContinuingRights",
  DitDrivingTestResult = "DITDrivingTestResult",
  DitSupportFeefo = "DITSupportFeefo",
  DitInitialSetUp = "DITInitialSetUp",
  DitInstructionalTestResult = "DITInstructionalTestResult",
  DitntuDeclinedbytheBusiness = "DITNTUDeclinedbytheBusiness",
  DitntuFailedtoMakeContactbyPhoneEndofContactStrategy = "DITNTUFailedtoMakeContactbyPhoneEndofContactStrategy",
  DitntuFailedtoMakeContactbyPhoneIncorrectNumber = "DITNTUFailedtoMakeContactbyPhoneIncorrectNumber",
  DitntuGonewithaCompetitor = "DITNTUGonewithaCompetitor",
  DitntuNotEligible = "DITNTUNotEligible",
  DitntuStayingwithCurrentEmployer = "DITNTUStayingwithCurrentEmployer",
  DitntuTakenEmploymentElsewhere = "DITNTUTakenEmploymentElsewhere",
  DitntuTooExpensive = "DITNTUTooExpensive",
  DitntuTrainerNotAvailable = "DITNTUTrainerNotAvailable",
  DitntuTrainingIsTooLong = "DITNTUTrainingIsTooLong",
  DitntuUnwillingtoTravel = "DITNTUUnwillingtoTravel",
  DitNoContact1stAttempt = "DITNoContact1stAttempt",
  DitNoContact2ndAttempt = "DITNoContact2ndAttempt",
  DitNoContact3rdAttempt = "DITNoContact3rdAttempt",
  DitNoContact4thAttempt = "DITNoContact4thAttempt",
  DitNoContact5thAttempt = "DITNoContact5thAttempt",
  DitReallocation = "DITReallocation",
  DitRefundRequestBereavement = "DITRefundRequestBereavement",
  DitSupportRefundChangeofCircumstances = "DITSupportRefundChangeofCircumstances",
  DitSupportRefundMedicalReasons = "DITSupportRefundMedicalReasons",
  DitSupportRefundUnhappywithTrainer = "DITSupportRefundUnhappywithTrainer",
  DitSupportRefundUnhappywithCourse = "DITSupportRefundUnhappywithCourse",
  DitSupportRefundWithinCoolingoffPeriod = "DITSupportRefundWithinCoolingoffPeriod",
  DitRenewalFleetOrderNewCar = "DITRenewalFleetOrderNewCar",
  DitRenewalPdItoAdiFranchiseKeyed = "DITRenewalPDItoADIFranchiseKeyed",
  DitRenewalPdItoAdiInitialContact = "DITRenewalPDItoADIInitialContact",
  DitRenewalPdItoAdIadmin = "DITRenewalPDItoADIadmin",
  DitResponsefromImiOffer = "DITResponsefromImiOffer",
  DitRetentionFollowupContact = "DITRetentionFollowupContact",
  DitRetentionInitialContact = "DITRetentionInitialContact",
  DitRetentionIssueExitInterview = "DITRetentionIssueExitInterview",
  DitRetentionLeaverChecklist = "DITRetentionLeaverChecklist",
  DitRetentionOutcomeLeaving = "DITRetentionOutcomeLeaving",
  DitRetentionOutcomeSaved = "DITRetentionOutcomeSaved",
  DitRetentionRefundProcessed = "DITRetentionRefundProcessed",
  DitStatusAgreed = "DITStatusAgreed",
  DitStatusCreditCheck = "DITStatusCreditCheck",
  DitStatusCreditCheckFailed = "DITStatusCreditCheckFailed",
  DitStatusCreditCheckPassed = "DITStatusCreditCheckPassed",
  DitStatusDead = "DITStatusDead",
  DitSupportDormant = "DITSupportDormant",
  DitStatusNew = "DITStatusNew",
  DitStatusOngoing = "DITStatusOngoing",
  DitStatusPaid = "DITStatusPaid",
  DitStatusPaidWithDiscount = "DITStatusPaidWithDiscount",
  DitStatusRefunded = "DITStatusRefunded",
  DitStatusRejected = "DITStatusRejected",
  DitSupportStoppedTuition = "DITSupportStoppedTuition",
  DitTheoryCertificateHasExpired = "DITTheoryCertificateHasExpired",
  DitTheoryTestResult = "DITTheoryTestResult",
  DitSupportTopUp = "DITSupportTopUp",
  DitTrainerAllocation = "DITTrainerAllocation",
  DpaChecks = "DPAChecks",
  DebtContactWk1 = "DebtContactWk1",
  DebtContactWk2 = "DebtContactWk2",
  DebtContactWk3 = "DebtContactWk3",
  DebtContactWk4 = "DebtContactWk4",
  DebtRecoveryProcess = "DebtRecoveryProcess",
  DebtTerminationSaved = "DebtTerminationSaved",
  DebtAdhocPaymentMade = "DebtADHOCPaymentMade",
  DebtConfirmationOfSuspensionSent = "DebtConfirmationOfSuspensionSent",
  DebtDcaReferralWarningSent = "DebtDCAReferralWarningSent",
  DebtDefaultNoticeSent = "DebtDefaultNoticeSent",
  DebtFinancialStatementRequested = "DebtFinancialStatementRequested",
  DebtNosiaSent = "DebtNOSIASent",
  DebtReferredToDca = "DebtReferredToDCA",
  DebtSuspensionWarningSent = "DebtSuspensionWarningSent",
  DebtTerminationWarningSent = "DebtTerminationWarningSent",
  DefermentPlanCreated = "DefermentPlanCreated",
  DefermentQuery = "DefermentQuery",
  DetailsUpdated = "DetailsUpdated",
  DiaryManagement = "DiaryManagement",
  DirectDebitCancelled = "DirectDebitCancelled",
  DirectDebitRtp = "DirectDebitRTP",
  DirectDebitReinstate = "DirectDebitReinstate",
  DirectDebitUpdateDetails = "DirectDebitUpdateDetails",
  DitSupportComplaint = "DITSupportComplaint",
  DitSupportAccountManagerOther = "DitSupportAccountManagerOther",
  DitSupportAccountManagerReview = "DitSupportAccountManagerReview",
  DitSupportAccountManagerWelcome = "DitSupportAccountManagerWelcome",
  DitSupportAdi21tCompleted = "DitSupportAdi21tCompleted",
  DitSupportAdi21tRequested = "DitSupportAdi21tRequested",
  DitSupportAdi2atCompleted = "DitSupportAdi2atCompleted",
  DitSupportAdi2atRequested = "DitSupportAdi2atRequested",
  DitSupportAdi3lmdCompleted = "DitSupportAdi3lmdCompleted",
  DitSupportAdi3lmdRequested = "DitSupportAdi3lmdRequested",
  DitSupportBsmVrBooking = "DitSupportBsmVrBooking",
  DitSupportBsmVrCancel = "DitSupportBsmVrCancel",
  DitSupportBsmVrReschedule = "DitSupportBsmVrReschedule",
  DitSupportContactAttempted = "DitSupportContactAttempted",
  DitSupportDbsApplicationSubmitted = "DitSupportDbsApplicationSubmitted",
  DitSupportDbsFollowUp = "DitSupportDbsFollowUp",
  DitSupportDbsRequested = "DitSupportDbsRequested",
  DitSupportInCarBooking = "DitSupportInCarBooking",
  DitSupportLookingToleave = "DitSupportLookingToleave",
  DitSupportOnlineClassroomBooking = "DitSupportOnlineClassroomBooking",
  DitSupportOther = "DitSupportOther",
  DitSupportPart1Support = "DitSupportPart1Support",
  DitSupportPart1TestBooked = "DitSupportPart1TestBooked",
  DitSupportPart1TestResult = "DitSupportPart1TestResult",
  DitSupportPart2Support = "DitSupportPart2Support",
  DitSupportPart2TestBooked = "DitSupportPart2TestBooked",
  DitSupportPart2TestResult = "DitSupportPart2TestResult",
  DitSupportPart3Support = "DitSupportPart3Support",
  DitSupportPart3TestBooked = "DitSupportPart3TestBooked",
  DitSupportPart3TestResult = "DitSupportPart3TestResult",
  DitSupportPrnApplicationSubmitted = "DitSupportPrnApplicationSubmitted",
  DitSupportPrnFollowUp = "DitSupportPrnFollowUp",
  DitSupportPrnRequested = "DitSupportPrnRequested",
  DitSupportTraineeLicenceRegisteredInterest = "DitSupportTraineeLicenceRegisteredInterest",
  DitSupportTrainerAllocation = "DitSupportTrainerAllocation",
  DitSupportTrainerReallocation = "DitSupportTrainerReallocation",
  DitSupportAdi21AtUpdateProvided = "DITSupportADI21ATUpdateProvided",
  DitSupportAdi21TUpdateProvided = "DITSupportADI21TUpdateProvided",
  DitSupportThrive = "DITSupportThrive",
  DitSupportBsmvrQuery = "DITSupportBSMVRQuery",
  MotabilityGrantTeamFeedback = "MotabilityGrantTeamFeedback",
  IgSignupComplete = "IGSignupComplete",
  DocumentToExpire = "DocumentToExpire",
  NewDocumentUpload = "NewDocumentUpload",
  DriveConfidentAccidentResponse = "DriveConfidentAccidentResponse",
  DriveConfidentMla = "DriveConfidentMLA",
  DriveConfidentSaga = "DriveConfidentSAGA",
  DrivingInstructorTraineeComplaintCsc = "DrivingInstructorTraineeComplaintCSC",
  DrivingInstructorTraineeComplaintOther = "DrivingInstructorTraineeComplaintOther",
  DrivingInstructorTraineeComplaintProductPackage = "DrivingInstructorTraineeComplaintProductPackage",
  DrivingInstructorTraineeComplaintTrainer = "DrivingInstructorTraineeComplaintTrainer",
  DrivingLessonAvailability = "DrivingLessonAvailability",
  DrivingLicenceDuetoExpire = "DrivingLicenceDuetoExpire",
  DualBrakeBespokeAdaptionsRequired = "DualBrakeBespokeAdaptionsRequired",
  DualBrakeNoCoverage = "DualBrakeNoCoverage",
  EmailAnsweredQuery = "EmailAnsweredQuery",
  EmailFinalChase = "EmailFinalChase",
  EmailGeneralFollow = "EmailGeneralFollow",
  EmailInitialIntro = "EmailInitialIntro",
  EmailReengagement = "EmailReengagement",
  EmergencyExchangeApproved = "EmergencyExchangeApproved",
  EmergencyExchangeDenied = "EmergencyExchangeDenied",
  EmergencyExchangeRequested = "EmergencyExchangeRequested",
  EstimatedVehicleReadyDate = "EstimatedVehicleReadyDate",
  ExistingPupilDetailQuery = "ExistingPupilDetailQuery",
  FaultFeeApplied = "FaultFeeApplied",
  FinancialResilience = "FinancialResilience",
  FixedPenaltyApplied = "FixedPenaltyApplied",
  FleetQuery = "FleetQuery",
  FranchiseApplicationReceived = "FranchiseApplicationReceived",
  FranchiseFinancialSupport = "FranchiseFinancialSupport",
  FranchisePayment = "FranchisePayment",
  FranchiseRenewal = "FranchiseRenewal",
  FranchiseSalesNtuDeclinedbytheBusiness = "FranchiseSalesNTUDeclinedbytheBusiness",
  FranchiseSalesNtuFailedtoMakeContactbyPhoneEndofContactStrategy = "FranchiseSalesNTUFailedtoMakeContactbyPhoneEndofContactStrategy",
  FranchiseSalesNtuFailedtoMakeContactbyPhoneIncorrectNumber = "FranchiseSalesNTUFailedtoMakeContactbyPhoneIncorrectNumber",
  FranchiseSalesNtuGoingIndependent = "FranchiseSalesNTUGoingIndependent",
  FranchiseSalesNtuGonewithaCompetitor = "FranchiseSalesNTUGonewithaCompetitor",
  FranchiseSalesNtuKeepingCurrentFranchise = "FranchiseSalesNTUKeepingCurrentFranchise",
  FranchiseSalesNtuNotEligible = "FranchiseSalesNTUNotEligible",
  FranchiseSalesNtuTooExpensive = "FranchiseSalesNTUTooExpensive",
  FranchiseSalesSetUp = "FranchiseSalesSetUp",
  FranchiseSupport = "FranchiseSupport",
  FranchiseeComplaintCsc = "FranchiseeComplaintCSC",
  FranchiseeComplaintFranchiseTandCs = "FranchiseeComplaintFranchiseTandCs",
  FranchiseeComplaintOther = "FranchiseeComplaintOther",
  FranchiseeComplaintVehicle = "FranchiseeComplaintVehicle",
  GeneralEnquiry = "GeneralEnquiry",
  GreenBadgeContact = "GreenBadgeContact",
  GreenBadgeDuetoExpire = "GreenBadgeDuetoExpire",
  GreenBadgeHasExpired = "GreenBadgeHasExpired",
  GreenBadgeUpdated = "GreenBadgeUpdated",
  GreenNewDiAccountActive = "GreenNewDIAccountActive",
  HubCappQuery = "HubCappQuery",
  HubcappSupport = "HubcappSupport",
  IgStatusAgreed = "IGStatusAgreed",
  IgStatusCreditCheck = "IGStatusCreditCheck",
  IgStatusCreditCheckFailed = "IGStatusCreditCheckFailed",
  IgStatusCreditCheckPassed = "IGStatusCreditCheckPassed",
  IgStatusDead = "IGStatusDead",
  IgStatusDormant = "IGStatusDormant",
  IgStatusLeft = "IGStatusLeft",
  IgStatusNew = "IGStatusNew",
  IgStatusOngoing = "IGStatusOngoing",
  IgStatusPaid = "IGStatusPaid",
  IgStatusPaidWithDiscount = "IGStatusPaidWithDiscount",
  IgStatusRejected = "IGStatusRejected",
  InappropriateBehaviour = "InappropriateBehaviour",
  InappropriateContact = "InappropriateContact",
  IncorrectIncompleteActionTaken = "IncorrectIncompleteActionTaken",
  IncorrectInformationGiven = "IncorrectInformationGiven",
  IncorrectPaymentTaken = "IncorrectPaymentTaken",
  IncorrectPupilAllocation = "IncorrectPupilAllocation",
  InstructorLead = "InstructorLead",
  InstructorMessage = "InstructorMessage",
  InstructorSetup = "InstructorSetup",
  InvoiceMissing = "InvoiceMissing",
  InvoiceQuery = "InvoiceQuery",
  KeyedFranchiseAdjustment = "KeyedFranchiseAdjustment",
  KeyedLeavingDate = "KeyedLeavingDate",
  LanguageToneCallHandling = "LanguageToneCallHandling",
  LeadCustomer = "LeadCustomer",
  LearnerComplaint = "LearnerComplaint",
  LearnerDriverComplaintCsc = "LearnerDriverComplaintCSC",
  LearnerDriverComplaintFranchiseeInstructor = "LearnerDriverComplaintFranchiseeInstructor",
  LearnerDriverComplaintOther = "LearnerDriverComplaintOther",
  LearnerRejection = "LearnerRejection",
  LearnerTopup = "LearnerTopup",
  LeftOnGoodTerms = "LeftOnGoodTerms",
  LessonAmendment = "LessonAmendment",
  LessonCancellation = "LessonCancellation",
  LessonClaimedinError = "LessonClaimedinError",
  LessonEnquiry = "LessonEnquiry",
  LifeEvent = "LifeEvent",
  LockedLearnerCaps = "LockedLearnerCaps",
  MtaAwaitingToAgreeCancellation = "MTAAwaitingToAgreeCancellation",
  MtaDeceased = "MTADeceased",
  MtaDualControlsRequested = "MTADualControlsRequested",
  MtaHigherRateRequest = "MTAHigherRateRequest",
  MtaIllHealth = "MTAIllHealth",
  MtaIncorrectDetailsOnLead = "MTAIncorrectDetailsOnLead",
  MtaLackofProgress = "MTALackofProgress",
  MtaLicenceSuspended = "MTALicenceSuspended",
  MtaMotabilityRequest = "MTAMotabilityRequest",
  MtaNoContactAfter3Attempts = "MTANoContactAfter3Attempts",
  MtaNoLongerEligibleforGrante = "MTANoLongerEligibleforGrante",
  MtaPupilRequest = "MTAPupilRequest",
  MtaReappliedforLicence = "MTAReappliedforLicence",
  MtaReferredBack = "MTAReferredBack",
  MtattNotPassed = "MTATTNotPassed",
  MtaUnabletoContact = "MTAUnabletoContact",
  ManualRefund = "ManualRefund",
  MarketingPreferences = "MarketingPreferences",
  MentalHealth = "MentalHealth",
  MileageUpdate = "MileageUpdate",
  MoPComplaintCsc = "MoPComplaintCSC",
  MoPComplaintFranchiseeInstructor = "MoPComplaintFranchiseeInstructor",
  MopComplaintOther = "MopComplaintOther",
  Motability14HoursTheoryCheck = "Motability14HoursTheoryCheck",
  Motability20HoursCompleted = "Motability20HoursCompleted",
  Motability30HoursCompleted = "Motability30HoursCompleted",
  Motability40HoursCompleted = "Motability40HoursCompleted",
  Motability8HoursCompleted = "Motability8HoursCompleted",
  MotabilityAdditionalHours = "MotabilityAdditionalHours",
  MotabilityAdditionalHoursApproved = "MotabilityAdditionalHoursApproved",
  MotabilityAdditionalHoursRequested = "MotabilityAdditionalHoursRequested",
  MotabilityAllocateInstructor = "MotabilityAllocateInstructor",
  MotabilityAllocationNote = "MotabilityAllocationNote",
  MotabilityAppointee = "MotabilityAppointee",
  MotabilityAwaitingAdiResponse = "MotabilityAwaitingAdiResponse",
  MotabilityCsi = "MotabilityCSI",
  MotabilityCancellationDeceased = "MotabilityCancellationDeceased",
  MotabilityCancellationFailedTheoryTest = "MotabilityCancellationFailedTheoryTest",
  MotabilityCancellationIllHealth = "MotabilityCancellationIllHealth",
  MotabilityCancellationLackOfProgress = "MotabilityCancellationLackOfProgress",
  MotabilityCancellationLearnerRequest = "MotabilityCancellationLearnerRequest",
  MotabilityCancellationLicenceSuspended = "MotabilityCancellationLicenceSuspended",
  MotabilityCancellationMotabilityRequest = "MotabilityCancellationMotabilityRequest",
  MotabilityCancellationNoLongerEligibleForGrant = "MotabilityCancellationNoLongerEligibleForGrant",
  MotabilityCancellationReappliedForLicence = "MotabilityCancellationReappliedForLicence",
  MotabilityCancellationReferredBack = "MotabilityCancellationReferredBack",
  MotabilityCancellationUnableToContact = "MotabilityCancellationUnableToContact",
  MotabilityDbs = "MotabilityDBS",
  MotabilityFamHofUrgent = "MotabilityFamHofUrgent",
  MotabilityFccComplete = "MotabilityFccComplete",
  MotabilityFccOutstanding = "MotabilityFccOutstanding",
  MotabilityFirstLessonCheck = "MotabilityFirstLessonCheck",
  MotabilityFourWeekFollowUp = "MotabilityFourWeekFollowUp",
  MotabilityGrantsTeamContact = "MotabilityGrantsTeamContact",
  MotabilityGreenBadge = "MotabilityGreenBadge",
  MotabilityHigherRateCreated = "MotabilityHigherRateCreated",
  MotabilityHigherRateRemoved = "MotabilityHigherRateRemoved",
  MotabilityHigherRateRequested = "MotabilityHigherRateRequested",
  MotabilityHofDl = "MotabilityHofDL",
  MotabilityHofFam = "MotabilityHofFam",
  MotabilityIdFormOutstanding = "MotabilityIDFormOutstanding",
  MotabilityIdFormReceived = "MotabilityIDFormReceived",
  MotabilityInitialSetUp = "MotabilityInitialSetUp",
  MotabilityInsuranceDocuments = "MotabilityInsuranceDocuments",
  MotabilityMtaResponseReceived = "MotabilityMtaResponseReceived",
  MotabilityNoLessonsTakenWithin4Weeks = "MotabilityNoLessonsTakenWithin4Weeks",
  MotabilityNotAllocated = "MotabilityNotAllocated",
  MotabilityOutstandingPracticalTestResult = "MotabilityOutstandingPracticalTestResult",
  MotabilityOutstandingTheoryTestResult = "MotabilityOutstandingTheoryTestResult",
  MotabilityPoChange = "MotabilityPOChange",
  MotabilityPracticalTestDateRequired = "MotabilityPracticalTestDateRequired",
  MotabilityPracticalTestGoodLuckCall = "MotabilityPracticalTestGoodLuckCall",
  MotabilityPublicLiability = "MotabilityPublicLiability",
  MotabilityRedAwaitingSystemUpdate = "MotabilityRedAwaitingSystemUpdate",
  MotabilityRogFormOutstanding = "MotabilityROGFormOutstanding",
  MotabilityRogReceived = "MotabilityROGReceived",
  MotabilityVehicleTypeHiTech = "MotabilityVehicleTypeHiTech",
  MotabilityVehicleTypeSimple = "MotabilityVehicleTypeSimple",
  NewPupilDetailQuery = "NewPupilDetailQuery",
  OnboardingInitialContactAttempted = "OnboardingInitialContactAttempted",
  OnboardingInitialContactCompleted = "OnboardingInitialContactCompleted",
  OnboardingPostContactAttempted = "OnboardingPostContactAttempted",
  OnboardingPostContactCompleted = "OnboardingPostContactCompleted",
  OnlineBookingError = "OnlineBookingError",
  OrangeChangeOfTransmission = "OrangeChangeOfTransmission",
  OutsourcedReasonAdaptionsNeededAreMorethanBsmFleetLevel3 = "OutsourcedReasonAdaptionsNeededAreMorethanBSMFleetLevel3",
  OutsourcedReasonBsmdiAllocatedComplaint = "OutsourcedReasonBSMDIAllocatedComplaint",
  OutsourcedReasonBsmdiAvailablePupilRequestedtoStaywithTheirOwnDi = "OutsourcedReasonBSMDIAvailablePupilRequestedtoStaywithTheirOwnDi",
  OutsourcedReasonHighlyAdaptedOwnVehicleNoExperiencedBsmDi = "OutsourcedReasonHighlyAdaptedOwnVehicleNoExperiencedBSMDi",
  OutsourcedReasonNoBsmAutoAvailable = "OutsourcedReasonNoBSMAutoAvailable",
  OutsourcedReasonNoBsmLevel3DiAvailable = "OutsourcedReasonNoBSMLevel3DIAvailable",
  OutsourcedReasonNoBsmManualAvailable = "OutsourcedReasonNoBSMManualAvailable",
  OutsourcedReasonNorthernIreland = "OutsourcedReasonNorthernIreland",
  PaymentError = "PaymentError",
  PaymentErrorEnquiry = "PaymentErrorEnquiry",
  PaymentGeneralEnquiry = "PaymentGeneralEnquiry",
  PaymentPlan = "PaymentPlan",
  PaymentPlanCreated = "PaymentPlanCreated",
  PhysicalHealth = "PhysicalHealth",
  PinkAwaiting3rdPartyDIdetails = "PinkAwaiting3rdPartyDIdetails",
  PinkBadgeDuetoExpire = "PinkBadgeDuetoExpire",
  PinkBadgeHasExpired = "PinkBadgeHasExpired",
  PinkBadgeUpdated = "PinkBadgeUpdated",
  PotentialFuture = "PotentialFuture",
  PracticalTestCover = "PracticalTestCover",
  PracticalTestCoverEnquiry = "PracticalTestCoverEnquiry",
  PracticalTestResult = "PracticalTestResult",
  Prestige = "Prestige",
  Price = "Price",
  PricingUpdated = "PricingUpdated",
  PupilReallocationInstructorAvailability = "PupilReallocationInstructorAvailability",
  PupilReallocationInstructorConcern = "PupilReallocationInstructorConcern",
  PupilReallocationPupilMovedAddress = "PupilReallocationPupilMovedAddress",
  PupilReallocationVehicleTransmission = "PupilReallocationVehicleTransmission",
  PupilRejectionEnquiry = "PupilRejectionEnquiry",
  PupilSupplyQuery = "PupilSupplyQuery",
  PurpleReferToMotability = "PurpleReferToMotability",
  Reallocation = "Reallocation",
  ReallocationDormancy = "ReallocationDormancy",
  ReallocationInstructorLeaving = "ReallocationInstructorLeaving",
  ReallocationNoInstructorAvailable = "ReallocationNoInstructorAvailable",
  ReallocationTestCover = "ReallocationTestCover",
  ReceiptRequest = "ReceiptRequest",
  RefundIncorrectNotActioned = "RefundIncorrectNotActioned",
  RefundRequest = "RefundRequest",
  RefundRequestInstructorAvailability = "RefundRequestInstructorAvailability",
  RefundRequestInstructorConcern = "RefundRequestInstructorConcern",
  RefundRequestPassedTest = "RefundRequestPassedTest",
  RefundRequestStoppedLessons = "RefundRequestStoppedLessons",
  RefundStatusEnquiry = "RefundStatusEnquiry",
  RegisteredSelfsourcedLearner = "RegisteredSelfsourcedLearner",
  RejectedPupil = "RejectedPupil",
  RenewalApplicationReceivedKeyNewTerm = "RenewalApplicationReceivedKeyNewTerm",
  RenewalFleetOrderNewCar = "RenewalFleetOrderNewCar",
  RenewalFollowupContact = "RenewalFollowupContact",
  RenewalInitialContact = "RenewalInitialContact",
  RenewalIssueApplication = "RenewalIssueApplication",
  RenewalOutcomeLeaving = "RenewalOutcomeLeaving",
  RenewalOutcomeRenewed = "RenewalOutcomeRenewed",
  ResignationReceived = "ResignationReceived",
  RetentionDealOffered = "RetentionDealOffered",
  RetentionFollowupContact = "RetentionFollowupContact",
  RetentionInitialContact = "RetentionInitialContact",
  RetentionIssueExitInterview = "RetentionIssueExitInterview",
  RetentionLeaverChecklist = "RetentionLeaverChecklist",
  RetentionLeaverDeferment = "RetentionLeaverDeferment",
  RetentionOutcomeLeaving = "RetentionOutcomeLeaving",
  RetentionOutcomeSaved = "RetentionOutcomeSaved",
  RetentionReferral = "RetentionReferral",
  RetentionResignationReceived = "RetentionResignationReceived",
  RujSupport = "RujSupport",
  SmsClosed = "SMSClosed",
  SmsFinalChase = "SMSFinalChase",
  SmsGeneralChase = "SMSGeneralChase",
  SmsInitialIntro = "SMSInitialIntro",
  SmsReengagement = "SMSReengagement",
  SavedLeaver = "SavedLeaver",
  ShortNoticeCancellation = "ShortNoticeCancellation",
  ShortNoticeCancellationCharged = "ShortNoticeCancellationCharged",
  ShortNoticeCancellationEnquiry = "ShortNoticeCancellationEnquiry",
  Stage1WarningIssued = "Stage1WarningIssued",
  Stage2WarningIssued = "Stage2WarningIssued",
  TerminationComplaints = "TerminationComplaints",
  TerminationConduct = "TerminationConduct",
  TerminationDbs = "TerminationDBS",
  TerminationDebt = "TerminationDebt",
  TerminationFailedPart3 = "TerminationFailedPart3",
  TerminationGreenBadge = "TerminationGreenBadge",
  TerminationPinkBadge = "TerminationPinkBadge",
  TheoryTestResult = "TheoryTestResult",
  TopUpAccount = "TopUpAccount",
  TuitionStandards = "TuitionStandards",
  UnabletosaveLeaver = "UnabletosaveLeaver",
  UpdateAddressDetails = "UpdateAddressDetails",
  UpdateContactDetails = "UpdateContactDetails",
  UpdateCoverage = "UpdateCoverage",
  UpdateDiary = "UpdateDiary",
  UpdateLessonType = "UpdateLessonType",
  UpdateMileage = "UpdateMileage",
  UpdatePersonalDetails = "UpdatePersonalDetails",
  UpdatePersonalInfo = "UpdatePersonalInfo",
  UpdatePrices = "UpdatePrices",
  UpdatePupilCap = "UpdatePupilCap",
  UpdateTestResults = "UpdateTestResults",
  UpdateWorkingHours = "UpdateWorkingHours",
  VehicleAaam = "VehicleAAAM",
  VehicleCourtesyCar = "VehicleCourtesyCar",
  VehicleDelivery = "VehicleDelivery",
  VehicleExchangeQuery = "VehicleExchangeQuery",
  VehicleFaultFee = "VehicleFaultFee",
  VehicleFixedPenalty = "VehicleFixedPenalty",
  VehicleParkingPermitRequest = "VehicleParkingPermitRequest",
  VehiclePrestigeQuery = "VehiclePrestigeQuery",
  VehicleRepair = "VehicleRepair",
  VehicleStandards = "VehicleStandards",
  VehicleType = "VehicleType",
  WebChat = "WebChat",
  WebsiteApp = "WebsiteApp",
  WhiteAwaitingAllocation = "WhiteAwaitingAllocation",
  YellowHigherRateRequestedToMbt = "YellowHigherRateRequestedToMBT",
  FleetChargeChallenge = "FleetChargeChallenge",
}

export type WorkingHour = {
  __typename?: "WorkingHour";
  proposition?: Maybe<Proposition>;
  id: Scalars["ID"];
  day: Scalars["String"];
  start?: Maybe<Scalars["String"]>;
  end?: Maybe<Scalars["String"]>;
  lineItemKey?: Maybe<Scalars["String"]>;
};
