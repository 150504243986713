import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { AwardsProps } from "../types";
import SubmitButton from "../../../../components/common/SubmitButton/SubmitButton";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  header: {
    fontWeight: 700,
    fontSize: "24px",
    margin: theme.spacing(2),
  },
  subHeading: {
    marginLeft: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
    width: "500px",
    borderRadius: "15px",
    border: "1px solid #C3C4C4",
    boxShadow: "0px 2px 4px 0px #00000029",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  formGroup: {
    marginTop: theme.spacing(2),
  },
  button: {
    width: "100%",
    margin: 0,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  buttonContainer: {
    margin: theme.spacing(2),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  checkboxLabel: {
    color: theme.palette.text.primary,
    margin: `0 ${theme.spacing(5)}px`,
    padding: `${theme.spacing(1)}px 0`,
    borderBottom: "1px solid #E2E2E2",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: theme.palette.secondary.main,
    },
    paddingLeft: 0,
    paddingRight: "24px",
  },
}));

const Awards: React.FC<AwardsProps> = ({
  instructorName,
  handleSubmit,
  awards,
  handleCheckboxChange,
  selectedOptions,
  awardLabels,
  loading,
  error,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography component="h3" className={classes.header}>
        How would you describe {instructorName}?
      </Typography>
      <Typography
        className={clsx({ [classes.errorText]: error }, classes.subHeading)}
      >
        Select up to three
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup className={classes.formGroup}>
          {awards.map(award => (
            <FormControlLabel
              key={award}
              className={classes.checkboxLabel}
              control={
                <Checkbox
                  value={award}
                  onChange={handleCheckboxChange}
                  checked={selectedOptions.includes(award)}
                  disabled={
                    selectedOptions.length >= 3 &&
                    !selectedOptions.includes(award)
                  }
                  className={classes.checkbox}
                />
              }
              label={awardLabels[award]}
            />
          ))}
        </FormGroup>
        <div className={classes.buttonContainer}>
          <SubmitButton
            text="Submit"
            data-test="submit-button"
            classes={{ root: classes.button }}
            disabled={loading}
            loading={loading}
            autoFocus={false}
          />
        </div>
      </form>
    </Card>
  );
};
export default Awards;
